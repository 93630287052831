import React from "react";
import "./PopupElement.scss";
import {Link} from "react-router-dom";

function PopupElement(props) {
  const {data, callbackMoodClick = null, callbackCategoryClick = null, handleClose = () => {} } = props;

  return (
    <div className="popup-element">
      <div className="popup-element__item">
        <p className="popup-element__title">Pack</p>
        {data && data.hasOwnProperty("pack") &&
        <>
          <Link
            className="popup-element__description_link"
            to={`/pack/${data.pack.id}`}
          >
            {data.pack.name}
          </Link>
        </>
        }
      </div>

      <div className="popup-element__item">
        <p className="popup-element__title">Mood</p>
        {data && data.hasOwnProperty("mood") && data.mood !== null &&
          <p className="popup-element__description_plain">
            {data.mood.name}
          </p>
        }
      </div>

      <div className="popup-element__item">
        <p className="popup-element__title">Category</p>

        {callbackCategoryClick !== null ?
          <>
            {data && data.hasOwnProperty("category") && data.category !== null &&
              <button
                className="popup-element__description_link"
                onClick={() => {
                  callbackCategoryClick(data.category.id);
                  handleClose();
                }}
              >
                {data.category.name}
              </button>
            }
          </> :
          <>
            {data && data.hasOwnProperty("category") && data.category !== null &&
              <Link
                className="popup-element__description_link"
                to={`/browse/?categoryId=${data.category.id}`}
              >
                {data.category.name}
              </Link>
            }
          </>
        }
      </div>

      <div className="popup-element__item">
        <p className="popup-element__title">File types</p>
        <ul className="popup-element__list">
          {data && data.hasOwnProperty("assignedExtensions") && data.assignedExtensions.map((key, index) => (
            <li key={index} className="popup-element__list-item">
              <p className="popup-element__description_plain">.{key}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PopupElement;
