import React, {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import BrowseByCard from './BrowseByCard';
import { connect } from 'react-redux'
import './BrowseByCategory.scss';
import { filterEmptyCategories } from '../lib/utils';

const mapStateToProps = (state) => ({
  // temporarily filter categories with small amount of content
  categories: filterEmptyCategories(state['browse'].categories),
});

// base url for pictures on cards
const baseUrl = 'https://storytale-public2.b-cdn.net/static/category/';

function BrowseByCategory({ categories = [] }) {
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    if (categories.length > 0) {
      const categoriesMoreData = categories.slice();
      categoriesMoreData.forEach((category) => {
        const categoryFirstWord = category.name.split(" ")[0].toLowerCase();
        category.class = categoryFirstWord;
        category.src = baseUrl + categoryFirstWord + '.png';
        switch (category.name) {
          case 'Illustration':
            category.description = category.name + 's';
            break;
          case 'Application':
            category.description = category.name + 's';
            break;
          case 'Email':
            category.description = category.name + 's';
            break;
          case 'Mockup':
            category.description = category.name + 's';
            break;
          case 'Elements':
            category.name = 'Icons'
            category.description = 'Icons'
            category.src = baseUrl + 'icons.png'
            break;
          case 'Web design':
            category.name = 'Patterns'
            category.description = 'Patterns'
            category.src = baseUrl + 'web-design.png'
            break;
          default:
            category.description = category.name;
            break;
        }
      });
      setCategoriesData(categoriesMoreData);
    }
  }, [categories]);

  return (
    <section className='browse-by-categories section'>
      <div className="container-custom">
        <div className="row">
          <div className="col">
            <h4 className="text-center">Explore by category</h4>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="container-custom">
          <div className="row content-row">
            {categoriesData.length > 0 && categoriesData.map((category) => {
              return (
                <div className="col-auto item" key={uuidv4()}>
                  <BrowseByCard
                    classes={`browse-by-card_${category.class}`}
                    link={category.id ? `/browse/?categoryId=${category.id}` :  `/browse/`}
                    imageSrc={category.src}
                    imageAlt={category.description}
                    description={category.description}
                  />
                </div>)
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default connect(mapStateToProps)(BrowseByCategory);
