import React from 'react';
import './BrowseByMood.css';
import { Link } from "react-router-dom";
import {Kebab} from "./Icons";
import ButtonBase from "./ButtonBase";
import BrowseByCard from "./BrowseByCard";

import { useSelector } from 'react-redux'

const firstMood = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/mood/01@2x.png";
const secondMood = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/mood/02@2x.png";
const thirdMood = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/mood/03@2x.png";
const fourthMood = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/mood/04@2x.png";
const fifthMood = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/mood/05@2x.png";

const FindSelector = (value) => {
  const todo = useSelector((state) => state['browse'].moods.filter(mood => mood.name.toLowerCase() === value.toLowerCase()).map(item => item.id));
  return todo[0];
};

function BrowseByMood () {
    const mood3d = FindSelector('3d');
    const moodOutline = FindSelector('outline');
    const moodFlat = FindSelector('flat');
    const moodGrainy = FindSelector('grainy');
    const moodHandDrawn = FindSelector('hand drawn');
    const baseUrl = '/browse/?moodIds[]=';

    return (
        <section className="browse-by-mood section">
        <div className="container-custom">
          <div className="row">
            <div className="col">
              <h4 className="text-center">Browse catalog by mood</h4>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="container-custom">
            <div className="row content-row">
              <div className="col-auto item ">
                <BrowseByCard
                  classes="browse-by-card-3d"
                  link={mood3d === undefined ? `/browse/` : `${baseUrl}${mood3d}`}
                  imageSrc={firstMood}
                  imageAlt="Browse by 3d"
                  description="3D"
                />
              </div>
              <div className="col-auto item">
                <BrowseByCard
                  classes="browse-by-card-outline"
                  link={moodOutline === undefined ? `/browse/` : `${baseUrl}${moodOutline}`}
                  imageSrc={secondMood}
                  imageAlt="Browse by Outline"
                  description="Outline"
                />
              </div>
              <div className="col-auto item">
                <BrowseByCard
                  classes="browse-by-card-flat"
                  link={moodFlat === undefined ? `/browse/` : `${baseUrl}${moodFlat}`}
                  imageSrc={thirdMood}
                  imageAlt="Browse by Flat"
                  description="Flat"
                />
              </div>
              <div className="col-auto item">
                <BrowseByCard
                  classes="browse-by-card-grainy"
                  link={moodGrainy === undefined ? `/browse/` : `${baseUrl}${moodGrainy}`}
                  imageSrc={fourthMood}
                  imageAlt="Browse by Grainy"
                  description="Grainy"
                />
              </div>
              <div className="col-auto item">
                <BrowseByCard
                  classes="browse-by-card-handdrawn"
                  link={moodHandDrawn === undefined ? `/browse/` : `${baseUrl}${moodHandDrawn}`}
                  imageSrc={fifthMood}
                  imageAlt="Browse by Hand drawn"
                  description="Hand drawn"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto mx-auto">
                <Link
                  to={`/browse`}
                >
                  <ButtonBase
                    isEmulate={true}
                    content="Explore full catalog"
                    classes="custom icon"
                    icon={
                      <Kebab/>
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default BrowseByMood ;
