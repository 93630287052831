import React from "react";
import "./SkeletonPack.css";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonPack(props) {

    return (
        <section className={`skeleton-pack ${props.type === "full" && "full"}`}>
          <h3 className="visually-hidden">Pack component</h3>
            <Skeleton variant="circle" animation="pulse" />
            <Skeleton variant="text" animation="pulse" width="65%" />
            <Skeleton variant="text" animation="pulse" width="80%" />
        </section>
    );
}

export default SkeletonPack;
