import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { postFormData } from "../lib/apiRequests";
import "./NewsLetterCard.css";

function NewsLetterCard(props) {
  const [formActive, setFormActive] = useState(true);
  const [newsLetterForm] = Form.useForm();

  function onFinish(values) {
    let formData = new FormData();
    formData.append("newsletterType", "anons");
    formData.append("email", values.email);

    postFormData("api/newsletter/subscribe", formData)
      .then((response) => {
        if (response.success) {
          newsLetterForm.resetFields();
          setFormActive(false);
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  return (
    <div className="newsletter_card">
      <div className="first_block">
        <h3 className="newsletter__title block-title">
          Sign up to our newsletters to catch new resources first
        </h3>
        <Form form={newsLetterForm} onFinish={onFinish} className="form-elements">
          <Form.Item
            className="newsletter__input_wrapper"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "",
              },
            ]}
          >
            <Input
              placeholder={"Your email"}
              className="newsletter__input"
            />
          </Form.Item>

          {/*<Form.Item className="button_form" style={{margin: "0", alignItems: "flex-end"}}>*/}
            <Button
              type="primary"
              htmlType="submit"
              className={"btn-pink newsletter__button"}
              disabled={!formActive}
            >
              {/* {formActive ? "Sign up" : "Signed up"} */ "Subscribe"}
            </Button>
          {/*</Form.Item>*/}
        </Form>
      </div>

      <img
        className="icon"
        src="/images/newsletters-icon.png"
        alt=""
        width={280}
        height={208}
      />
    </div>
  );
}

export default NewsLetterCard;
