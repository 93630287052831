import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveSubscriptionInfo } from '../features/user/userSlice';

import { Table } from 'antd';
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from 'uuid';

import { ShieldCheck } from './Icons';

// Common scripts
import useStateCallback from '../lib/customHooks/useStateCallback';
import { postFormData } from '../lib/apiRequests';
import { openNotificationWithIcon } from '../lib/notifications';

import './Checkout.scss';
import '../common_styles/tableWrapper.scss';
import '../common_styles/styledAntPopover.scss';
import '../common_styles/buttons.scss';
import '../common_styles/settingsTable.scss';

const checkoutOrderCollumns = [
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: 'Plan',
    dataIndex: 'name',
    key: 'name'
  }
];

const totalPriceColumns = [
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    key: 'totalPrice'
  }
];

function Checkout(props) {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.modals.orderChoosen);
  const history = useHistory();
  const paddle_props = ['Environment', 'Checkout', 'Order'];
  const Paddle = (window && window.hasOwnProperty('Paddle')) ? window.Paddle : undefined;
  let is_paddle = Paddle && paddle_props.every(prop => Paddle.hasOwnProperty(prop));

  const [checkoutOrderData, setCheckoutOrderData] = useStateCallback([]);
  const [checkoutTotalPrice, setCheckoutTotalPrice] = useState([]);
  const [orderStatus, setOrderStatus] = useState(null);
  const [paddleId, setPaddleId] = useState(null);

  const is_sandbox = (process.env.REACT_APP_PADDLE_IS_SANDBOX === 'true');
  let openCheckout = undefined;

  if (is_paddle) {
    if (is_sandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    });

    openCheckout = (id) => {
      let formData = new FormData();
      formData.append('orderId', id);
      postFormData('/api/order/confirm', formData)
          .catch(err => {
            console.log(err);
          });

      Paddle.Checkout.open({
        product: paddleId,
        passthrough: `{\"orderId\": ${id}}`,
        successCallback: checkoutComplete,
      });
    }
  }

  function checkoutComplete(data) {
    let checkoutId = data.checkout.id;

    Paddle.Order.details(checkoutId, function(data) {
      try {
        dispatch(getActiveSubscriptionInfo);
        dispatch({ type: 'modals/modalCheckoutActive', payload: false });
        data.thankYouTitle = 'Order received';
        history.push({
          pathname: '/thankyou',
          state: data,
        });
      } catch (error) {
        openNotificationWithIcon('error', 'Error', 'Something gone wrong');
      }
    });
  }

  function paddleUnreachableHandling(description = 'Some trouble with payment system') {
    openNotificationWithIcon('error', 'Error', description);
    dispatch({type: 'modals/modalCheckoutActive', payload: false});
  }

  useEffect(() => {
    if (order && Object.keys(order).length !== 0 && order.constructor === Object) {
      let _temp = order;
      _temp.orderPositions.map(order => {
        order.product.key = uuidv4();
        order.product.name = order.product.name.charAt(0).toUpperCase() + order.product.name.slice(1);
        return true;
      });
      setCheckoutTotalPrice(order.totalPrice);
      setOrderStatus(order.status);
      setCheckoutOrderData([_temp.orderPositions[0].product],
        () => {
          setPaddleId(order.orderPositions[0].product.paddleId);
        });
    }

  }, [setCheckoutOrderData, order]);

  if (checkoutOrderData.length) {
    if (orderStatus !== 3) {
      return (
        <div className='Checkout'>
          <div className='Checkout__order'>
                <Table
                  className='settingsTable_subs Checkout__table'
                  columns={checkoutOrderCollumns}
                  dataSource={checkoutOrderData}
                  pagination={false}
                />
              </div>
          <div className='Checkout__order'>
                <Table
                  className='settingsTable_subs_one_column'
                  columns={totalPriceColumns}
                  dataSource={[{totalPrice: `$${checkoutTotalPrice}`, key: uuidv4()}]}
                  pagination={false}
                />
              </div>
          <Button variant='secondary' onClick={
              () => openCheckout ? openCheckout(order.id) : paddleUnreachableHandling('Paddle is unreachable now, try later')
            }>
            Pay {`$${checkoutTotalPrice}`}
          </Button>
          <p>By purchasing you agree with our <a className='text-link_black' target='_blank' href={`${process.env.REACT_APP_STORYTALE_APP}/privacy-policy`}>privacy policy</a></p>
          <p>VAT may be charged following your country law</p>
          <div className='Checkout__warning'>
            <div className='Checkout__warning-icon'>
              <ShieldCheck />
            </div>
            <p>If you switch a subscription plan, current subscription will be replaced by a new one</p>
          </div>
        </div>
      )
    }
    return history.goBack();
  }
  return null;
}

export default Checkout;
