import React from 'react';

import { Form, Input, Button, notification } from 'antd';

import { postFormData } from '../lib/apiRequests';
import useStateCallback from '../lib/customHooks/useStateCallback';

import './ContactUsForm.css';
import {CloseElement} from "./Icons";

const openNotificationWithIcon = (type, header, description) => {
  notification[type]({
    message: header,
    description: description,
    closeIcon: (<span><CloseElement/></span>),
  })
}

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const ContactUsForm = (props) => {
  const [responseData, setResponsedata] = useStateCallback();
  const [contactUsForm] = Form.useForm();

  const onFinish = (values) => {
    let formData = new FormData();
    formData.append('authorEmail', values.email);
    formData.append('authorName', values.name);
    formData.append('requestBody', values.message);
  
    postFormData('/api/user/contactUs', formData)
      .then(data => {
        setResponsedata(data, newData => { 
          if (newData.success) {
            contactUsForm.resetFields();
            return openNotificationWithIcon('success', 'Success', 'Message was sent'); 
          }
          return openNotificationWithIcon('error', 'Error', newData.message);
        });
      })
      .catch(err => { 
        console.log(responseData);
        setResponsedata(err, newData => { return openNotificationWithIcon('error', 'Error', newData.message)} )
      })
  };

  return (
    <Form
      form={contactUsForm}
      name='contactUsForm'
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className='ContactUsForm'
    >
      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
            message: 'Please input your email!',
            type: 'email',
          },
        ]}
        validateTrigger='onBlur'
      >
        <Input />
      </Form.Item>

      <Form.Item 
        name='message'
        label="Your message"
        rules={[
          {
            required: true,
            message: 'Please type your message first!',
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 6 }}/>
      </Form.Item>

      <div className='ContactUsForm__inner_flex'>
        <Form.Item
          name='name'
          label='Name'
        >
          <Input />
        </Form.Item>
        <Form.Item >
          <Button type='primary' htmlType='submit'>
            Send
          </Button>
        </Form.Item>
      </div> 
    </Form>     
  );
}

export default ContactUsForm;
