import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import '../common_styles/buttons.scss'
import "./Modal.scss"
import SubscriptionPlanCard from "./SubscriptionPlanCard";

import { postFormData } from '../lib/apiRequests';
import { openNotificationWithIcon } from '../lib/notifications';
import { connect } from "react-redux";
import { CloseElement } from "./Icons";

// const oldPrices = [19, 29, 39];

class ModalPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choosed_plans: [
        parseInt(process.env.REACT_APP_PLAN_ID_FIRST) || null,
        parseInt(process.env.REACT_APP_PLAN_ID_SECOND) || null,
        parseInt(process.env.REACT_APP_PLAN_ID_THIRD) || null
      ],
      cardButtonsDisabled: false,
      responseData: {
        success: false,
        isLoading: true,
        message: 'Something went wrong. Please try again later'
      }
    }
  }

  createOrder = (id) => {
    let formData = new FormData();

    formData.append('orderPositions[1][id]', id);
    formData.append('orderPositions[1][type]', 'subscription_plan');
    formData.append('orderPositions[1][count]', 1);

    this.setState({
      cardButtonsDisabled: true,
    });

    postFormData('/api/order/create', formData)
      .then(data => {
        if (data.success) {
          this.setState(prevState => ({
            ...prevState,
            responseData: {
              ...prevState.responseData,
              success: data.success,
              isLoading: false,
              order: data.result.order.id
            },
            cardButtonsDisabled: false,
          }));
          this.props.orderChoosed(data.result.order);
        } else {
          this.setState(prevState => ({
            ...prevState,
            responseData: {
              ...prevState.responseData,
              success: data.success,
              isLoading: false,
              message: data.message
            },
            cardButtonsDisabled: false,
          }));
          openNotificationWithIcon('error', 'Error', this.state.responseData.message);
        }
      })
      .catch(err => {
        this.setState(prevState => ({
          ...prevState,
          responseData: {
            ...prevState.responseData,
            success: false,
            isLoading: false,
            message: err.message
          },
          cardButtonsDisabled: false,
        }));
        if (err.message === 'Request failed with status code 401') {
          window.location.href = process.env.REACT_APP_ACCOUNT_APP;
        } else {
          openNotificationWithIcon('error', 'Error', err.message);
        }
      })
  };

  render() {
    const {cardButtonsDisabled, choosed_plans} = this.state;
    const {plans, modalPlansActive, handleClose } = this.props;

    const plansItems = choosed_plans.map((item, index) => {
        let btnType = 'outline blue';
        let cardClass = 'SubscriptionPlanCard_big';
        btnType = ((index-1)%3 === 0) ? 'secondary' : 'outline blue';
        cardClass += ((index-1)%3 === 0) ? ' SubscriptionPlanCard_yellow' : ' SubscriptionPlanCard_big';
        // const oldprice = oldPrices[index];
        return (
          plans.filter(plan => plan.id === item).map(plan => {
            return (
              <SubscriptionPlanCard
                key={index}
                id={plan.id}
                name={plan.name}
                price={plan.price}
                // oldprice={oldprice}
                duration={plan.durationLabel}
                durationCount={plan.durationCount}
                description={plan.description}
                downloadsPM={plan.downloadLimit}
                chargePeriodCount={plan.chargePeriodCount}
                chargePeriodLabel={plan.chargePeriodLabel}
                cardClass={cardClass}
                buttonType={btnType}
                handleClick={() => { this.createOrder(plan.id)}}
                buttonDisabled={cardButtonsDisabled}
                simple={true}
              />
            )
          })
        )
      });

    return (
      <Modal
            animation={true}
            show={modalPlansActive}
            onHide={handleClose}
            dialogClassName="modal-dialog modal-dialog-plans  modal-dialog-centered"
            backdropClassName="custom-backdrop"
        >
        <Modal.Header>
           <span className="close-button" onClick={handleClose}>
            <CloseElement/>
          </span>
        </Modal.Header>
            <Modal.Body>
                <h3 className="text-center">Pricing</h3>
                {/*<div className="black-friday-text">*/}
                {/*  <p>-50% for all subscription plans during Black Friday Sale</p>*/}
                {/*  <p>Join Storytale by 1 December</p>*/}
                {/*</div>*/}
               <div className="plans">
                   <div className="wrapper-container">
                       {plansItems}
                   </div>
                   <div className="text-center info">Renew, switch and cancel plan any time. VAT rate maybe charged, following your country law</div>
               </div>
            </Modal.Body>
        </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  plans: state['plans'].plans,
  modalPlansActive: state['modals'].modalPlansActive,
  orderChoosen: state['modals'].orderChoosen,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => {
    dispatch({ type: 'modals/modalPlansActive', payload: false });
    dispatch({ type: 'modals/orderChoosen', payload: {} });
  },
  orderChoosed: (order) => {
    dispatch({ type: 'modals/modalPlansActive', payload: false });
    dispatch({ type: 'modals/modalCheckoutActive', payload: true });
    dispatch({ type: 'modals/orderChoosen', payload: order});
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlans);

