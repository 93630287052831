import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, withRouter } from 'react-router-dom';
import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import Header from './components/Header';
import envs from './lib/envs';
import { hideCrisp, showCrisp } from "./lib/crisp";
import './textpage.css';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class Help extends Component {
  componentDidMount() {
    showCrisp();
  }

  componentWillUnmount() {
    hideCrisp();
  }

  render() {

    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Help</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container textpage'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='textpage__title'>Help</h1>
                <p>Have a question? Find the answer in our Frequently Asked Questions.</p>
                <h2 className='textpage__title_secondary'>What is Storytale?</h2>
                <p>Storytale is a marketplace of premium illustrations from the best illustrators all over the world. Come and get some super-cool illustrations for your project. Hundreds of vector and incredible illustrations to use.</p>
                <h2 className='textpage__title_secondary'>How can I use Storytale?</h2>
                <p>Storytale works as a subscription service with unlimited downloads. After purchasing a subscription, you get access to all existing illustrations and new releases during the whole subscription.</p>
                <h2 className='textpage__title_secondary'>How can I use Storytale illustrations?</h2>
                <p>You can use illustrations for both personal and commercial projects. Example: client’s work, dribbble shots, personal websites, social media accounts, advertising, physical items and so on.</p>
                <h2 className='textpage__title_secondary'>What is a Team plan?</h2>
                <p>Team plan is required if you are going to use our illustrations in your team which means 2 or more users.</p>
                <h2 className='textpage__title_secondary'>Can I upgrade my current subscription plan?</h2>
                <p>You do not need to upgrade your subscription plan as you get unlimited access to all items. If you need to use the illustrations in your team, you need to choose Team subscription plan.</p>
                <h2 className='textpage__title_secondary'>Can I get a discount?</h2>
                <p>Yes, discounts are applied if you are purchasing the annual subscription.</p>
                <h2 className='textpage__title_secondary'>Do I need to renew the subscription plan after a month?</h2>
                <p>No, you don’t need to. Once you buy a subscription, auto-renewal is activated automatically.</p>
                <h2 className='textpage__title_secondary'>Can I cancel my subscription?</h2>
                <p>Yes, you can cancel your subscription anytime. You will still have access to the website for the subscription period.</p>
                <h2 className='textpage__title_secondary'>Can I turn off my auto-renewal?</h2>
                <p>Yes, you can turn off auto-renewal in the settings. You won’t be charged the next time and won’t be able to download illustrations after your subscription period expires.</p>
                <h2 className='textpage__title_secondary'>Can I use downloaded illustrations after my subscription expires?</h2>
                <p>Yes, even after your subscription expires, you are permitted to use the illustrations you downloaded during your subscription period.</p>
                <h2 className='textpage__title_secondary'>I can’t find suitable illustrations.</h2>
                <p>If you cannot find the illustrations you need, you can use our <Link to='/order-custom'>Custom design</Link> service. We can create cool illustrations of any complexity specifically for your needs.</p>
              </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer/>
      </>
    )
    }
}

export default withRouter(Help);
