import React, { Component } from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';

//styles
import './Pack.css';

//components
import BeforeFooter from "./components/BeforeFooter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ReadyTo from "./components/ReadyTo";
import IllustrationsList from "./components/IllustrationsList";
import WidgetPack from "./components/WidgetPack";
import SkeletonPack from "./components/SkeletonPack";
import ModalIllustrationInfo from "./components/ModalIllustrationInfo";
import ModalDownloadLimit from "./components/ModalDownloadLimit";
import ModalSubscriptionError from "./components/ModalSubscriptionError";
import {Escape} from "./components/Icons";
import ButtonIcon from "./components/ButtonIcon";
import PacksGrid from "./components/PacksGrid";

//lib
import { setPagesHistory } from './lib/domainPagesHistory';
import downloadErrorHandler from "./lib/downloadErrorHandler";
import getIllustrationInfo from "./lib/getIllustrationInfo";
import changeIllustrationActivities from "./lib/changeIllustrationActivities";
import changePicturesLikesCountsLocally from "./lib/changePicturesLikesCountsLocally";
import addPictureLikeCounts from "./lib/addPictureLikeCounts";
import getCustomerActivities from "./lib/getCustomerActivities";
import metaTagsDelete from "./lib/metaTagsDelete";
import BrowseByPacks from "./components/BrowseByPacks";
import loadMoreIllustrationsFrom from "./lib/loadMoreIllustrationsFrom";
import scrollToTop from "./lib/scrollToTop";
import ScrollToTop from "./ScrollToTop";

//const
const COUNT = 12;
const MAX_PACKS = 12;
const storytaleUrl = process.env.REACT_APP_STORYTALE_APP;

class Pack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pictures: [],
      illustrationsFromPack: [],
      illustrationsFromKeywords: [],
      illustrationsFromKeywordsParams: {
        searchText: "",
        count: COUNT,
        page: 1
      },
      illustrationsFromPackParams: {
        packId: "",
        count: COUNT,
        sortBy: "3",
        page: 1
      },
      customerActivities: {},
      picturesLikesCounts: {},
      packs: [],
      count: COUNT,
      illustrationCount: 0,
      id: null,
      cover: null,
      packName: null,
      isLoading: true,
      isLoadingInfoData: true,
      isLoadingPackIllustrations: true,
      isLoadingKeywordsIllustrations: true,
      isLoadingPackInfo: true,
      showModalInfo: false,
      hasActiveSubscription : false,
      showModalDownloadLimits: false,
      showModalSubscriptionEnded: false,
      currentIllustrationPackData: null,
      currentIllustrationIndex: null,
      server: "",
    };

    this.handlePackClick = this.handlePackClick.bind(this);
    this.showModalInfo = this.showModalInfo.bind(this);
    this.hideModalInfo = this.hideModalInfo.bind(this);
    this.showModalDownloadLimits = this.showModalDownloadLimits.bind(this);
    this.hideModalDownloadLimits = this.hideModalDownloadLimits.bind(this);
    this.showModalSubscriptionEnded = this.showModalSubscriptionEnded.bind(this);
    this.hideModalSubscriptionEnded = this.hideModalSubscriptionEnded.bind(this);

    this.loadMoreIllustrationsFromWrapper = this.loadMoreIllustrationsFromWrapper.bind(this);

    this.getIllustrationInfoWrapper = this.getIllustrationInfoWrapper.bind(this);
    this.getCustomerActivitiesWrapper = this.getCustomerActivitiesWrapper.bind(this);
    this.changeIllustrationActivitiesWrapper = this.changeIllustrationActivitiesWrapper.bind(this);
    this.changePicturesLikesCountsLocallyWrapper = this.changePicturesLikesCountsLocallyWrapper.bind(this);

    this.downloadErrorHandlerWrapper = this.downloadErrorHandlerWrapper.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }
  componentDidMount() {
    if(document) {
      document.getElementsByTagName('body')[0].scrollTo(0, 0);
    }


    this.timerID = setTimeout(() => {
      if (window.__ROUTE_DATA__) {
        this.setState({
          server: window.__ROUTE_DATA__
        });
        delete window.__ROUTE_DATA__;
        clearTimeout(this.timerID);
        metaTagsDelete('meta[data-render-type="csr"]');
      } else {
        this.setState({
          server: null
        });
      }
    }, 0);

    this.callApi();
    setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {

      if(document) {
        document.getElementsByTagName('body')[0].scrollTo(0, 0);
      }
      clearTimeout(this.timerID);
      this.setState({
        server: null
      });
    }

    if (this.state.server === null) {
      metaTagsDelete('meta[data-render-type="ssr"]');
    }

    if(prevProps.authorized !== this.props.authorized && this.props.authorized === true) {
      this.getCustomerActivitiesWrapper(this.state.pictures);
    }
  }

  getIllustrationInfoWrapper = (id) => {
    const props = this;
    const  callback = () => setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
    getIllustrationInfo(props, id, callback, this.getCustomerActivitiesWrapper);
  };

  changeIllustrationActivitiesWrapper = (id, type, result) => {
    const props = this;

    changeIllustrationActivities(props, id, type, result);
  };

  changePicturesLikesCountsLocallyWrapper = (id, actionTaken) => {
    const stateOwnerThis = this;
    changePicturesLikesCountsLocally(stateOwnerThis, id, actionTaken);
  };

  getCustomerActivitiesWrapper = (originalData) => {
    const props = this;
    getCustomerActivities(props, originalData);
  };

  loadMoreIllustrationsFromWrapper = () => {
    const props = this;
    loadMoreIllustrationsFrom(props);
  };

  downloadErrorHandlerWrapper = (errorCode) => {
    const {history, showModalPlans} = this.props;
    downloadErrorHandler(errorCode, history, this.showModalSubscriptionEnded, this.showModalDownloadLimits, showModalPlans);
  };

  callApi = async (id = null) => {

    const responsePack = await axios.get(`/api/pack/${id === null ? this.props.id : id}`);
    const responsePackIllustration = await axios.get(`/api/illustration?packId=${id === null ? this.props.id : id}&count=999&sortBy=3`);
    const responsePacks = await axios.get(`/api/pack?count=${MAX_PACKS}`);

    axios.all([responsePack, responsePackIllustration, responsePacks])
      .then(axios.spread((...responses) => {
        const { packData } = responses[0].data.result;
        const { illustrationData } = responses[1].data.result;
        const { packsData } = responses[2].data.result;

        if (packData === null) {
          this.setState({
            error: true
          });
        }
        const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);
        const { name, id,  cover, description, illustrationCount } = packData;

        this.setState(
          prevState => {
            return {
              packs: packsData,
              packName: name,
              description : description,
              cover: cover,
              illustrationCount: illustrationCount,
              id: id,
              pictures: illustrationData,
              count: COUNT,
              isLoading: false,
              currentIllustrationData: [],
              showModalInfo: false,
              isLoadingInfoData: false,
              showModalPlans: false,
              picturesLikesCounts: updatedPicturesLikesCounts,
            };
          },
          () => {
            this.getCustomerActivitiesWrapper(this.state.pictures);
          });
      }));
    return;
  };

  handlePackClick(id = null) {
    this.setState(prevState => {
      return {
        isLoading: true
      }
    }, () => this.callApi(id))
  }

  showModalInfo(id) {
    this.setState({
      showModalInfo: true,
    });
  }

  hideModalInfo(id) {
    window.history.pushState('', '',`/pack/${this.state.id}`);
    this.setState({
      showModalInfo: false,
    });
  }

  onConcatRequest() {

    axios.get(`/api/pack`, {
      params: {
        count: this.state.count,
      }
    })
    .then((response) => {
      this.setState({
        packs: response.data.result.packsData
      });
    })
  }

  goAccount = () => {
    window.location.href = `/error`;
  };

  hideModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: false,
    });
  }

  showModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: true,
    });
  }

  hideModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: false,
    });
  }

  showModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: true,
    });
  }

  redirectTo(value) {
    const {history} = this.props;
    history.push(`/browse/?searchText=${value}`)
  }

  render() {
    const {
      pictures,
      packName,
      packs,
      cover,
      id,
      isLoading,
      error,
      illustrationCount,
      showModalInfo,
      isLoadingInfoData,
      description,
      server,
      currentIllustrationInfo,
      currentIllustrationPackData,
      illustrationsFromPack,
      illustrationsFromKeywords,
      illustrationsFromKeywordsParams,
      illustrationsFromPackParams,
      customerActivities,
      isLoadingPackInfo,
      isLoadingPackIllustrations,
      picturesLikesCounts,
    } = this.state;
    const {autoRenewal, endDate, hasSubscribe, history} = this.props;

    return (
      <div className="pack">
        {error === true && this.goAccount()}

        <Header/>

        {server === null &&
          <Helmet>
            <title>{packName ? `${packName} illustration pack — Storytale` : 'Illustration pack — Storytale'}</title>
            <meta data-render-type="csr" name="description" content={description ? description : "Project description here"} data-react-helmet="true"/>
            <meta data-render-type="csr" name="theme-color" data-react-helmet="true"/>
            <meta data-render-type="csr" property="og:title" content={packName ? `${packName} illustration pack — Storytale` : 'Illustration pack — Storytale'} data-react-helmet="true"/>
            <meta data-render-type="csr" property="og:description" content={description ? description : "Pack description here"} data-react-helmet="true"/>
            <meta data-render-type="csr" property="og:image" content={cover && cover} data-react-helmet="true"/>
            <meta data-render-type="csr" property="og:url" content={cover && (storytaleUrl + history.location.pathname)} data-react-helmet="true"/>
          </Helmet>
        }

        <ModalDownloadLimit
          show={this.state.showModalDownloadLimits}
          handleClose={this.hideModalDownloadLimits}
          endDate={moment(endDate).format("MMM Do")}
          autoRenewal={autoRenewal}
        />
        <ModalSubscriptionError
          show={this.state.showModalSubscriptionEnded}
          handleClose={this.hideModalSubscriptionEnded}
        />
        <ModalIllustrationInfo
          show={showModalInfo}
          showModalLogin={this.showModal}
          handleClose={() => {
            this.hideModalInfo();
          }}
          data={currentIllustrationInfo}
          packData={currentIllustrationPackData}
          packIllustrations={illustrationsFromPack}
          keywordsIllustrations={illustrationsFromKeywords}
          requestKeywordsParams={illustrationsFromKeywordsParams}
          requestPackParams={illustrationsFromPackParams}
          getCustomerActivities={customerActivities}
          isLoading={isLoadingInfoData}
          isLoadingPackInfo={isLoadingPackInfo}
          isLoadingPackIllustrations={isLoadingPackIllustrations}
          callbackButtonKeywordsClick={this.loadMoreIllustrationsFromWrapper}
          callbackButtonPackClick={(id) => {
            this.hideModalInfo();
            this.handlePackClick(id);
            scrollToTop();
          }}
          callbackPackClick={(id) => {
            this.hideModalInfo();
            this.handlePackClick(id);
            scrollToTop();
          }}
          customerActivities={this.changeIllustrationActivitiesWrapper}
          downloadErrorHandler={this.downloadErrorHandlerWrapper}
          callbackTagClick={this.redirectTo}
          packs={packs}
          onClick={this.getIllustrationInfoWrapper}
          customButtonHeader={true}
          picturesLikesCounts={picturesLikesCounts}
          changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
        />
        <Link to={{ pathname: '/browse/' }} >
          <ButtonIcon
            isEmulate={true}
            icon={<Escape/>}
            content="Browse"
            classes="large neutral outline escape sticky"
          />
        </Link>
        <div className="full-pack">
          <section className="illustrations-pack illustrations-pack-full">
            <h2 className="visually-hidden">illustration pack</h2>
            <div className="container-custom">
              <div className="row">
                <div className="col-lg-5 col-sm-6 mx-auto">
                  {isLoading ? <SkeletonPack type="full"/> :
                    <WidgetPack
                        id={id}
                        name={packName}
                        cover={cover}
                        quantity={illustrationCount}
                        description={description}
                        type="full"
                    />
                  }
                </div>

                <div className="col-12">
                  <IllustrationsList
                    pictures={pictures}
                    onClick={this.getIllustrationInfoWrapper}
                    status={isLoading}
                    customerActivities={this.changeIllustrationActivitiesWrapper}
                    containerType={"col3"}
                    downloadErrorHandler={this.downloadErrorHandlerWrapper}
                    getCustomerActivities={customerActivities}
                    picturesLikesCounts={picturesLikesCounts}
                    changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        {!hasSubscribe &&
          <ReadyTo text="Like these illustrations?" />
        }

        <PacksGrid
          title={"Recent projects"}
          packs={packs}
          isLoading={isLoading}
          quantity={MAX_PACKS}
          onClick={(id) => {
            this.handlePackClick(id)
          }}
        />
        {/*<BeforeFooter />*/}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    autoRenewal: state['user'].autoRenewal,
    endDate: state['user'].endDate,
    hasSubscribe: state['user'].hasSubscribe,
});

const mapDispatchToProps = dispatch => ({
  showModalPlans: () => dispatch({ type: 'modals/modalPlansActive', payload: true }),
});

const PackRouter = withRouter(Pack);
export default connect(mapStateToProps, mapDispatchToProps)(PackRouter);
