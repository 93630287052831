import React, { Component } from 'react';
import { Link } from "react-router-dom";

import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import Header from './components/Header';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";

import envs from './lib/envs';

import './textpage.css';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class CookiesPolicy extends Component {
  render() {
    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Cookies</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container textpage'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='textpage__title'>Cookies Policy</h1>
                <h2 className='textpage__title_secondary'>What are cookies?</h2>
                <p>Cookies are small text files that are stored in the web browser that allows Storytale or a third party to recognize you. Cookies can be used to collect, store and share bits of information about your activities across websites, including on Storytale website.</p>
                <h2 className='textpage__title_secondary'>Cookies might be used for the following purposes:</h2>
                <p>To enable certain functions</p>
                <p>To provide analytics</p>
                <p>To store your preferences</p>
                <p>To enable ad delivery and behavioral advertising</p>
                <h2 className='textpage__title_secondary'>Type of Cookies</h2>
                <h3 className='textpage__text_lead'>Session Cookie</h3>
                <p>A session cookie is used to identify a particular visit to our Website. These cookies expire after a short time, or when you close your web browser after using our Website. We use these cookies to identify you during a single browsing session, such as when you log into our Website.</p>
                <h3 className='textpage__text_lead'>Persistent Cookie</h3>
                <p>A persistent cookie will remain on your devices for a set period of time specified in the cookie. We use these cookies where we need to identify you over a longer period of time. For example, we would use a persistent cookie if you asked that we keep you signed in.</p>
                <p>Storytale uses both session cookies and persistent cookies.</p>
                <h2 className='textpage__title_secondary'>How do third parties use cookies on the Storytale Website?</h2>
                <p>Third-party companies like analytics companies and ad networks generally use cookies to collect user information on an anonymous basis. They may use that information to build a profile of your activities on the Storytale and other websites that you’ve visited.</p>
                <h2 className='textpage__title_secondary'>What are your cookies options?</h2>
                <p>If you don’t like the idea of cookies or certain types of cookies, you can change your browser’s settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser.</p>
                <p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>
                <h2 className='textpage__title_secondary'>Where can I find more information about cookies?</h2>
                <p>You can learn more about cookies by visiting the following third-party websites:</p>
                <p><a href='https://www.allaboutcookies.org/' target='_blank' rel='noreferrer' >https://www.allaboutcookies.org/</a> </p>
                <h2 className='textpage__title_secondary'>Contact Us</h2>
                <p>If you have any questions about these Terms, please <Link to='/contact-us'>contact us</Link>.</p>
              </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer/>
      </>
    )
    }
}

export default withRouter(CookiesPolicy);
