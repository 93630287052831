import React from "react";
import Modal from "react-bootstrap/Modal";
import '../common_styles/buttons.scss'
import "./Modal.scss"
import ProductInfo from "./ProductInfo";
import { CloseElement, Escape } from "./Icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import ButtonIcon from "./ButtonIcon";
import PacksGrid from "./PacksGrid";
import BrowseByIllustrations from "./BrowseByIllustrations";

const MAX_PACKS = 15;

const ModalIllustrationInfo = (
  {
    show,
    handleClose,
    isLoading,
    isLoadingPackInfo,
    isLoadingPackIllustrations,
    isLoadingKeywordsIllustrations,
    data,
    packData,
    status,
    hasSubscription,
    showModalLogin,
    requestKeywordsParams,
    requestPackParams,
    customerActivities,
    callbackMoodClick = null,
    callbackCategoryClick = null,
    callbackButtonKeywordsClick = () => { },
    callbackButtonPackClick = () => { },
    callbackPackClick = () => { },
    customButtonCallback = () => { },
    downloadErrorHandler,
    callbackTagClick,
    packs,
    packIllustrations,
    keywordsIllustrations,
    getCustomerActivities,
    onClick,
    customButtonHeader = false,
    redirect = false,
    showMorePackButton = true,
    skeletonCount = 14,
    picturesLikesCounts,
    changeLikesCountsLocally,
  }) => {

  const scrollToTop = () => {
    let anchorElement = document.getElementsByClassName('target')[0];
    if (anchorElement !== null) {
      anchorElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
  };

  const packsData = packs.filter(
    (item, index) => index < MAX_PACKS
  );

  return (
    <Modal
      animation={true}
      show={show}
      className="target"
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-illustration-info  modal-dialog-centered"
    >
      <Modal.Header>

        {isLoadingPackInfo ?
          <Skeleton variant="rect" animation="wave" height={50} width={140} />
          :
          customButtonHeader ?
            <ButtonIcon
              isEmulate={true}
              callback={() => {
                callbackPackClick(packData.id)
              }}
              classes="large neutral outline escape"
              icon={
                <Escape />
              }
              content={
                <>
                  <Avatar size={32} shape="circle" src={packData && packData.cover} />
                  {packData && packData.name}
                </>
              }
            /> :
            <Link
              to={`/pack/${packData && packData.id}`}
            >
              <ButtonIcon
                isEmulate={true}
                classes="large neutral outline escape"
                icon={
                  <Escape />
                }
                content={
                  <>
                    <Avatar size={32} shape="circle" src={packData && packData.cover} />
                    <p className="text-content">{packData && packData.name}</p>
                  </>
                }
              />
            </Link>
        }

        <span className="close-button" onClick={handleClose}>
          <CloseElement />
        </span>
      </Modal.Header>

      <Modal.Body>
        <ProductInfo
          data={data}
          isLoading={isLoading}
          hasSubscription={hasSubscription}
          inModal={true}
          status={status}
          showModalLogin={showModalLogin}
          customerActivities={customerActivities}
          callbackMoodClick={callbackMoodClick}
          callbackCategoryClick={callbackCategoryClick}
          callbackTagClick={callbackTagClick}
          downloadErrorHandler={downloadErrorHandler}
          handleClose={handleClose}
          getCustomerActivities={getCustomerActivities}
          picturesLikesCounts={picturesLikesCounts}
          changeLikesCountsLocally={changeLikesCountsLocally}
        />

        {keywordsIllustrations.length > 0 &&
          <section className="section">
            <BrowseByIllustrations
              titleText={`You might also like`}
              buttonText={`Load more assets`}
              pictures={keywordsIllustrations}
              requestParams={requestKeywordsParams}
              onClick={(id) => {
                onClick(id);
                scrollToTop();
              }}
              callback={callbackButtonKeywordsClick}
              status={isLoadingKeywordsIllustrations}
              containerType="col3"
              customerActivities={customerActivities}
              downloadErrorHandler={downloadErrorHandler}
              getCustomerActivities={getCustomerActivities}
              picturesLikesCounts={picturesLikesCounts}
              changeLikesCountsLocally={changeLikesCountsLocally}
            />
          </section>
        }

        {packIllustrations.length > 0 &&
          <section className="section">
            <BrowseByIllustrations
              titleText={`More from ${packData && packData.name}`}
              href={packData && packData.id}
              pictures={packIllustrations}
              requestParams={requestPackParams}
              onClick={(id) => {
                onClick(id);
                scrollToTop();
              }}
              callback={() => {
                callbackButtonPackClick(packData.id)
              }}
              redirect={redirect}
              status={isLoadingPackIllustrations}
              containerType="col3"
              customerActivities={customerActivities}
              downloadErrorHandler={downloadErrorHandler}
              getCustomerActivities={getCustomerActivities}
              picturesLikesCounts={picturesLikesCounts}
              changeLikesCountsLocally={changeLikesCountsLocally}
            />
          </section>
        }

        <PacksGrid
          title={"Recent projects"}
          packs={packsData}
          isLoading={isLoading}
          quantity={MAX_PACKS}
          hasButton={showMorePackButton}
        />
      </Modal.Body>
    </Modal>
  )
};
export default ModalIllustrationInfo;
