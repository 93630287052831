import getOffset from "./getOffset";

const handleScroll = (containerComponent, paramsFrom, paramsTo, topGap, bottomGap, targetState) => {

  let heightToShowFrom;
  let heightToShowTo = getOffset(document.querySelector(`${paramsTo}`), bottomGap);

  if(window.innerWidth < 768) {
    heightToShowFrom = getOffset(document.querySelector(`${paramsFrom}`), -(topGap + 100));
  } else {
    heightToShowFrom = getOffset(document.querySelector(`${paramsFrom}`), -topGap);
  }

  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;


  if (winScroll > heightToShowFrom && winScroll < heightToShowTo) {
    containerComponent.setState(
        () => {
          return {
            [targetState]: true,
            lastScroll: winScroll
          };
        }
      );
  }
  else if  (winScroll < heightToShowFrom)  {
    containerComponent.setState(
      () => {
        return {
          [targetState]: false,
        };
      }
    );
  }
  else if (winScroll > heightToShowFrom && winScroll > heightToShowTo ) {
    containerComponent.setState(
        () => {
          return {
            [targetState]: false,
            lastScroll: winScroll
          };
        }
      );
  }
};

export default handleScroll;
