const changePicturesLikesCountsLocally = (stateOwnerThis, id, actionTaken) => {
  const updatedPicturesLikesCounts = Object.assign({}, stateOwnerThis.state.picturesLikesCounts);
  switch (actionTaken) {
    case 'like':
      updatedPicturesLikesCounts[id] += 1;
      break;
    case 'unlike':
      updatedPicturesLikesCounts[id] -= 1;
      break;
    default:
      break;
  }

  stateOwnerThis.setState(prevState => {
    return {
      picturesLikesCounts: updatedPicturesLikesCounts,
    };
  })
};

export default changePicturesLikesCountsLocally;
