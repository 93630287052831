import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import PackCard from "./PackCard";
import "./PacksGrid.scss";

function PacksGrid({
  packs,
  isLoading,
  title,
  description,
  quantity,
  onClick,
  hasSection = true,
  hasButton = true,
  hasContainer = true,
  hasSmartGrid = false,
  button_content = "",
  buttonLink = "",
}) {
  function renderSkeletonCards(quantity) {
    const skeletonCards = [];
    for (let x = 0; x < quantity; x++) {
      skeletonCards.push(
        <div key={uuidv4()}>
          <Skeleton variant="rect" width="100%" animation="pulse">
            <span style={{ display: "block", paddingTop: "75%" }} />
          </Skeleton>
          <div className="packs-grid__skeleton-box">
            <Skeleton
              variant="circle"
              width={56}
              height={56}
              animation="pulse"
              className="packs-grid__skeleton-circle"
            />
            <div className="packs-grid__skeleton-container">
              <Skeleton
                variant="rect"
                width="100%"
                height={24}
                animation="pulse"
                className="packs-grid__skeleton-text"
              />
              <Skeleton
                variant="rect"
                width="100%"
                height={22}
                animation="pulse"
                className="packs-grid__skeleton-text"
              />
              <Skeleton
                variant="rect"
                width="100%"
                height={38}
                animation="pulse"
                className="packs-grid__skeleton-text"
              />
            </div>
          </div>
        </div>
      );
    }
    return <>{skeletonCards}</>;
  }

  return (
    <section
      className={`${hasContainer ? "container-custom" : ""} ${
        hasSection ? "section" : ""
      } packs-grid`}
    >
      <h4 className="packs-grid__title">{title}</h4>
      <div className="packs-grid__description">{description}</div>
      {hasButton && (
        <div className="packs-grid__button">
          <Link to={buttonLink}>
            <div className="packs-grid__button_packs">
              <span className="packs-grid__button_content">{button_content}</span>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Tail icon">
                  <path
                    id="Vector"
                    d="M3.83301 10H17.1663M17.1663 10L12.1663 5M17.1663 10L12.1663 15"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
          </Link>
        </div>
      )}
      <section
        className={`${
          hasSmartGrid ? "packs-grid__grid-smart" : "packs-grid__grid"
        }`}
      >
        {isLoading
          ? renderSkeletonCards(quantity)
          : packs && packs?.length > 0
          ? packs.map((packData) => {
              return (
                <PackCard
                  onClick={onClick}
                  packData={packData}
                  key={packData.id}
                />
              );
            })
          : null}
      </section>
    </section>
  );
}

export default PacksGrid;
