import axios from 'axios';

require('dotenv').config();

export class ErrorLoger {
  version = '1.1';
  url = process.env.REACT_APP_GELF_URL;
  host = process.env.REACT_APP_GELF_HOST;
  facility = process.env.REACT_APP_GELF_LOGGER_FACILITY;

  sendEmergency(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Emergency',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          0,
      'props':          props,
    });
  }

  sendAlert(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Alert',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          1,
      'props':          props,
    });
  }

  sendCritical(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Critical',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          2,
      'props':          props,
    });
  }

  sendError(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Error',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          3,
      'props':          props,
    });
  }

  sendWarning(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Warning',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          4,
      'props':          props,
    });
  }

  sendNotice(error, errorInfo, errorStack,  location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Notice',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          5,
      'props':          props,
    });
  }

  sendInfo(error, errorInfo, errorStack,  location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Info',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          6,
      'props':          props,
    });
  }

  sendDebug(error, errorInfo, errorStack, location, props) {
    axios.post(`${this.url}`, {
      'error':          error,
      'errorInfo':      errorInfo,
      'errorStack':     errorStack,
      'location':       location,
      'short_message':  'Debug',
      'version':        this.version,
      'host':           this.host,
      'facility':       this.facility,
      'level':          7,
      'props':          props,
    });
  }

  // Method for env configuration check
  getConfig() {
    return {
      version: this.version,
      url: this.url,
      host: this.host,
      port: this.port,
      facility: this.facility
    }
  }
}
