import React, { Component } from "react";
import "./Main.css";

import { withRouter } from "react-router";
import BeforeFooter from "./components/BeforeFooter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ReadyTo from "./components/ReadyTo";

import axios from "axios";

import ModalSubscriptionError from "./components/ModalSubscriptionError";
import HeaderIntro from "./components/HeaderIntro";
import HowItWorks from "./components/HowItWorks";
import { connect } from "react-redux";
import { setPagesHistory } from "./lib/domainPagesHistory";
import queryString from "query-string";
import { notification } from "antd";
import { Helmet } from "react-helmet";

import { showCrisp, hideCrisp } from "./lib/crisp";
import { CloseElement } from "./components/Icons";
import AdvantagesTiles from "./components/AdvantagesTiles";
import TagCloud from "./components/TagCloud";
import CompaniesLogos from "./components/CompaniesLogos";
import RatingCard from "./components/RatingCard";
import SubscriptionWorkflow from "./components/SubscriptionWorkflow";
import PacksGrid from "./components/PacksGrid";
import BrowseByMood from "./components/BrowseByMood";
import BrowseByCategory from "./components/BrowseByCategory";
import BlackFridayPopup from "./components/BlackFridayPopup";
import { HowItWorksSecondSection } from "./components/HowItWorksSecondSection";

const MAX_PACKS = 6;

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packs: [],
      vectorPacks: [],
      _3DPacks: [],
      mockupsPacks: [],
      isLoading: true,
      isLoadingVector: true,
      isLoading3D: true,
      isLoadingMockups: true,
      showModalSubscriptionEnded: false,
      searchForIllustrations: "",
    };

    this.showModalSubscriptionEnded =
      this.showModalSubscriptionEnded.bind(this);
    this.hideModalSubscriptionEnded =
      this.hideModalSubscriptionEnded.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.onChangeSearchForIllustrations =
      this.onChangeSearchForIllustrations.bind(this);
  }

  componentDidMount() {
    this.callApi();
    this.get3DPacks();
    this.getMockupsPacks();
    this.getVectorPacks();

    setPagesHistory(
      window.location.origin,
      this.props.history.location.pathname,
      30,
      process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN
    );
    var { emailConfrim } = queryString.parse(this.props.location.search);

    const openNotificationWithIcon = (type, header, description) => {
      notification[type]({
        message: header,
        description: description,
        closeIcon: (
          <span>
            <CloseElement />
          </span>
        ),
      });
    };

    if (emailConfrim) {
      openNotificationWithIcon(
        "success",
        "Success email confirmation",
        "Congratulations your email has been confirmed!"
      );
    }

    showCrisp();
  }

  callApi = async () => {
    axios
      .get(`/api/pack`)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.result.packsData.filter(
            (item, index) => index < MAX_PACKS
          );

          this.setState({
            packs: data,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get3DPacks = async () => {
    axios
      .get(`/api/pack/?categoryId=2`)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          const data = response.data.result.packsData.filter(
            (item, index) => index < MAX_PACKS
          );

          this.setState({
            _3DPacks: data,
            isLoading3D: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  getVectorPacks = async () => {
    axios
      .get(`/api/pack/?categoryId=1&typeId=1`)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          const data = response.data.result.packsData.filter(
            (item, index) => index < MAX_PACKS
          );

          this.setState({
            vectorPacks: data,
            isLoadingVector: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMockupsPacks = async () => {
    axios
      .get(`/api/pack/?categoryId=6`)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          const data = response.data.result.packsData.filter(
            (item, index) => index < MAX_PACKS
          );

          this.setState({
            mockupsPacks: data,
            isLoadingMockups: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }




  componentWillUnmount() {
    hideCrisp();
  }

  hideModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: false,
    });
  }

  showModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: true,
    });
  }

  redirectTo(value) {
    const { history } = this.props;
    history.push(`/browse/?searchText=${value}`);
  }

  onChangeSearchForIllustrations(value) {
    this.setState({
      searchForIllustrations: value,
    });
  }

  render() {
    const {
      packs,
      _3DPacks,
      mockupsPacks,
      vectorPacks,
      isLoading,
      isLoadingMockups,
      isLoadingVector,
      isLoading3D,
      searchForIllustrations,
      showModalSubscriptionEnded,
    } = this.state;

    const { hasSubscribe } = this.props;

    return (
      <div className="main-page">
        <Header />
        <Helmet>
          <title>Storytale</title>
        </Helmet>

        <ModalSubscriptionError
          show={showModalSubscriptionEnded}
          handleClose={this.hideModalSubscriptionEnded}
        />
        {/*<BlackFridayPopup/>*/}

        <HeaderIntro
          onSubmitCallback={this.redirectTo}
          inputValue={searchForIllustrations}
          onChangeCallback={this.onChangeSearchForIllustrations}
        />
        <HowItWorks />

        {/* <BrowseByCategory /> */}

        <PacksGrid
          title={"3D illustrations"}
          description={
            "3D illustration became a passion for many creators. Explore volumetric illustrations, full of brightness, attractiveness, non-trivial shapes, and textures."
          }
          button_content="Explore all 3D packs"
          buttonLink="/browse/?categoryId=2"
          packs={_3DPacks}
          isLoading={isLoading3D}
          quantity={MAX_PACKS}
        />

        <PacksGrid
          title={"Vector illustrations"}
          description={
            "Huge collection of vector illustrations. Business, finance, design, science, technologies, medicine, education, lifestyle, anything you want!"
          }
          button_content="Explore all vector packs"
          buttonLink="/browse/?typeId=1&categoryId=1"
          packs={vectorPacks}
          isLoading={isLoadingVector}
          quantity={MAX_PACKS}
        />

        <PacksGrid
          title={"Mockups"}
          description={
            "Mockups are able to get your design to the professional level. Meet a big collection of realistic mockups, photo mockups, and minimalistic ones."
          }
          button_content="Explore all mockups packs"
          buttonLink="/browse/?categoryId=6"
          packs={mockupsPacks}
          isLoading={isLoadingMockups}
          quantity={MAX_PACKS}
        />

        {/* <TagCloud /> */}

        {/* <AdvantagesTiles /> */}

          <HowItWorksSecondSection />

        {/*
        <CompaniesLogos
          withBorders={false}
          boldTitle={true}
          title="Trusted by the coolest players in the game"
        /> */}

        {/* <RatingCard /> */}

        {hasSubscribe !== null && !hasSubscribe && <ReadyTo />}

        {/* <BeforeFooter /> */}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  autoRenewal: state["user"].autoRenewal,
  endDate: state["user"].endDate,
  hasSubscribe: state["user"].hasSubscribe,
  authorized: state["user"].authorized,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPlans: () =>
    dispatch({ type: "modals/modalPlansActive", payload: true }),
});

const MainRouter = withRouter(Main);
export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);
