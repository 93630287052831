import axios from "axios";

const initialState = {
  plans: [],
}

export default function plansReducer(state = initialState, action) {
  switch (action.type) {
    case 'plans/plansLoad': {

      return {
        ...state,
        plans: action.payload,
      }
    }
    default:
      return state
  }
}

// Thunk function
export async function fetchPlansData(dispatch, _getState) {
  await axios.get(`/api/subscriptionPlan`).then((response) => {
    const {success, result} = response.data;
    const {subscriptionPlans} = result;

    if(success) {
      dispatch({ type: 'plans/plansLoad', payload: subscriptionPlans})
    }
  })
    .catch((error) => {
      console.log(error)
    })
}

