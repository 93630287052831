import React from "react";
import ButtonBase from "./ButtonBase";

const Block = (props) => {
  const { forwardedRef, data, categoryParams, text="illustrations"} = props;
  const {count, page} = categoryParams;

  return (
    <>
      <div className="viewport-block" ref={forwardedRef}>
        <ButtonBase
          classes={`custom mx-auto ${data.length !== (+count * +page) && `hidden`}`}
          content={`Loading ${text}...`}
        />
      </div>
    </>
  );
};

export default Block;