import React from 'react';
import { useDispatch } from "react-redux";
import ButtonBase from './ButtonBase';
import './ReadyTo.css';

function ReadyTo() {
  const dispatch = useDispatch()
  const getFullAccessClick = () => dispatch({ type: 'modals/modalPlansActive', payload: true});

  return (
    <section className='ready-to section container-custom'>
      <div className='row'>
        <div className='col-lg-10 mx-auto'>
          <h4 className='text-center like-title'>Ready to start creating?</h4>
          <p className='description'>Unlock access to every existing design on Storytale and upcoming releases during whole subscribtion</p>
        </div>
        <div className='col-auto mx-auto position-relative'>
          <div className='large-link mx-auto'>
            <ButtonBase
              isEmulate={true}
              callback={() => getFullAccessClick()}
              content={`Get full access`}
              classes='large blue'
            />
          </div>
          <svg className='position-absolute noice-1' width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.60488 8.47578C7.20961 5.80537 4.81434 3.13495 2.41906 0.464533C1.16154 -0.931364 -0.844494 1.13213 0.383084 2.52803C2.77836 5.19844 5.17363 7.86886 7.56891 10.5393C8.82642 11.9352 10.8624 9.84133 9.60488 8.47578Z' fill='#FF98E1' />
          </svg>
          <svg className='position-absolute noice-2' width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.64145 6.61685C5.84796 4.5572 4.05447 2.49756 2.26099 0.437908C1.11203 -0.877981 -0.793543 1.06724 0.35541 2.38313C2.1489 4.44278 3.94239 6.50244 5.73588 8.56209C6.91285 9.87798 8.79041 7.93274 7.64145 6.61685Z' fill='#FF98E1' />
          </svg>
          <svg className='position-absolute noice-3' width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.5 15C3.375 15 0 11.625 0 7.5C0 3.375 3.375 0 7.5 0C11.625 0 15 3.375 15 7.5C15 11.625 11.625 15 7.5 15ZM7.5 2.99999C5.03125 2.99999 3 5.03125 3 7.5C3 9.96875 5.03125 12 7.5 12C9.96875 12 12 9.96875 12 7.5C12 5.03125 9.96875 2.99999 7.5 2.99999Z' fill='#F76567' />
          </svg>
          <svg className='position-absolute noice-4' width='23' height='30' viewBox='0 0 23 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M13.3962 30C12.9263 30 12.5087 29.7432 12.2737 29.3323C10.6814 26.302 5.53895 18.3412 1.12741 17.8533C0.52702 17.8019 0.0832581 17.314 0.00494661 16.749C-0.047261 16.1584 0.318199 15.6191 0.892483 15.465C7.67947 13.4106 10.6292 1.08414 10.6553 0.955739C10.7858 0.442136 11.2296 0.0569304 11.7778 0.00557013C12.2999 -0.0457902 12.8219 0.262378 13.0308 0.750301C15.8239 7.2217 20.0266 14.4378 21.9583 14.7717C22.4281 14.8487 22.7936 15.1826 22.9502 15.6191C23.0807 16.0557 22.9502 16.5436 22.6109 16.8774C20.4965 18.8805 14.9363 24.9667 14.6492 28.8701C14.597 29.4093 14.2054 29.8716 13.6572 30C13.5789 30 13.4745 30 13.3962 30ZM4.4426 16.4409C8.04492 18.4182 11.1774 22.7325 12.9785 25.583C14.5448 21.9364 17.8861 18.1614 19.6872 16.2868C16.8941 14.104 13.9444 8.63411 12.1171 4.78209C10.8902 8.27459 8.46258 13.6674 4.4426 16.4409Z' fill='#FFDF57' />
          </svg>
          <svg className='position-absolute noice-5' width='37' height='20' viewBox='0 0 37 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M34.9717 18.9051C34.9613 19.0346 34.9324 19.1626 34.868 19.2691C34.6288 19.6967 34.076 19.8571 33.6484 19.6179L1.99173 1.87992C1.56414 1.64073 1.40232 1.10643 1.64299 0.660349C1.88217 0.232765 2.43498 0.0724342 2.86256 0.311617L34.5193 18.0496C34.8218 18.2229 34.9985 18.5722 34.9717 18.9051Z' fill='#F76567' />
          </svg>
          <svg className='position-absolute noice-6' width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6.68693 20.9747C6.15248 20.9747 5.69092 20.595 5.56946 20.0635C4.30623 15.1025 2.21702 11.0274 1.39106 11.2299C0.759449 11.3565 0.152132 10.9515 0.0306675 10.2934C-0.11509 9.66064 0.273596 9.00255 0.880919 8.85069C2.92152 8.31915 4.64632 3.58599 5.22935 1.18144C5.25365 0.903015 5.35081 0.599276 5.64232 0.320854C6.03101 -0.0335006 6.56547 -0.109428 7.02703 0.168994C7.44001 0.422105 7.65863 0.877692 7.58575 1.3586C8.04732 3.13038 11.7156 8.95193 12.9788 9.1291C13.5618 9.20504 14.0234 9.73657 13.9991 10.3694C13.9748 11.0021 13.5132 11.5084 12.9059 11.559C11.9585 11.6349 8.41171 15.5834 7.85297 19.9369C7.78009 20.5191 7.31852 20.9494 6.75979 21C6.7355 20.9747 6.71122 20.9747 6.68693 20.9747ZM3.60171 9.91375C4.88924 11.2299 5.90955 13.5079 6.68693 15.6594C7.65864 13.4573 9.04333 11.4577 10.4766 10.2428C9.01904 8.82538 7.58576 6.57269 6.58975 4.72499C5.93384 6.49676 4.96211 8.54696 3.60171 9.91375Z' fill='#FFDF57' />
          </svg>
          <svg className='position-absolute noice-7' width='27' height='40' viewBox='0 0 27 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14.0595 40C13.5205 40 13.0519 39.5955 12.9582 39.0482C12.9348 38.8816 10.0293 22.3443 1.5942 21.7257C1.40675 21.7257 1.19587 21.7257 1.00843 21.7019C0.422652 21.6305 -0.0225471 21.107 0.000883833 20.5121C0.0243148 19.8935 0.516376 19.4176 1.12558 19.4176C1.2896 19.4176 1.45361 19.4176 1.61763 19.4414C2.53143 19.3938 3.63269 18.8465 4.7808 17.8709C9.13896 14.1828 13.1222 5.66426 13.4971 1.04809C13.544 0.477013 13.9892 0.0487099 14.5515 0.00112052C15.0904 -0.0226742 15.6059 0.334243 15.723 0.905316C16.7774 5.75943 20.6436 18.442 25.9624 18.8703C26.4544 18.9179 26.8527 19.2748 26.9699 19.7507C27.0871 20.2266 26.8528 20.7501 26.431 20.988C26.3373 21.0594 16.2385 27.1509 15.1842 38.953C15.1373 39.5241 14.6686 39.9762 14.1063 40C14.0829 40 14.0829 40 14.0595 40ZM5.20254 20.4408C9.8653 22.844 12.5599 29.1496 13.9423 33.6706C16.0511 26.8653 20.667 22.4633 23.3381 20.3932C19.0268 18.0375 16.1917 10.7801 14.6921 5.9022C13.0753 10.8039 9.81845 16.6098 6.21009 19.6555C5.88205 19.9411 5.53058 20.2028 5.20254 20.4408Z' fill='#FFDF57' />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default ReadyTo ;
