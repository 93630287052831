import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import { Provider} from 'react-redux';
import store from './store';
import { checkAuthorization, getActiveSubscriptionInfo } from './features/user/userSlice';
import { fetchMoodsData, fetchTypesData, fetchCategoriesData} from './features/browse/browseSlice';
import {fetchPlansData} from "./features/plans/plansSlice";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

store.dispatch(checkAuthorization);
store.dispatch(getActiveSubscriptionInfo);
store.dispatch(fetchMoodsData);
store.dispatch(fetchTypesData);
store.dispatch(fetchCategoriesData);
store.dispatch(fetchPlansData);

ReactDOM.hydrate(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
