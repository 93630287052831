import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CloseElement } from "./Icons";
import { setCookie, getCookie } from '../lib/cookies';

import './NotificationCookies.css';

function NotificationCookies ({
        mainText,
        linkText,
    }) {
    const [visibility, setVisibility] = useState(null);

    useEffect(() => {
        setVisibility(getCookie('NotificationCookiesVisibility') !== null ? getCookie('NotificationCookiesVisibility') : 'show');
    }, [visibility]);

    const setHideCookie = () => {
        setCookie('NotificationCookiesVisibility', 'hide', 365, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
        setVisibility(getCookie('NotificationCookiesVisibility'));
    };

    return (
          <>
          {(visibility !== null && visibility !== 'hide') && <div className='NotificationCookies'>
              <div className='NotificationCookies__close' onClick={() => setHideCookie()}><CloseElement /></div>
              <p className='NotificationCookies__mainText'>{mainText}&nbsp;</p>
              <Link className='NotificationCookies__linkText' to={'/cookies-policy'}>{linkText}</Link>
          </div>}
          </>
    );
}

NotificationCookies.propTypes = {
    mainText: PropTypes.string,
    linkText: PropTypes.string,
};

NotificationCookies.defaultProps = {
    mainText: 'We use cookies.',
    linkText: 'Learn more',
};

export default NotificationCookies;
