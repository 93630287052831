import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import scrollToTop from "../lib/scrollToTop";
import { StorytaleLogo } from "./Icons";
import "./Footer.css";
import BeforeFooter from "./BeforeFooter";

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP;

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: "",
    };
  }

  componentDidMount() {
    axios
      .get("/api/illustration_log/getCreatedCount")
      .then((response) => {
        response.data.success === true &&
          this.setState({
            count: response.data.result.count,
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { bordered } = this.props;
    const date = new Date();

    return (
      <footer
        className={`site-footer ${bordered === true ? `bordered` : null}`}
      >
        <div className="footer-container">
          <div className="row">
            <div className="col">
              <div className="section_before_footer">
                <BeforeFooter />
              </div>
              <div className="wrapper">
                <div className="first">
                  <a href={storytaleUrl} className="link footer_logo">
                    <StorytaleLogo />
                  </a>
                  <p className="footer-text">
                     High-quality assets to bring life into you projects
                  </p>
                </div>
                <div className="second">
                  <p className="header">Explore</p>
                  <ul className="list">
                    <li>
                      <a className="link" href="/browse" onClick={scrollToTop}>
                        Assets
                      </a>
                    </li>
                    <li>
                      <a
                        className="link"
                        href="/browse/?packs=true"
                        onClick={scrollToTop}
                      >
                        Packs
                      </a>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/plans`}
                        onClick={scrollToTop}
                      >
                        Pricing
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="third">
                  <p className="header">Legals</p>
                  <ul className="list">
                    <li>
                      <Link
                        className="link"
                        to={`/privacy-policy`}
                        onClick={scrollToTop}
                      >
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/license-agreement`}
                        onClick={scrollToTop}
                      >
                        Licenses
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/terms-of-use`}
                        onClick={scrollToTop}
                      >
                        Terms
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/refund-policy`}
                        onClick={scrollToTop}
                      >
                        Refunds
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/cookies-policy`}
                        onClick={scrollToTop}
                      >
                        Cookies
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="fourth">
                  <p className="header">Company</p>
                  <ul className="list">
                    <li>
                      <Link
                        className="link"
                        to={`/order-custom`}
                        onClick={scrollToTop}
                      >
                        Custom work
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/about-us`}
                        onClick={scrollToTop}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        to={`/contact-us`}
                        onClick={scrollToTop}
                      >
                        Contacts
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={`/help`} onClick={scrollToTop}>
                        Help
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="fifth">
                  <div className="left">
                    <p className="bottom-text">
                      Created on sunshine coast by{" "}
                      <a
                        href="https://craftwork.design/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Craftwork ©
                      </a>{" "}
                      2020 - {date.getFullYear()}
                    </p>
                  </div>
                  <div className="right">
                    <ul className="socials">
                      <li>
                        <a
                          href="https://dribbble.com/storytale"
                          target="_blank"
                          rel="noreferrer"
                          className="bottom-text bottom-link"
                        >
                          Dribbble
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/storytale.io"
                          target="_blank"
                          rel="noreferrer"
                          className="bottom-text bottom-link"
                        >
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
