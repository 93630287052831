import axios from "axios";
import getCustomerActivities from "./getCustomerActivities";
import addPictureLikeCounts from "./addPictureLikeCounts";
const qs = require('qs');

const loadMoreIllustrationsFrom = async (props, illustrationArray = 'illustrationsFromKeywords', paramsList = 'illustrationsFromKeywordsParams') => {

  props.setState (
    prevState => {
      let params = Object.assign({}, prevState[paramsList]);
      params.page = params.page += 1;

      return {
        [paramsList] : params,
      }
    },
    () => {
      axios
        .get(`/api/illustration`, {
          params: {...props.state[paramsList]},
          paramsSerializer: function(params) {
            return qs.stringify(params, {arrayFormat: 'brackets', encode: false})
          },
        })
        .then(response => {
          const {success} = response.data;
          const {illustrationData} = response.data.result;

          try {
            if (success && illustrationData !== null) {
              const illustrationsClone = [...props.state[illustrationArray]];
              const result = illustrationsClone.concat(illustrationData);
              const updatedPicturesLikesCounts = addPictureLikeCounts(props.state.picturesLikesCounts, illustrationData);

              props.setState(
                prevState => {
                  return {
                    [illustrationArray]: result,
                    picturesLikesCounts: updatedPicturesLikesCounts,
                  };
                },
                () => {
                  getCustomerActivities(props, illustrationData);
                }
              );
            }
          } catch (err) {
            console.log(err);
          }
        }).catch(function (error) {
        console.log(error);
      });
    }
  );
};

export default loadMoreIllustrationsFrom ;
