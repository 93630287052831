import './InfoFieldWithLink.scss';

import PropTypes from 'prop-types';

const InfoFieldWithLink = ({ label, link_name, link_url, target }) => ( 
  <div className='InfoFieldWithLink'>
    <p className='InfoFieldWithLink__label'>{ label } </p>
    <a className='InfoFieldWithLink__link' href={ link_url } target={target}>{ link_name }</a>
  </div>
)

InfoFieldWithLink.propTypes = {
  text:      PropTypes.string,
  link_name: PropTypes.string,
  link_url:  PropTypes.string.isRequired,
  target:    PropTypes.string, 
}

InfoFieldWithLink.defaultProps = {
  text:      'InfoField',
  link_name: 'link',
  target:    '_blank',
}

export default InfoFieldWithLink;
