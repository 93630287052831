import React, { useState } from 'react';
import './AccountForm.scss';

import AccountDelimiter from './AccountDelimiter';
import AccountSignLink from './AccountSignLink';
import SignBlock from './SignBlock';
import ErrorField from './ErrorField';

import { useDispatch } from 'react-redux';
import { checkAuthorization, getActiveSubscriptionInfo } from '../features/user/userSlice'

// Common js
import { setCookie, eraseCookie } from '../lib/cookies';

//Icons
import {UsernameFromIcon, PasswordFormIcon} from './Icons';

import { Form, Input, Button, Checkbox } from 'antd';
import axios from 'axios';

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const goAccount = (url) => {
  window.location.href = `${process.env.REACT_APP_ACCOUNT_APP}/${url}`;
};

export default function AccountForm (props) {
  const dispatch = useDispatch();
  const [backError, setBackError] = useState({
    message: 'Unknown Error',
    show: false,
  });

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('userName', values.username);
    formData.append('password', values.password);
    formData.append('rememberMe', values.remember);

    axios.post(`/api/user/login`, formData)
      .then(response => {
        if (response.data.success === true) {
          dispatch(checkAuthorization);
          dispatch(getActiveSubscriptionInfo);
          setCookie('dropUser', 'false', 100, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
          eraseCookie('dropUser');
          props.onSubmit();
        } else {
          setBackError({
            message: response.data.message,
            show: true,
          });
        }
      })
      .catch(function (error) {
        setBackError({
          message: error.message,
          show: true,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='LoginForm'
      initialValues={{
        remember: true,
        username: localStorage.userName,
        password: localStorage.password,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className='AccountForm'
    >
      <SignBlock/>

      <AccountDelimiter text='or' />

      <Form.Item
        name='username'
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input placeholder='Email or Username' prefix={<UsernameFromIcon />} />
      </Form.Item>

      <Form.Item
        // label={<span className='AccountForm__label_withRightLink'>Password <Link to='/restore-password'>Forgot password</Link></span>}
        name='password'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password placeholder='Password' prefix={<PasswordFormIcon />} />
      </Form.Item>

      { backError.show === true &&
        <ErrorField error_message={backError.message} show={backError.show}/>
      }

      <div className='AccountForm__label_withRightLink'>
        <Form.Item className='checkbox' name='remember' valuePropName='checked'>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <div onClick={() => goAccount('restore-password')}>Forgot password</div>
      </div>

      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          Log in
        </Button>
      </Form.Item>

      <AccountSignLink text='Don’t have an account?' linktext='Register' linkroute='/register' onClick={props.onClick}/>
    </Form>
  );
};


