import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

import {Kebab} from "./components/Icons";
import {Helmet} from "react-helmet";

function NotFound ({ staticContext = {} }) {

  staticContext.status = 404;

  return (
      <section className="error-404">
        <Helmet>
          <title>Not Found</title>
          <meta name="description" content="Not found page" data-react-helmet="true"/>
        </Helmet>

        <Header />
        <section className="content">
          <div className="container-custom">
            <div className="row content-row">
              <div className="col-12 mx-auto d-flex flex-column align-items-center">
                <img src="https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/01-custom@2x.png" width="365" height="290" alt="page not found"/>
                <div className="text-center text-content">
                  Oh, no! This page does not exist.
                </div>
                <Link className="button-icon large black" to="/browse" >
                    <span>
                      <Kebab fill="#ffffff"/>
                    </span>
                  <span>Explore catalog</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer bordered={true}/>
      </section>
  );
};

export default NotFound

