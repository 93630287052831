const changeIllustrationActivities = (props, id, type='isLiked', result = null ) => {
  const customerActivities = props.state.customerActivities;

  if(type === 'isLiked') {
    customerActivities[id] = {
      isLiked: result === 'like' ? true : false,
      isDownloaded: customerActivities[id] ? customerActivities[id].isDownloaded : false,
    };
  }

  if(type === 'isDownloaded') {
    customerActivities[id] = {
      isLiked: customerActivities[id] ? customerActivities[id].isLiked : false,
      isDownloaded: result ? true : false,
    };
  }

  props.setState(prevState => {
    return {
      customerActivities: customerActivities,
    };
  });

  // try {
  //   let {likeCount} = props.state.currentIllustrationInfo;
  //
  //   if(type === 'isLiked' && result === 'unlike' && likeCount !== 0) {
  //     likeCount -= 1;
  //   } else if (type === 'isLiked' && result === 'like')  {
  //     likeCount += 1;
  //   }
  //
  //   props.setState(prevState => {
  //     let currentIllustrationInfo = Object.assign({}, prevState.currentIllustrationInfo);
  //     currentIllustrationInfo.likeCount = likeCount;
  //
  //     return {
  //       currentIllustrationInfo,
  //     };
  //   });
  // } catch (error) {
  //
  // }
};

export default changeIllustrationActivities;
