import React from "react";

//styles
import "./WidgetPack.css"

import { Link } from "react-router-dom";

function WidgetPack(props) {
  const {type = "normal", id, cover, name, quantity, description, onClick = () => {}} = props;

  return (
    <div
      className={`widget-pack ${type}`}
      onClick={() => onClick(id)}
    >
      <div
        className={`poster ${type === `small` && `small`} ${type === `full` && `small`}`}
      >
         <Link className="image-link" to={`/pack/${id}`}>
           <img src={cover} alt={name} />
         </Link>
      </div>

      <Link className="text-link" to={`/pack/${id}`}>
        {type === `full` ?
          <h1 className="title">
            {name}
          </h1>
         :
          <h5 className="title">
            {name}
          </h5>
        }
      </Link>

      {(type === "full" && description) &&
        <p className="descr">
          {description === 'undefined' ? '' : description}
        </p>
      }

      <p className={`descr ${type === 'full' && 'text-dark'}`} >
        {quantity} assets
      </p>
    </div>
  );
}

export default WidgetPack;
