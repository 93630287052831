import React from "react";
import Modal from "react-bootstrap/Modal";

import '../common_styles/buttons.scss'
import "./Modal.scss"
import {CloseElement, StorytaleLogoSlim} from "./Icons";
import RegisterFrom from "./RegisterForm";

import {useSelector, useDispatch} from 'react-redux'

const image = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/authRegisterTinified.png";

const ModalAuthRegister = () => {

  const dispatch = useDispatch();

  const modalRegisterActive = useSelector((state) => state.modals.modalRegisterActive);

  const handleClose = () => dispatch({ type: 'modals/modalRegisterActive', payload: false });
  const handleCloseCustom = () => {
    dispatch({ type: 'modals/modalRegisterActive', payload: false });
    dispatch({ type: 'modals/modalLoginActive', payload: true });
  };

  return (
    <Modal
      animation={true}
      show={modalRegisterActive}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-auth modal-dialog-auth-register modal-dialog-centered "
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
        <span className="close-button" onClick={handleClose}>
          <CloseElement/>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="wrapper">
          <div className="left">
            <div className="custom-title">Sign up to continue</div>
            <RegisterFrom onSubmit={handleClose} onClick={handleCloseCustom}/>
          </div>
          <div className="right">
            <div className="logo">
              <StorytaleLogoSlim fill="#000000"/>
            </div>
            <img src={image} srcSet={image} alt="register"/>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};
export default ModalAuthRegister;
