import React from 'react';
import PropTypes from 'prop-types';

import Picture from './Picture';

import './AboutUsProsCard.css';

const AboutUsProsCard = ({
  title,
  text,
  alt,
  img_normal,
  img_big
}) => {
  return (
    <div className='AboutUsProsCard'>
      <div className='AboutUsProsCard__img'>
        <Picture 
          standart={img_normal} 
          big={img_big} 
          alt={alt}/>
      </div>
      <div className='AboutUsProsCard__description'>
        <p className='AboutUsProsCard__title'>{title}</p>
        <p className='AboutUsProsCard__text'dangerouslySetInnerHTML={{ __html: text }}/>
      </div>
    </div>
  );
};

AboutUsProsCard.propTypes = {
  title:      PropTypes.string,
  text:       PropTypes.string,
  alt:        PropTypes.string,
  img_big:    PropTypes.string.isRequired,
  img_normal: PropTypes.string.isRequired
};

AboutUsProsCard.defaultProps = {
  title:    'Title',
  text:     'Some text',
  alt:      'picture'  
}

export default AboutUsProsCard;
