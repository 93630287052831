import React  from "react";

import "./TextAvatar.css"

import PropTypes from "prop-types";

const TextAvatar = ({ userNameFirstLetter }) => (
  <div className={`TextAvatar_${(userNameFirstLetter.charCodeAt() - 97)%8}`}>
    <span className='TextAvatar__letter'>{userNameFirstLetter}</span>
  </div>
)

TextAvatar.propTypes = {
  userNameFirstLetter: PropTypes.string.isRequired
}

export default TextAvatar;
