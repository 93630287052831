import React from "react";
import "./BeforeFooter.css";
import CustomIllustrationsCard from "./CustomIllustrationsCard";
import NewsLetterCard from "./NewsLetterCard";

function BeforeFooter(props) {
  return (
    <section className='before-footer'>
      <div className="container-section-before">
        <CustomIllustrationsCard />
        <NewsLetterCard />
      </div>
    </section>
  );
}

export default BeforeFooter;
