import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import InfoField from './components/InfoField';
import InfoFieldWithLink from './components/InfoFieldWithLink';
import EmptyPageFiller from './components/EmptyPageFiller';
import Header from './components/Header';
import Footer from './components/Footer';

import moment from "moment";

import './ThankYou.scss';
import React from "react";

//Images
const StandartThankYou = 'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/ThankYou.png';
const BigThankYou = 'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/ThankYou@2x.png';

const ThankYou = (props) => {
  let thankYouTitle = 'Thank You';
  let checkoutDetails = {};
  let orderDetails = {};
  let comleteData = null;

  if (props.location.state) {
    thankYouTitle = props.location.state.thankYouTitle;
    checkoutDetails = props.location.state.checkout;
    orderDetails = props.location.state.order;
    comleteData = moment(props.location.state.order.completed.date);
  return (
    <div className='ThankYou'>
      <Header/>
      <Helmet>
        <title>Storytale Thank you</title>
      </Helmet>
      <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-6 ThankYou__firstcol'>
          <h1>{thankYouTitle} </h1>
          <InfoField label='Checkout Id' value={checkoutDetails.checkout_id} />
          <InfoField label='Checkout Title' value={checkoutDetails.title} />
          <InfoField label='Date' value={comleteData.isValid() ? comleteData.format("MMMM D, YYYY") : null} />
          <InfoField label='Email' value={orderDetails.customer.email} />
          <InfoField label='Total' value={`$${orderDetails.total}`} />
          <InfoField label='Tax' value={`$${orderDetails.total_tax}`} />
          <InfoFieldWithLink label='Receipt Url' link_url={orderDetails.receipt_url} />
        </div>
        <div className='col-12 col-md-6 ThankYou__secondcol'>
          <EmptyPageFiller
            StandartImage={StandartThankYou}
            BigImage={BigThankYou}
            linkPath='browse'
          />
        </div>
      </div>
      </div>
      <Footer bordered={true}/>
    </div>
  ) } else {
    return <Redirect to="/" />
  }
};

export default ThankYou;
