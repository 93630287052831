const scrollToTopSmooth = () => {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

  if(winScroll > 0) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export default scrollToTopSmooth;
