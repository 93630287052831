import React from 'react';
import './ScrollUp.scss';
import { ArrowUp } from './Icons';

function ScrollUp({reveal, scrollAction}) {
  return (
    <button
      type='button'
      className={`scroll-up ${reveal? 'scroll-up_show': 'scroll-up_hide'}`}
      onClick={scrollAction}
    >
      <ArrowUp />
    </button>
  )
}

export default ScrollUp;
