
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select} from 'antd';
import {ArrowDownBig} from './Icons';
import './FilterSelect.scss';

function FilterSelect(props) {
  const {options, value, onChange = () => {}} = props;
  return (
    <div
      className='filter-select'
    >
      <Select
        key={uuidv4()}
        value={value}
        style={{ width: 150 }}
        onChange={onChange}
        suffixIcon={<ArrowDownBig/>}
        options={options}
      />
    </div>
  );
}

export default FilterSelect;
