import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";

import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import Header from './components/Header';
import {Helmet} from "react-helmet";

import envs from './lib/envs';

import './textpage.css';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class RefundPolicy extends Component {
  render() {
    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Refund Policy</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container textpage'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='textpage__title'>Refund Policy</h1>
                <h2 className='textpage__title_secondary'>Subscription Refund Policy</h2>
                <p>Unfortunately, we do not refund subscription costs. According to our <Link to='/terms-of-use'>Terms of Use</Link>, you must be responsible for your purchases and make timely use of them. If you do not anything during the month of subscription, you will not get any compensation.</p>
                <p>We apologize for any inconvenience you may have while using our service. If you have any questions, feel free to <Link to='/contact-us'>contact us</Link>.</p>
              </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer/>
      </>
    )
    }
}

export default withRouter(RefundPolicy);
