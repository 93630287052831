import React, { Component } from 'react';

import Footer from './components/Footer';
import Header from './components/Header';
import FaqSection from './components/FaqSection';
import Picture from './components/Picture';
import CompleteBriefForm from './components/CompleteBriefForm';

import {
  CompleteBriefFormLeftStar,
  CompleteBriefFormRightStar,
  HandOk,
  EllipseSmall,
  HandSnapWithSound,
  VectorStraight,
  VectorStraightShort,
  CompleteBriefFormVectorLeft,
} from './components/Icons';

import './OrderCustom.css';
import ButtonBase from "./components/ButtonBase";
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";

import envs from './lib/envs';
import CompaniesLogos from "./components/CompaniesLogos";

const LeftArrow = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/LeftArrow.png';
const LeftArrowBig = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/LeftArrow@2x.png';
const RightArrow = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/RightArrow.png';
const RightArrowBig = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/RightArrow@2x.png';

const OrderCustomTypeImage01Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/typeimages/Gamer.png';
const OrderCustomTypeImage01Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/typeimages/Gamer@2x.png';
const OrderCustomTypeImage02Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/typeimages/Skater.png';
const OrderCustomTypeImage02Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/typeimages/Skater@2x.png';

const OrderCustomWorkProcessImage01Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image1.png';
const OrderCustomWorkProcessImage01Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image1@2x.png';
const OrderCustomWorkProcessImage02Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image2.png';
const OrderCustomWorkProcessImage02Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image2@2x.png';
const OrderCustomWorkProcessImage03Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image3.png';
const OrderCustomWorkProcessImage03Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image3@2x.png';
const OrderCustomWorkProcessImage04Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image4.png';
const OrderCustomWorkProcessImage04Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ordercustom/workprocess/items/Image4@2x.png';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class OrderCustom extends Component {
  render() {

    const {history} = this.props;


    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Order Custom</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='OrderCustom'>
          <div className='container-fluid OrderCustom__head-container'>
            <div className='row'>
              <div className='col-12 col-lg-6 offset-lg-3'>
                <h1 className='text-center OrderCustom__title'>
                  <span className='OrderCustom__title-svg_left'><HandSnapWithSound /></span>
                  Order custom design <br/> by Storytale Team
                  <span className='OrderCustom__title-svg_right'><HandOk /></span>
                  <span className='OrderCustom__title-svg_top_left'><EllipseSmall /></span>
                  <span className='OrderCustom__title-svg_top_right'><VectorStraightShort /></span>
                </h1>
                <p className='text-center OrderCustom__main-description'>
                  <span><VectorStraight /></span>
                  Get unique illustrations, landing pages, or&nbsp;shots and&nbsp;make your&nbsp;project outstanding among others. Order exclusive design that&nbsp;is&nbsp;made to&nbsp;satisfy your&nbsp;wishes fully.
                  <span><CompleteBriefFormVectorLeft stroke='#FFC121' /></span>
                </p>
                  <ButtonBase
                    content="Complete a brief"
                    classes="large blue  OrderCustom__formlink"
                    callback={() => {
                      const briefElement = document.getElementById('complete-a-brief');
                      briefElement && briefElement.scrollIntoView({ behavior: "smooth", block: "start"});
                    }}
                  />
                <p className='text-center OrderCustom__headind-text_small_colored '>It takes less than 2 minutes</p>
              </div>
            </div>
          </div>

          <div className='container-fluid OrderCustom__types-section'>
            <div className='row OrderCustom__types-section-row'>
              <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                <h2 className='text-center OrderCustom__title_secondary title_medium'>Illustration types</h2>
                <p className='text-center OrderCustom__types-section-subtitle'>Create a personal account and get hundreds of stylish illustrations for your web projects</p>
              </div>
              <div className='col-12 col-md-5 offset-md-1 order-1 order-md-1'>
                <div className='OrderCustom__types-section-item_pink'>
                  <Picture
                    standart={OrderCustomTypeImage02Normal}
                    big={OrderCustomTypeImage02Big}
                    alt={'skater'}
                  />
                </div>
              </div>
              <div className='col-12 col-md-5 order-3 order-md-2'>
                <div className='OrderCustom__types-section-item_gray OrderCustom__types-section-item_small-image'>
                  <Picture
                    standart={OrderCustomTypeImage01Normal}
                    big={OrderCustomTypeImage01Big}
                    alt={'skater'}
                  />
                </div>
              </div>
              <div className='col-12 col-md-4 offset-md-1 OrderCustom__types-section-text order-2 order-md-3'>
                <div className='row'>
                  <div className='col-2 col-md-3'>
                    <p className='text-right'>2D</p>
                  </div>
                  <div className='col-10 col-md-9'>
                    <p>Vector illustrations look perfectly in any type project. They can be drawn in a&nbsp;huge variety of styles. Choose what you like most.</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 offset-md-1 OrderCustom__types-section-text order-4 order-md-4'>
                <div className='row'>
                  <div className='col-2 col-md-3'>
                    <p className='text-right'>3D</p>
                  </div>
                  <div className='col-10 col-md-9'>
                    <p>Trendy and complex illustrations obviously look attractive. Everyone loves 3D graphics. But only a&nbsp;few have them. Right option if you want to&nbsp;differ.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid OrderCustom__work-process-section'>
            <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
              <h2 className='text-center OrderCustom__title_secondary title_medium'>Work process</h2>
              <p className='text-center OrderCustom__types-section-subtitle'>Successful project always has a&nbsp;consistent plan to&nbsp;be finished step by step and provide a&nbsp;perfect result in the&nbsp;end</p>
            </div>
            <div className='OrderCustom__work-process-section-inner'>
              <div className='OrderCustom__work-process-section-inner-row row'>
                <Picture
                  standart={LeftArrow}
                  big={LeftArrowBig}
                  alt='left arrow'
                  img_class='OrderCustom__work-process-section-img_left'
                />
                <div className='OrderCustom__work-process-section-inner-item_yellow col-12 col-sm-6 col-lg-4 offset-0 offset-lg-2'>
                  <h3>Brief</h3>
                  <p>Everything starts with an&nbsp;idea. We choose the style, message, and composition to collect necessary details and set up the task for the&nbsp;designer.</p>
                </div>
                <Picture
                  standart={OrderCustomWorkProcessImage01Normal}
                  big={OrderCustomWorkProcessImage01Big}
                  alt='image 1'
                  img_class='OrderCustom__work-process-section-item-img_right'
                />
              </div>
              <div className='OrderCustom__work-process-section-inner-row row'>
                <Picture
                  standart={RightArrow}
                  big={RightArrowBig}
                  alt='left arrow'
                  img_class='OrderCustom__work-process-section-img_right'
                />
                <div className='OrderCustom__work-process-section-inner-item_green col-12 col-sm-6 col-lg-4 offset-0 offset-sm-6 offset-lg-6'>
                  <h3>Sketch</h3>
                  <p>Based on the&nbsp;brief we create a&nbsp;sketch or a&nbsp;wireframe to approve that we’re in the&nbsp;right direction. This step is important to&nbsp;be sure of&nbsp;composition, design structure and&nbsp;shapes.</p>
                </div>
                <Picture
                  standart={OrderCustomWorkProcessImage02Normal}
                  big={OrderCustomWorkProcessImage02Big}
                  alt='image 2'
                  img_class='OrderCustom__work-process-section-item-img_left'
                />
              </div>
              <div className='OrderCustom__work-process-section-inner-row row'>
                <div className='OrderCustom__work-process-section-inner-item_blue col-12 col-sm-6 col-lg-4 offset-0 offset-lg-2'>
                  <h3>Design</h3>
                  <p>Approved sketch becomes alive. Now it represents the&nbsp;style you chose and spiced with all those tasty details to look perfect.</p>
                </div>
                <Picture
                  standart={OrderCustomWorkProcessImage03Normal}
                  big={OrderCustomWorkProcessImage03Big}
                  alt='image 3'
                  img_class='OrderCustom__work-process-section-item-img_right'
                />
              </div>
              <div className='OrderCustom__work-process-section-inner-row row'>
                <div className='OrderCustom__work-process-section-inner-item_magnolia col-12 col-sm-6 col-lg-4 offset-0 offset-sm-6 offset-lg-6'>
                  <h3>Delivery</h3>
                  <p>If you’re fully satisfied with the&nbsp;result we’re happy to provide sources. Getting source you become an&nbsp;exclusive owner of created designs. Feel free to do anything with it!</p>
                </div>
                <Picture
                  standart={OrderCustomWorkProcessImage04Normal}
                  big={OrderCustomWorkProcessImage04Big}
                  alt='image 3'
                  img_class='OrderCustom__work-process-section-item-img_left'
                />
              </div>
            </div>
          </div>

          <div className='container-fluid OrderCustom__complete-a-brief-section' id='complete-a-brief'>
            <div className='row'>
              <div className='col-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3'>
              <h2 className='text-center OrderCustom__title_secondary title_medium'><span><CompleteBriefFormLeftStar /></span>Complete a&nbsp;brief<span><CompleteBriefFormRightStar /></span></h2>
                <CompleteBriefForm />
              </div>
            </div>
          </div>
          <CompaniesLogos title="Trusted by great companies"/>
          <FaqSection
            LeftCollumnTitles={['What information do we need to start?', 'How fast do we work?']}
            LeftCollumnText={[
              'To start drawing we need a small explanation of your project. What should be shown? How it will be used? Also maybe do you have wishes for style, references, colors, etc.',
              'The speed of our work is directly connected with the speed of our clients. We have several designers and illustrators with different styles. All of them work really fast.'
            ]}
            RightCollumnTitles={['Do you have control over the process?', 'How do we accept payment?']}
            RightCollumnText={[
              'Yes. We will be in touch with you throughout the entire process of creating. We will show and discuss every completed step with you.',
              'We work on a half-prepaid basis. To start the work, you need to deposit 50% of the price we will calculate for you. Payment can be done via PayPal, Payoneer or Swift.'
            ]}
          />
          <Footer bordered={true}/>
        </div>
      </>
    )
  }
}

export default withRouter(OrderCustom);

