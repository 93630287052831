import "./InfoField.scss";

import PropTypes from "prop-types";

const InfoField = ({label, value}) => ( 
  <div className="InfoField">
    <p className="InfoField__label">{label} </p>
    <p className="InfoField__value">{ typeof value === 'object' ? JSON.stringify(value) : value}</p>
  </div>
)

InfoField.propTypes = {
  text: PropTypes.string
}

InfoField.defaultProps = {
  text: "InfoField"
}

export default InfoField;
