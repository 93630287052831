import React from 'react';
import PropTypes from 'prop-types';

import './ErrorField.css';

function ErrorField({ 
  error_message,
  show 
}) {
  if (!show) return null;
  return (<div className="ErrorField">{`ERROR: ${error_message}`}</div>);
}
ErrorField.propTypes = {
  error_message: PropTypes.string,
  show: PropTypes.bool,
};

ErrorField.defaultProps = {
  error_message: 'Unknown Error',
  show: false,
};


export default ErrorField;
