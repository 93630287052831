import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";

import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import Header from './components/Header';
import {Helmet} from "react-helmet";

import envs from './lib/envs';

import './textpage.css';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class TermsOfUse extends Component {
  render() {
    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Terms of Use</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container textpage'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='textpage__title'>Terms of Use</h1>
                <p>Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the http://storytale.io website operated by Storytale (“us”, “we”, or “our”).</p>
                <p>Your access to and use of the Website is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Website.</p>
                <p>By accessing or using the Website you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Website.</p>
                <h2 className='textpage__title_secondary'>Purchasing</h2>
                <p>If you wish to purchase the subscription on the Website, you may be asked to supply certain information relevant to your Purchase including, without limitation, your payment information (card or account details) and personal data. By proceeding with the subscription purchase you agree to the processing of your personal data by our service.</p>
                <h2 className='textpage__title_secondary'>Age</h2>
                <p>By using our website and purchasing a subscription you confirm that you are 18 years or over. You are responsible for all your actions that you perform on our website and will be legally responsible for your violations.</p>
                <h2 className='textpage__title_secondary'>License</h2>
                <p>Downloading an item, you get source files in various formats and a copy of the license terms. Downloading an item you are not acquiring ownership. All items are obligated to use only in specific terms that are described in <Link to='/license-agreement'>Storytale License</Link>.</p>
                <h2 className='textpage__title_secondary'>Downloads per subscription</h2>
                <p>You have access to all illustrations on the website during one subscription period. Even if there are some downloads left they reset at the start of the next subscription period. If your subscription doesn’t renew, the amount of downloads resets when subscription expires. You are allowed to use illustrations you downloaded forever.</p>
                <h2 className='textpage__title_secondary'>Services</h2>
                <p>As the subscriber of our service, you are granted to get support on your issues, complain about the content quality, make your suggestions for service work and etc. For our part, we guarantee that all your questions will be resolved honestly and in the shortest possible time.</p>
                <h2 className='textpage__title_secondary'>Account</h2>
                <p>Personal account may be used only by one person. If we notice any suspicious actions, we reserve the right to terminate your account until the circumstances are clarified.</p>
                <h2 className='textpage__title_secondary'>Payment</h2>
                <p>You can make payment for the subscription using PayPal or your credit card. By proceeding with the payment you agree to the subscription terms: the subscription will be automatically renewed every month on the day you purchased the subscription. Canceling the subscription will cause your account suspending after 30 days of your last payment. If we can’t debit your account to renew your subscription due to your credit card or PayPal blocking or lack of funds, your account will be suspended.</p>
                <h2 className='textpage__title_secondary'>Currency conversation</h2>
                <p>We are not responsible for any currency conversion operations that occurred during your payment process that may cause any difference between the amount deducted from your account and the price on our website. We have no control over the process and cannot change the default currency.</p>
                <h2 className='textpage__title_secondary'>Taxes</h2>
                <p>The price of the subscription includes taxes in its cost where it is possible to apply from our side (e.g. VAT). You are responsible for any other fees and taxes related to your activities regardless of your location (e.g. withholding tax).</p>
                <h2 className='textpage__title_secondary'>Refunds</h2>
                <p>We described all the conditions and rules for the refund and your service resources in our <Link to='refund-policy'>Refund Policy</Link>.</p>
                <h2 className='textpage__title_secondary'>Fraud suspected</h2>
                <p>If we notice that you excessively use full access and download the maximum number of illustrations per day regularly, your account will be terminated without warning and clarifying the circumstances. This will free us from piracy activity and completely unloading of our illustrations from the website. Please don’t try to download all the illustrations at once, download the scenes that you need right now.</p>
                <h2 className='textpage__title_secondary'>Termination</h2>
                <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                <h2 className='textpage__title_secondary'>Links To Other Web Sites</h2>
                <p>Our Website may contain links to third-party web sites or services that are not owned or controlled by Storytale. Storytale has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Storytale shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                <h2 className='textpage__title_secondary'>Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is a material we will try to provide at least 7 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                <h2 className='textpage__title_secondary'>Contact Us</h2>
                <p>If you have any questions about these Terms, please <Link to='contact-us'>contact us</Link>.</p>
              </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer/>
      </>
    )
    }
}

export default withRouter(TermsOfUse);

