import React, {Fragment, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {CheckMark, SmallDownIcon} from "./Icons";

import PropTypes from "prop-types";

import "./SubscriptionPlanCard.scss";

const SubscriptionPlanCard = ({
  id,
  name,
  price,
  oldprice,
  duration,
  downloadsPM,
  buttonCaption,
  buttonType,
  isActivePlan,
  handleClick,
  cardClass,
  description,
  buttonDisabled,
  durationCount,
  chargePeriodCount,
  chargePeriodLabel,
  simple = false
}) => {
  const [pricePerDownload, setPricePerDownload] = useState(0);
  const [durationLabel, setDurationLabel] = useState('');
  const [chargeLabel, setChargeLabel] = useState('');

  const generateCaption = (count, timeUnit) => {
    let caption = '';
    if (timeUnit === 'day') {
      if (count >= 30) {
        caption = 'month';
        return caption;
      }
      if ((count % 7 === 0) && (count > 7)) {
        caption = `${count / 7} weeks`;
        return caption;
      }
      switch (count) {
        case 1:
          caption = 'day';
          break;
        case 7:
          caption = 'week';
          break;
        default:
          caption = `${count} days`;
          break;
      }
    }
    if (timeUnit === 'month') {
      if ((count % 12 === 0) && (count > 12)) {
        caption = `${count / 12} years`;
        return caption;
      }
      switch (count) {
        case 1:
          caption = 'month';
          break;
        case 3:
          caption = 'quarter';
          break;
        case 6:
          caption = 'half-year';
          break;
        case 12:
          caption = 'year';
          break;
        default:
          caption = `${count} months`;
          break;
      }
    }
    return caption;
  };
  const generateLimitDurationLabel = (durationCount, duration) => {
    const article = (durationCount === 1 ? 'a ' : 'per ');
    const caption = generateCaption(durationCount, duration);
    return article + caption;
  };

  useEffect(() => {
    if ((price > 0) && (downloadsPM > 0) && (chargePeriodCount > 0) && (durationCount > 0)) {
      setPricePerDownload(price / ( downloadsPM * ( chargePeriodCount / durationCount )));
    }
  }, [price, downloadsPM, chargePeriodCount, durationCount]);

  useEffect(() => {
    if ((durationCount > 0) && (duration.length > 0)) {
      setDurationLabel(generateLimitDurationLabel(durationCount, duration));
    }
  }, [durationCount, duration]);

  useEffect(() => {
    if ((chargePeriodCount > 0) && (chargePeriodLabel.length > 0)) {
      setChargeLabel(generateCaption(chargePeriodCount, chargePeriodLabel));
    }
  }, [chargePeriodCount, chargePeriodLabel]);

  return (
    <div className={cardClass}>
      <div className="SubscriptionPlanCard__header">
        <p className="SubscriptionPlanCard__name">
          {name}
        </p>
        <div className="SubscriptionPlanCard__downloads">
          {
            simple ?
            `${downloadsPM} downloads/${chargePeriodLabel}` :
            `${downloadsPM} downloads ${durationLabel}`
          }
        </div>
      </div>
      <div className="SubscriptionPlanCard__body">
        <p className="SubscriptionPlanCard__price">
          <p>${price}</p>
          <p>/{chargeLabel}</p>
        </p>
        {oldprice && <p className="SubscriptionPlanCard__oldprice">
          <p>${oldprice}</p>
          <p>/{chargeLabel}</p>
        </p>}
        {/*<div className="SubscriptionPlanCard__price-for-one">*/}
        {/*  <SmallDownIcon/> ${(pricePerDownload).toFixed(2)} for download*/}
        {/*</div>*/}
        <div className="SubscriptionPlanCard__description">
          {description ? description : ''}
        </div>
      </div>
      <div className="SubscriptionPlanCard__footer">
        <Button disabled={buttonDisabled}  variant={isActivePlan ? "third" : buttonType} onClick={() => handleClick()}>
          {isActivePlan ? <Fragment><CheckMark svgColor="" className="btn__icon_right"/>Your plan</Fragment> : buttonCaption}
        </Button>
      </div>
    </div>
  );
};

SubscriptionPlanCard.propTypes = {
  id:            PropTypes.number.isRequired,
  name:          PropTypes.string,
  cardClass:     PropTypes.string,
  description:   PropTypes.string,
  oldprice:      PropTypes.number,
  price:         PropTypes.number,
  duration:      PropTypes.string,
  downloadsPM:   PropTypes.number,
  buttonCaption: PropTypes.string,
  buttonType:    PropTypes.string,
  isActivePlan:  PropTypes.bool,
  handleClick:   PropTypes.func,
  durationCount: PropTypes.number,
  chargePeriodCount: PropTypes.number,
  chargePeriodLabel: PropTypes.string,
};

SubscriptionPlanCard.defaultProps = {
  buttonCaption: "Get started",
  buttonType: "outline-black",
  cardClass: "SubscriptionPlanCard",
  isActivePlan: false,
  handleClick: () => {},
  duration: "",
  durationCount: 0,
  chargePeriodCount: 0,
  chargePeriodLabel: "",
  downloadsPM: 0,
  price: 0,
};

export default SubscriptionPlanCard;
