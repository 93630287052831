const setQueryString = (targetComponent, searchType="assets") => {

  const {illustrationsParams, packsParams} = targetComponent.state;
  // @todo if necessary make url string look better
  // const {types} = targetComponent.props;

  if(searchType === "assets") {
    const {moodIds, typeId, searchText, categoryId, isFree, sortBy} = illustrationsParams;

    // let typeName = '';
    // if (Array.isArray(types) && types.length > 0 && typeId) {
    //   const typeObj = types.find(obj => obj.id == typeId);
    //   typeName = typeObj?.name.toLowerCase();
    // }

    let searchString = (moodIds !== null ? `moodIds[]=${moodIds}`: '');
    searchString += (typeId !== null ? `&typeId=${typeId}` : '');
    // searchString += (typeId !== null ? `&type=${typeName}` : '');
    searchString += (categoryId !== null ? `&categoryId=${categoryId}` : '');
    searchString += (isFree !== null ? `&isFree=${isFree}` : '');
    searchString += (sortBy !== null ? `&sortBy=${sortBy}` : '');
    searchString += (searchText !== "" && searchText !== null ? `&searchText=${searchText}` : '');

    if(searchString.charAt(0) === `&`) {
      searchString = searchString.substr(1)
    }

    targetComponent.setState({
      queryStringIllustrationsParams: searchString,
    });
  }

  if (searchType === "packs") {
    const {searchText, sortBy} = packsParams;

    let searchString = `&packs=true`;
    searchString += (sortBy !== null ? `&sortBy=${sortBy}` : '');
    searchString += (searchText !== "" && searchText !== null ? `&searchText=${searchText}` : '');

    if(searchString.charAt(0) === `&`) {
      searchString = searchString.substr(1)
    }


    targetComponent.setState({
      queryStringPacksParams: searchString,
    });
  }
};

export default setQueryString;
