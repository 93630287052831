import React from 'react';
import { AdobeLogo, GoogleLogo, IdeoLogo, LogitechLogo, MailRuLogo, MicrosoftLogo, PaypalLogo, PinterestLogo, UberLogo, VimeoLogo, WallmartLogo, YandexLogo } from "./Icons";
import './CompaniesLogos.scss';

function CompaniesLogos({ withBorders = true, boldTitle = false, title }) {
  return (
    <section className={`companies section ${withBorders && 'companies_with-borders'}`}>
      <h4 className={`companies__title ${boldTitle && 'companies__title_bold'}`}>
        {title}
      </h4>
      <div className="companies__logos container">
        <div className="companies__logo"><IdeoLogo /></div>
        <div className="companies__logo"><PinterestLogo /></div>
        <div className="companies__logo"><GoogleLogo /></div>
        <div className="companies__logo"><UberLogo /></div>
        <div className="companies__logo"><WallmartLogo /></div>
        <div className="companies__logo"><PaypalLogo /></div>
        <div className="companies__logo"><VimeoLogo /></div>
        <div className="companies__logo"><AdobeLogo /></div>
        <div className="companies__logo"><MailRuLogo /></div>
        <div className="companies__logo"><YandexLogo /></div>
        <div className="companies__logo"><LogitechLogo /></div>
        <div className="companies__logo"><MicrosoftLogo /></div>
      </div>
    </section>
  );
}

export default CompaniesLogos;
