import React from "react";
import Modal from "react-bootstrap/Modal";

import '../common_styles/buttons.scss'
import "./Modal.scss"
import {CloseElement} from "./Icons";
import ButtonBase from "./ButtonBase";
import {useDispatch} from "react-redux";

const description = "You can also upgrade the plan to overcome download limit";
const shortDescription = "Download limit reached";
const DownloadLimit = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/download-limit.png"

const ModalDownloadLimit = ({
           show,
  handleClose,
  autoRenewal,
  endDate,
}) => {
  const dispatch = useDispatch()
  const showModalPlans = () => dispatch({ type: 'modals/modalPlansActive', payload: true});

  return (
    <Modal
      animation={true}
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-download-limit modal-dialog-centered "
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
        <span className="close-button" onClick={handleClose}>
          <CloseElement/>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="image-container">
          <img src={DownloadLimit} width={70} height={70} alt=""/>
        </div>
        <p className="short-description text-center">{shortDescription}</p>
        {autoRenewal &&  <p>Your downloads will be refreshed on {endDate}</p> }

      </Modal.Body>
      <Modal.Footer>
        <p>{description}</p>
          <ButtonBase
            content="Upgrade plan"
            classes="big purple w-100"
            isEmulate="true"
            callback={() => {
              showModalPlans()
              handleClose()
            }}
          />
      </Modal.Footer>
    </Modal>
  )
}
export default ModalDownloadLimit;
