import React from 'react';
import axios from "axios";
import { Tooltip, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { getActiveSubscriptionInfo } from "../features/user/userSlice";
import { DownloadCloud, Heart, CloseElement, ArrowDownSmall } from "./Icons";
import './Product.css';

const placeholder = "You can download it again while you have an active subscription without spending limits.";

function Picture({ cover, containerType, altText='' }) {
    const pictureSizes = {
        col4: [410, 180, 245, 155, 205, 250],
        col3: [410, 180, 245, 220, 275, 350],
        col4Custom: [410, 180, 180, 180, 165, 210],
        col5: [410, 180, 180, 180, 165, 270],
        col2: [410, 180, 245, 340, 430, 535],
        colInf: [348, 427, 315, 315, 278, 259],
    };

  return (
      <picture>
          <source srcSet={`${cover}?height=${pictureSizes[containerType][0]} 1x, ${cover}?height=${pictureSizes[containerType][0] * 2} 2x`} media="(max-width: 576px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][1]} 1x, ${cover}?height=${pictureSizes[containerType][1] * 2} 2x`} media="(max-width: 768px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][2]} 1x, ${cover}?height=${pictureSizes[containerType][2] * 2} 2x`} media="(max-width: 992px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][3]} 1x, ${cover}?height=${pictureSizes[containerType][3] * 2} 2x`} media="(max-width: 1270px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][4]} 1x, ${cover}?height=${pictureSizes[containerType][4] * 2} 2x`} media="(max-width: 1510px)" />
          <img src={`${cover}?height=${pictureSizes[containerType][5]}`}  srcSet={`${cover}?height=${pictureSizes[containerType][5] * 2} 2x`} alt={altText} />
      </picture>
  );
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

const openNotificationWithIcon = (type, header, description) => {
  notification[type]({
    message: header,
    description: description,
    closeIcon: (<span><CloseElement/></span>),
  })
};

function Product(props) {
    const {
      id,
      cover,
      assetName,
      packName,
      likeCount,
      onClick,
      customerActivities,
      containerType,
      downloadErrorHandler,
      getCustomerActivities,
      changeLikesCountsLocally,
      isFree = false,
    } = props;

    const dispatch = useDispatch();
    const downloadClick = () => dispatch(getActiveSubscriptionInfo);
    const callback = () => dispatch({ type: 'modals/modalRegisterActive', payload: true });
    const authorizationStatus = useSelector((state) => state.user.authorized);

    let found = getCustomerActivities ? getCustomerActivities.hasOwnProperty(id) : null;
    let isDownloaded = found ? getCustomerActivities[id].isDownloaded : false;
    let isLiked = found ? getCustomerActivities[id].isLiked : false;

    const onDownload = () => {
      axios({
        url: `/api/illustration/download/${id}`,
        method: 'GET',
      }).then((response) => {
        if (!response.data.success) {
          if (response.data.code !== 109001001 && response.data.code !== 109001002 && response.data.code !== 109001003) {
            console.log(response.data.code);
            openNotificationWithIcon('error', 'Error', response.data.message);
          }
          downloadErrorHandler(response.data.code)
        } else {
          downloadClick();
          const url = window.URL.createObjectURL(new Blob([b64toBlob(response.data.result.zip)], {
            type: 'application/zip'
          }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.zip');
          document.body.appendChild(link);
          link.click();
          const {success} = response.data;
          customerActivities(id, "isDownloaded", success);
        }
      }).catch(function (error) {
        error.request.status === 401 && callback();
      });
    };

    const onLike = () => {
        axios.post(`/api/illustration/like`, {
            illustrationId: id,
        })
        .then((response) => {
          const {action} =  response.data.result;
          customerActivities(id, "isLiked", action);
          changeLikesCountsLocally(id, action);
        })
        .catch((error) => {
          console.log(error);
          error.request.status === 401 && callback();
        });
    };

  return (
    <div className="product">
      <div className="product__picture" onClick={() => onClick(id)}>
        <Picture
          cover={cover}
          containerType={containerType}
          altText={assetName || ''}
        />
      </div>

      <div className="product__shade product__shade_gray" />
      <div className="product__shade product__shade_white" />

      { isFree && <div className="product__free"> <ArrowDownSmall />Free</div>}

      <div className="product__info">
        <div className="product__texts">
          <p className="product__name">
            {assetName ? assetName : ''}
          </p>
          <p className="product__pack-name">
            {packName ? packName : ''}
          </p>
        </div>

        <div className="btn-wrapper">
          <button
            onClick={onLike}
            className="dialog-link dialog-link-like"
          >
            {authorizationStatus && isLiked ? <Heart fill="#FE3434"/> : <Heart fill="#A9A9A9"/>}
            {(Number.isInteger(likeCount) && likeCount > 0) && <p className="product__likes-count">{likeCount}</p>}
          </button>
          {authorizationStatus && isDownloaded && !isFree
            ?
            <Tooltip title={placeholder} color="#9130DE">
              <button
                onClick={onDownload}
                className="dialog-link dialog-link-download"
              >
                <DownloadCloud fill="#9130DE"/>
              </button>
            </Tooltip>
            :
            <button
              onClick={onDownload}
              className="dialog-link dialog-link-download"
            >
              <DownloadCloud fill={authorizationStatus && isDownloaded ? "#9130DE" : "#A9A9A9"}/>
            </button>
          }
        </div>

      </div>

    </div>
  );
}

export default Product;
