import React from "react";
import SearchForm from "./SearchForm";
import "./HeaderIntro.css";
import { DotBackground } from "./DotBackground";
import ProductHuntBadge from "./ProductHuntBadge";

function HeaderIntro(props) {
  const { onSubmitCallback, inputValue, onChangeCallback } = props;

  return (
    <section className="intro">
      <div className="container_for_header_intro">
        <DotBackground />
        <div className="face-in-cube">
          <img className="face-in-cube-img" src="/face-in-cube.png" alt="" />
        </div>

        <div className="worm-in-cube">
          <img className="worm-in-cube-img" src="/worm-in-cube.png" alt="" />
        </div>

        <div className="cursor-wrapper-img">
          <img className="cursor-img" src="/cursor.png" alt="" />
        </div>

        <div className="container-custom">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto">
              <ProductHuntBadge />
              <h1 className="title">
                Save on designing for your next startup idea
              </h1>
              <p className="description">
                Millions of graphics for your design projects. Created by
                independent designers.
              </p>
              <section className="form">
                <h3 className="visually-hidden">form</h3>
                <SearchForm
                  placeholder={"Search for assets"}
                  onSubmitCallback={onSubmitCallback}
                  inputValue={inputValue}
                  onChangeCallback={onChangeCallback}
                  autoFocus={false}
                  newMainPage={true}
                  show={true}
                  examples={[
                    "error 404",
                    "cryptocurrency",
                    "shopping",
                    "business",
                  ]}
                  withTextSubmitButton={true}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeaderIntro;
