import React from 'react';

import { Form, Input, Button, Checkbox, notification } from 'antd';

import { postFormData } from '../lib/apiRequests';
import useStateCallback from '../lib/customHooks/useStateCallback';

import {
  DollarIcon,
  CompleteBriefFormVectorLeft,
  CompleteBriefFormVectorRight,
  CompleteBriefFormLeftStarSmall,
  CompleteBriefFormLeftVectorSmallFirst,
  CompleteBriefFormLeftVectorSmallSecond,
} from './Icons';

import './CompleteBriefForm.css';

const openNotificationWithIcon = (type, header, description) => {
  notification[type]({
    message: header,
    description: description,
  })
}

const onFinishFailed = (errorInfo) => {
  // console.log('Failed:', errorInfo);
};

const CompleteBriefForm = (props) => {
  const [responseData, setResponsedata] = useStateCallback();
  const [completeBriefForm] = Form.useForm();

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('authorEmail', values.email);
    formData.append(
      'requestBody',
      `name: ${values.name}, types of custom design: ${values.designTypes.join(', ')}, brief: ${values.brief}`);

    postFormData('/api/user/contactUs', formData)
      .then(data => {
        setResponsedata(data, newData => {
          if (newData.success) {
            completeBriefForm.resetFields();
            return openNotificationWithIcon('success', 'Success', 'Message was sent');
          }
          return openNotificationWithIcon('error', 'Error', newData.message);
        });
      })
      .catch(err => {
        console.log(responseData);
        setResponsedata(err, newData => { return openNotificationWithIcon('error', 'Error', newData.message)} )
      })
  };

  return (
      <Form
      form={completeBriefForm}
      name='completeBriefForm'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className='CompleteBriefForm'
      colon={false}
    >
      <div className='CompleteBriefForm__left-svg-vector'>
        <CompleteBriefFormVectorLeft />
      </div>
      <div className='CompleteBriefForm__right-svg-vector'>
        <CompleteBriefFormVectorRight />
      </div>

      <div className='CompleteBriefForm__left-star-small'>
        <CompleteBriefFormLeftStarSmall />
      </div>

      <div className='CompleteBriefForm__right-vector-top'>
        <CompleteBriefFormLeftVectorSmallFirst />
      </div>

      <div className='CompleteBriefForm__right-vector-bottom'>
        <CompleteBriefFormLeftVectorSmallSecond />
      </div>

      <div className='CompleteBriefForm__inner_flex'>
        <Form.Item
          name='name'
          label='Your name:'
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please type your name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label='Your email:'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input your email',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

        <Form.Item
          name='designTypes'
          label='Select type of custom design:'
          rules={[
            {
              required: true,
              message: 'Please select at least one design type',
            },
          ]}
        >
          <Checkbox.Group
            className='CompleteBriefForm__checkbox-group'
          >
            <Checkbox value='Vector illustration' className='CompleteBriefForm__checkbox'>
              Vector illustrations
            </Checkbox>

            <Checkbox value='3D illustration' className='CompleteBriefForm__checkbox'>
              3D illustrations
            </Checkbox>

            <Checkbox value='Web design' className='CompleteBriefForm__checkbox'>
              Web design
            </Checkbox>

            <Checkbox value='App design' className='CompleteBriefForm__checkbox'>
              App design
            </Checkbox>

            <Checkbox value='Graphics' className='CompleteBriefForm__checkbox'>
              Graphics
            </Checkbox>

          </Checkbox.Group>
        </Form.Item>



        <Form.Item
          name='brief'
          label='Describe your idea shortly:'
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please describe your idea',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>


      <div className='CompleteBriefForm__inner_flex_left-big'>
        <div className='CompleteBriefForm__inner_flex_left-big-icon-container'>
          <DollarIcon />
        </div>
        <div className='CompleteBriefForm__inner_flex_left-big-text-container'>
          <p>The cost of your illustrations <br/> will be calculated individually</p>
        </div>
        <Form.Item >
          <Button type='primary' htmlType='submit' className='CompleteBriefForm__submit-button'>
            Send
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default CompleteBriefForm;
