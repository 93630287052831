import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

import envs from './lib/envs';
import { showCrisp, hideCrisp } from "./lib/crisp";

import Footer from './components/Footer';
import Header from './components/Header';
import Picture from './components/Picture';
import ContactUsForm from './components/ContactUsForm';

import './ContactUs.css';

const ContactUsNormalImg = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ContactUsPageImage.png";
const ContactUsBigImg = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ContactUsPageImage@2x.png";

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class ContactUs extends Component {
  componentDidMount() {
    showCrisp();
  }

  componentWillUnmount() {
    hideCrisp();
  }

  render() {
    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale Contact Us</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container ContactUs'>
          <div className='row'>
            <div className='col-12 col-lg-6 ContactUs__firstcolumn'>
              <h2 className='ContactUs__header'>Contacts</h2>
              <p className='ContactUs__maintext'>
                If you have any questions regarding the service, legal notes, or illustration using, feel free
                to contact us using the contact form. Also, you can contact us via the live chat on the bottom-right
                corner of the website or write to <a href='mailto:hello@storytale.io'> hello@storytale.io </a> email to get help.
              </p>
              <Picture standart={ContactUsNormalImg } big={ContactUsBigImg }  alt='Contact us'/>
            </div>
            <div className='col-12 col-lg-6'>
              <h1 className='ContactUs__header'>Leave us a message</h1>
              <ContactUsForm />
              <div className='ContactUs__container_flex'>
                <div className='ContactUs__container-item'>
                  <h2 className='ContactUs__header'>Email</h2>
                  <a href='mailto:hello@storytale.io'>hello@storytale.io</a>
                </div>
                <div className='ContactUs__container-item'>
                  <h2 className='ContactUs__header'>Socials</h2>
                  <div className='ContactUs__links'>
                    <a target='_blank' rel='noreferrer' href='https://dribbble.com/storytale'>Dribbble</a>
                    <a target='_blank' rel='noreferrer' href='https://twitter.com/GetCraftwork'>Twitter</a>
                    <a target='_blank' rel='noreferrer' href='https://www.instagram.com/storytale.io/'>Instagram</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer bordered={true} />
      </>
    )
  }
}

export default withRouter(ContactUs);

