import React, {Component} from "react";
import axios from "axios";
import { withRouter } from "react-router";
import {connect} from "react-redux";
import moment from "moment";
import handleViewport from 'react-in-viewport';
import {Helmet} from "react-helmet";
import throttle from 'lodash.throttle';

//styles
import "./Browse.css"

//components
import PacksList from "./components/PacksList";
import ReadyTo from "./components/ReadyTo";
import IllustrationsList from "./components/IllustrationsList";
import BeforeFooter from "./components/BeforeFooter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ModalIllustrationInfo from "./components/ModalIllustrationInfo";
import SkeletonButton from "./components/SkeletonButton";
import EmptyData from "./components/EmptyData";
import Tabs from "./components/Tabs";
import ModalDownloadLimit from "./components/ModalDownloadLimit";
import ModalSubscriptionError from "./components/ModalSubscriptionError";
import SearchForm from "./components/SearchForm";
import Block from "./components/Block";
import FilterMenu from './components/FilterMenu';
import FilterDropdown from './components/FilterDropdown';
import ScrollUp from './components/ScrollUp';
import PacksGrid from "./components/PacksGrid";

//lib
import getIllustrationInfo from "./lib/getIllustrationInfo";
import changeIllustrationActivities from "./lib/changeIllustrationActivities";
import changePicturesLikesCountsLocally from "./lib/changePicturesLikesCountsLocally";
import addPictureLikeCounts from "./lib/addPictureLikeCounts";
import getCustomerActivities from "./lib/getCustomerActivities";
import handleScroll from "./lib/handleScroll";
import { setPagesHistory } from './lib/domainPagesHistory';
import downloadErrorHandler from "./lib/downloadErrorHandler";
import loadMoreIllustrationsFrom from "./lib/loadMoreIllustrationsFrom";
import setQueryString from "./lib/setQueryString";
import scrollToTop from "./lib/scrollToTop";
import scrollToTopSmooth from "./lib/scrollToTopSmooth";
import {ArrowDownBig, UnionFour, UnionSix} from "./components/Icons";
import {filterEmptyCategories} from "./lib/utils";
import FilterSelect from "./components/FilterSelect";

//constants
const COUNT = "60";
const MAX_ELEMENTS = 12;
const MAX_ELEMENTS_VIEW = 15;
const ASSETS_SEARCH_EXAMPLES = ['error 404', 'cryptocurrency', 'ui kit', 'business', '3d', 'Icons', 'Mockups'];
const PACKS_SEARCH_EXAMPLES = ['Superscene', 'Oslo', 'Control', 'Denmark'];
const queryString = require("query-string");

class Browse extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      pictures: [],
      packs: [],
      isLoading: true,
      illustrationsFromPack: [],
      illustrationsFromKeywords: [],
      illustrationsFromKeywordsParams: {
        searchText: "",
        count: MAX_ELEMENTS,
        page: 1
      },
      illustrationsFromPackParams: {
        packId: "",
        count: MAX_ELEMENTS,
        sortBy: null,
        page: 1
      },
      customerActivities: {},
      picturesLikesCounts: {},
      activeFilter: null,
      illustrationsParams: {
        typeId: null,
        moodIds: null,
        categoryId: null,
        count: COUNT,
        searchText: null,
        page: "1",
        isFree: null,
        sortBy: null,
      },
      packsParams: {
        packs: true,
        count: COUNT,
        searchText: null,
        page: "1",
        sortBy: null,
      },
      currentIllustrationInfo: null,
      currentIllustrationPackData: null,
      isLoadingInfoData: true,
      isLoadingPackIllustrations: true,
      isLoadingKeywordsIllustrations: true,
      isLoadingPackInfo: true,
      showModal: false,
      showModalInfo: false,
      showModalDownloadLimits: false,
      showModalSubscriptionEnded: false,
      currentIllustrationIndex: null,
      searchForIllustrations: '',
      searchForPacks: '',
      queryStringPacksParams: 'packs=true',
      queryStringIllustrationsParams: '',
      showDetachedSearch: false,
      showScrollUp: false,
      scrollDir: '',
      lastScroll: 0,
      searchPlaceholder: '',
      sortBy: 'newest'
    };

    this.onChangeRequest = this.onChangeRequest.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);

    this.handleMoreClick = this.handleMoreClick.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.clearAllPackFilters = this.clearAllPackFilters.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModalInfo = this.showModalInfo.bind(this);
    this.hideModalInfo = this.hideModalInfo.bind(this);
    this.showModalDownloadLimits = this.showModalDownloadLimits.bind(this);
    this.hideModalDownloadLimits = this.hideModalDownloadLimits.bind(this);
    this.showModalSubscriptionEnded = this.showModalSubscriptionEnded.bind(this);
    this.hideModalSubscriptionEnded = this.hideModalSubscriptionEnded.bind(this);
    this.setQueryStringWrapper = this.setQueryStringWrapper.bind(this);
    this.handleScrollWrapper = this.handleScrollWrapper.bind(this);
    this.handleScrollThrottled = throttle(this.handleScrollWrapper, 400);
    this.loadMoreIllustrationsFromWrapper = this.loadMoreIllustrationsFromWrapper.bind(this);
    this.getIllustrationInfoWrapper = this.getIllustrationInfoWrapper.bind(this);
    this.getCustomerActivitiesWrapper = this.getCustomerActivitiesWrapper.bind(this);

    this.changeIllustrationActivitiesWrapper = this.changeIllustrationActivitiesWrapper.bind(this);
    this.changePicturesLikesCountsLocallyWrapper = this.changePicturesLikesCountsLocallyWrapper.bind(this);
    this.downloadErrorHandlerWrapper = this.downloadErrorHandlerWrapper.bind(this);

    this.executeScroll = this.executeScroll.bind(this);
    this.onChangeSearchForIllustrations = this.onChangeSearchForIllustrations.bind(this);
    this.onChangeSearchForPacks = this.onChangeSearchForPacks.bind(this);
    this.onSubmitSearchInputs = this.onSubmitSearchInputs.bind(this);
    this.onChangeSortByRating = this.onChangeSortByRating.bind(this);
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollThrottled);

    const {location} = this.props;
    const parsed = queryString.parseUrl(window.location.href, {arrayFormat: "bracket"});
    let string = queryString.stringify(parsed.query, {arrayFormat: "bracket"});
    const {moodIds = null, typeId = null, packs = false, count = COUNT, searchText = null, categoryId = null, isFree = null, sortBy = null} = parsed.query;

    // string += "&sortBy=4"

    if (sortBy !== null && sortBy === "4") {
      this.setState({
        sortBy: "popular",
      });
    }

    // @todo if necessary make url string look better
    // const {moodIds = null, type = null, packs = false, count = COUNT, searchText = null, categoryId = null, isFree = null} = parsed.query;
    // let typeId = null;
    //
    // console.log('I AM HERE 1');
    // console.log('Array.isArray(types)', Array.isArray(types));
    // console.log('types.length > 0', types.length > 0, types);
    // console.log('type !== null', type !== null);
    //
    //
    // if (Array.isArray(types) && types.length > 0 && type !== null) {
    //   console.log('I AM HERE 2');
    //   const typeObj = types.find(obj => obj.name === ((typeof type === 'string' || type instanceof String) && type.toUpperCase()));
    //   typeId = typeObj?.id;
    // }

    const requestIllustrations = axios.get(
      `/api/illustration?&count=${count}&${string}`
    );

    const requestAllPacks = axios.get(`/api/pack?count=${count}${string}`);

    if (packs) {
      this.setState({
        activeFilter: "packs",
        searchPlaceholder: 'Search for packs ...',
        queryStringPacksParams: string,
        searchForPacks: searchText,
      });

      axios.all([requestAllPacks]).then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const {packsData} = responseOne.data.result;

          this.setState(prevState => {
            let packsParams = Object.assign({}, prevState.packsParams);
            packsParams.count = count;
            packsParams.searchText = searchText;
            packsParams.sortBy = sortBy;

            let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
            illustrationsParams.sortBy = sortBy;

            return {
              packsParams,
              illustrationsParams,
              packs: packsData,
              isLoading: false
            };
          }, () => {
            this.onChangeRequest(this.state.illustrationsParams);
            this.setQueryStringWrapper();
          });
        })
      ).catch(function (error) {
        console.log(error);
      });

    } else {
      this.setState({
        activeFilter: "assets",
        searchPlaceholder: 'Search for assets ...',
        queryStringIllustrationsParams: string,
        searchForIllustrations: searchText,
      });
      axios
        .all([
          requestIllustrations,
          requestAllPacks
        ])
        .then(
          axios.spread((...responses) => {
            const responseFirst = responses[0];
            const responseSecond = responses[1];

            const {illustrationData} = responseFirst.data.result;
            const {packsData} = responseSecond.data.result;
            const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);

            this.setState(prevState => {
              let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
              illustrationsParams.typeId = typeId;
              illustrationsParams.moodIds = moodIds;
              illustrationsParams.categoryId = categoryId;
              illustrationsParams.count = count;
              illustrationsParams.searchText = searchText;
              illustrationsParams.isFree = isFree;
              illustrationsParams.sortBy = sortBy;

              let packsParams = Object.assign({}, prevState.packsParams);
              packsParams.sortBy = sortBy;

              return {
                illustrationsParams,
                packsParams,
                pictures: illustrationData,
                packs: packsData,
                activeFilter: "assets",
                searchPlaceholder: 'Search for assets ...',
                isLoading: false,
                picturesLikesCounts: updatedPicturesLikesCounts,
              };
            }, () => {
              this.getCustomerActivitiesWrapper(this.state.pictures);
              this.onChangeRequest(this.state.packsParams, 'packs');
              this.setQueryStringWrapper('packs');
            });
          })
        ).catch(function (error) {
        console.log(error);
      });
    }

    setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollThrottled);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.authorized !== this.props.authorized && this.props.authorized === true) {
      this.getCustomerActivitiesWrapper(this.state.pictures);
    }

    if(prevState.queryStringPacksParams !== this.state.queryStringPacksParams && this.state.activeFilter === 'packs') {
      window.history.pushState('', '',`/browse/?${this.state.queryStringPacksParams}`);
    }

    if(prevState.queryStringIllustrationsParams !== this.state.queryStringIllustrationsParams && this.state.activeFilter === 'assets') {
      window.history.pushState('', '',`/browse/?${this.state.queryStringIllustrationsParams}`);
    }
  }

  handleScrollWrapper = () => {
    const containerComponent = this;
    handleScroll(containerComponent, '#catalog', '#stop', 0,300, 'showDetachedSearch');
    if (window.innerWidth > 768) {
      handleScroll(containerComponent, '#catalog', '#stop', 150,-1000, 'showScrollUp');
    }
  };

  setQueryStringWrapper = (value) => {
    const props = this;
    setQueryString(props, value)
  };

  getOffset = (element, gap=0) => {
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset ||  document.documentElement.scrollTop;
    return rect.top + scrollTop - gap;
  };

  downloadErrorHandlerWrapper = (errorCode) => {
    const {history, showModalPlans} = this.props;
    downloadErrorHandler(errorCode, history, this.showModalSubscriptionEnded, this.showModalDownloadLimits, showModalPlans);
  };

  loadMoreIllustrationsFromWrapper = () => {
    const props = this;
    loadMoreIllustrationsFrom(props);
  };

  getIllustrationInfoWrapper = (id) => {
    const props = this;

    const  callback = () => setPagesHistory(window.location.origin, this.props.history.location.pathname, 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
    getIllustrationInfo(props, id, callback, this.getCustomerActivitiesWrapper);
  };

  changeIllustrationActivitiesWrapper = (id, type, result) => {
    const props = this;

    changeIllustrationActivities(props, id, type, result);
  };

  changePicturesLikesCountsLocallyWrapper = (id, actionTaken) => {
    const stateOwnerThis = this;
    changePicturesLikesCountsLocally(stateOwnerThis, id, actionTaken);
  };

  getCustomerActivitiesWrapper = (originalData) => {
    const props = this;
    getCustomerActivities(props, originalData);
  };

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  showModalInfo() {
    this.setState({
      showModalInfo: true,
    });
  }

  hideModalInfo(original=true) {
    const {queryStringIllustrationsParams} = this.state;

    if(original) {
      window.history.pushState('', '',`/browse/?${queryStringIllustrationsParams}`)
    }
    this.setState({
      showModalInfo: false,
    });
  }

  onChangeRequest(reguest, type="assets") {

    if(type === "assets") {

      let search = {
        params: reguest
      };

      axios
        .get(`/api/illustration`, search)
        .then(response => {
          const { illustrationData } = response.data.result;
          const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);

          this.setState(
            () => {
              return {
                pictures: illustrationData,
                isLoading: false,
                picturesLikesCounts: updatedPicturesLikesCounts,
              };
            },
            () => {
              this.getCustomerActivitiesWrapper(this.state.pictures);
            }
          );
        }).catch(function (error) {
        console.log(error);
      });
    } else {

      let search = {
        params: reguest
      };

      axios
        .get(`/api/pack`, search)
        .then(response => {
          const { packsData } = response.data.result;
          this.setState(
            () => {
              return {
                packs: packsData,
                isLoading: false,
              };
            }
          );
        }).catch(function (error) {
        console.log(error);
      });
    }
  }

  //click on filter
  handleFilterClick(filter, value= null, multiply = false) {

    this.setState(
      prevState => {
        let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
        illustrationsParams[filter] = value;
        illustrationsParams.count = COUNT;
        illustrationsParams.page = "1";

        return {
          illustrationsParams,
          isLoading: true,
        };
      },
      () => {
        this.onChangeRequest(this.state.illustrationsParams);
        this.setQueryStringWrapper();
        setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
      }
    );

    if (multiply) {
      this.setState(
        prevState => {
          let packsParams = Object.assign({}, prevState.packsParams);
          packsParams[filter] = value;
          packsParams.count = COUNT;
          packsParams.page = "1";

          return {
            packsParams,
          };
        }, () => {
          this.onChangeRequest(this.state.packsParams, 'packs');
          this.setQueryStringWrapper('packs');
        }
      );
    }
  }

  onSubmitSearchInputs(val = null) {

    const {activeFilter} = this.state;

    if(activeFilter === "packs") {
      this.setState(
        prevState => {
          let packsParams = Object.assign({}, prevState.packsParams);
          packsParams.searchText = val;
          packsParams.count = COUNT;
          packsParams.page = "1";

          return {
            packsParams,
            isLoading: true,
          };
        },
        () => {
          this.onChangeRequest(this.state.packsParams, activeFilter);
          this.setQueryStringWrapper(activeFilter);
          setPagesHistory(window.location.href);
        }
      );
    } else {
      this.setState(
        prevState => {
          let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
          illustrationsParams.searchText = val;
          illustrationsParams.count = COUNT;
          illustrationsParams.page = "1";
          illustrationsParams.moodIds = null;
          illustrationsParams.typeId = null;
          illustrationsParams.categoryId = null;
          illustrationsParams.isFree = null;

          return {
            illustrationsParams,
            isLoading: true,
          };
        },
        () => {
          this.onChangeRequest(this.state.illustrationsParams, activeFilter);
          this.setQueryStringWrapper(activeFilter);
          setPagesHistory(window.location.href);
        }
      );
    }
  }

  incrementParamsCount() {
    let {activeFilter} = this.state;

    if(activeFilter === "packs") {
      this.setState (
        prevState => {
          let packsParams = Object.assign({}, prevState.packsParams);
          packsParams.page = (+(packsParams.page) + 1);
          return {
            packsParams,
          };
        },
        () => {
          this.onConcatRequest(this.state.packsParams, activeFilter);
        }
      );
    } else {
      this.setState (
        prevState => {
          let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
          illustrationsParams.page = (+(illustrationsParams.page) + 1);
          return {
            illustrationsParams,
          };
        },
        () => {
          this.onConcatRequest(this.state.illustrationsParams, activeFilter);
        }
      );
    }
  }

  //click on more trigger (more button)
  handleMoreClick() {

    let {activeFilter, packs, pictures} = this.state;

    let illustrationsParamsCount = this.state.illustrationsParams.count;
    let illustrationsPageCount = this.state.illustrationsParams.page;

    let packsParamsCount = this.state.packsParams.count;
    let packsPageCount = this.state.packsParams.page;

    if (activeFilter === 'assets' && pictures.length === (+illustrationsParamsCount * +illustrationsPageCount)) {
      this.incrementParamsCount();
    } else if (activeFilter === 'packs' && packs.length === (+packsParamsCount * +packsPageCount)) {
      this.incrementParamsCount();
    }
  }

  //click on tabs
  handleClick(evt) {

    const {queryStringIllustrationsParams, queryStringPacksParams} = this.state;
    const target = evt.target;
    const parent = target.closest('.nav-link');
    const attr = parent?.getAttribute('data-value').toLowerCase() || null;

    if (attr === 'packs') {

      const {packs} = this.state;
      const {count, page} = this.state.packsParams;

      window.history.pushState('', '',`/browse/?${queryStringPacksParams}`);

      if (packs.length > 0) {
        this.setState (
          () => {
            return {
              activeFilter: "packs",
              searchPlaceholder: 'Search for packs ...',
            };
          }
        );
      } else {
        const requestPacks = axios.get(`/api/pack?count=${count}&page=${page}`);

        axios.all([requestPacks]).then(
          axios.spread((...responses) => {
            const responseOne = responses[0];
            const {packsData} = responseOne.data.result;

            this.setState (
              () => {
                return {
                  packs: packsData,
                  activeFilter: "packs",
                  searchPlaceholder: 'Search for packs ...',
                };
              }
            );
          })
        ).catch(function (error) {
          console.log(error);
        });
      }
    } else if (attr === "assets") {

      const {pictures} = this.state;

      window.history.pushState('', '',`/browse/?${queryStringIllustrationsParams}`);

      if (pictures.length > 0 ) {
        this.setState({
          activeFilter: "assets",
          searchPlaceholder: 'Search for assets ...',
          searchForIllustrations: this.state.searchForIllustrations,
        });
      } else {

        let search = {
          params: this.state.illustrationsParams
        };

        const requestIllustrations = axios.get(`/api/illustration`, search);

        axios.all([
          requestIllustrations,
        ]).then(
          axios.spread((...responses) => {
            const responseOne = responses[0];
            const {illustrationData} = responseOne.data.result;
            const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);

            this.setState (
              () => {
                return {
                  pictures: illustrationData,
                  activeFilter: "assets",
                  searchPlaceholder: 'Search for assets ...',
                  searchForIllustrations: this.state.searchForIllustrations,
                  picturesLikesCounts: updatedPicturesLikesCounts,
                };
              }, () => {
                this.getCustomerActivitiesWrapper(this.state.pictures)
              }
            );
          })
        ).catch(function (error) {
          console.log(error);
        });
      }
    }
  }

  clearAllFilters = async (evt) => {
    evt.preventDefault();

    this.setState({
      isLoading: true
    });

    const responceAllIllustration = await axios.get(
      `/api/illustration?count=${COUNT}`
    );

    axios
      .all([responceAllIllustration])
      .then(
        axios.spread((...responses) => {
          const {illustrationData} = responses[0].data.result;
          const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);

          window.history.pushState('', '',`/browse`);

          this.setState(
            prevState => {
              let illustrationsParams = Object.assign({}, prevState.illustrationsParams);
              illustrationsParams.typeId = null;
              illustrationsParams.moodIds = null;
              illustrationsParams.count = COUNT;
              illustrationsParams.categoryId = null;
              illustrationsParams.page = "1";
              illustrationsParams.searchText = null;
              illustrationsParams.isFree = null;

              return {
                illustrationsParams,
                pictures: illustrationData,
                count: COUNT,
                isLoading: false,
                searchForIllustrations: '',
                queryStringIllustrationsParams: '',
                picturesLikesCounts: updatedPicturesLikesCounts,
              };
            }, () => {
              this.getCustomerActivitiesWrapper(this.state.pictures)
            }
          );
        })
      )
      .catch(function (error) {
        console.log(error);
      });
    return;
  };

  clearAllPackFilters() {
    const {activeFilter} = this.state;
    const defaultString = "packs=true";

    window.history.pushState('', '',`/browse/?packs=true`);

    this.setState(
      prevState => {
        let packsParams = Object.assign({}, prevState.packsParams);
        packsParams.searchText = null;

        return {
          packsParams,
          queryStringPacksParams: defaultString,
          searchForPacks: ''

        };
      }, () => {
        this.onChangeRequest(this.state.packsParams, activeFilter);
      }
    );
  }

  onConcatRequest(reguest, type) {

    let search = {
      params: reguest
    };

    if (type === "assets") {
      axios
        .get(`/api/illustration`, search)
        .then(response => {
          const {illustrationData} = response.data.result;
          const picturesClone = [...this.state.pictures];
          const picturesConcatResult = picturesClone.concat(illustrationData);
          const updatedPicturesLikesCounts = addPictureLikeCounts(this.state.picturesLikesCounts, illustrationData);

          this.setState(
            () => {
              return {
                pictures: picturesConcatResult,
                picturesLikesCounts: updatedPicturesLikesCounts,
              };
            },
            () => {
              this.getCustomerActivitiesWrapper(this.state.pictures);
            }
          );
        }).catch(function (error) {
        console.log(error);
      });
    } else {
      axios
        .get(`/api/pack`, search)
        .then(response => {
          const {packsData} = response.data.result;
          const packsClone = [...this.state.packs];
          const result = packsClone.concat(packsData);

          this.setState({
            packs: result,
          });
        }).catch(function (error) {
        console.log(error);
      });
    }
  }

  hideModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: false,
    });
  }

  showModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: true,
    });
  }

  hideModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: false,
    });
  }

  showModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: true,
    });
  }

  executeScroll = () => this.myRef.current.scrollIntoView();

  onChangeSearchForIllustrations(value) {
    this.setState({
      searchForIllustrations: value,
    });
  }

  onChangeSearchForPacks(value) {
    this.setState({
      searchForPacks: value,
    });
  }

  onChangeSortByRating(value) {
    this.setState({
      sortBy: value,
    });
  }

  defineScrollDirection(){
    const currentScrollTopVal = document.body.scrollTop || document.documentElement.scrollTop;
    if(this.state.lastScroll > currentScrollTopVal) {
      this.setState({
        scrollDir: 'top',
        lastScroll: currentScrollTopVal
      });
    } else if(this.state.lastScroll < currentScrollTopVal) {
      this.setState({
        scrollDir: 'bottom',
        lastScroll: currentScrollTopVal
      });
    }
  }

  render() {
    const {
      pictures,
      packs,
      showModalInfo,
      currentIllustrationPackData,
      isLoadingInfoData,
      isLoading,
      activeFilter,
      currentIllustrationInfo,
      illustrationsFromPack,
      illustrationsFromKeywords,
      illustrationsFromKeywordsParams,
      illustrationsFromPackParams,
      customerActivities,
      isLoadingPackInfo,
      isLoadingPackIllustrations,
      picturesLikesCounts,
      sortBy,
    } = this.state;

    const {autoRenewal, endDate, types, moods, categories, history} = this.props;
    const storytaleUrl = process.env.REACT_APP_STORYTALE_APP;
    const ViewportBlock = handleViewport(Block,{ rootMargin: '350px' });
    const {hasSubscribe} = this.props;

    return (
      <div className="browse">
        <Header
          handleFilterClick={value => {
            this.handleFilterClick('categoryId', value);
          }}
        />

        <Helmet>
          <title>Storytale Browse</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
          <link rel="canonical" href={`${storytaleUrl}/browse`} />
        </Helmet>

        <ModalDownloadLimit
          show={this.state.showModalDownloadLimits}
          handleClose={this.hideModalDownloadLimits}
          endDate={moment(endDate).format("MMM Do")}
          autoRenewal={autoRenewal}
        />

        <ModalSubscriptionError
          show={this.state.showModalSubscriptionEnded}
          handleClose={this.hideModalSubscriptionEnded}
        />

        <ModalIllustrationInfo
          show={showModalInfo}
          showModalLogin={this.showModal}
          handleClose={() => {
            this.hideModalInfo();
          }}
          data={currentIllustrationInfo}
          packData={currentIllustrationPackData}
          packIllustrations={illustrationsFromPack}
          keywordsIllustrations={illustrationsFromKeywords}
          requestKeywordsParams={illustrationsFromKeywordsParams}
          requestPackParams={illustrationsFromPackParams}
          getCustomerActivities={customerActivities}
          isLoading={isLoadingInfoData}
          isLoadingPackInfo={isLoadingPackInfo}
          isLoadingPackIllustrations={isLoadingPackIllustrations}
          callbackButtonKeywordsClick={this.loadMoreIllustrationsFromWrapper}
          customerActivities={this.changeIllustrationActivitiesWrapper}
          downloadErrorHandler={this.downloadErrorHandlerWrapper}
          packs={packs}
          onClick={this.getIllustrationInfoWrapper}
          callbackCategoryClick={(value) => {
            this.handleFilterClick('categoryId', value);
            scrollToTop();
          }}
          callbackTagClick={(value) => {
            this.onChangeSearchForIllustrations(value);
            this.onSubmitSearchInputs(value);
            this.hideModalInfo(false);
            scrollToTop();
          }}
          redirect={true}
          browseByPacksCustomButton={true}
          callbackButtonPackClick={(evt) => {
            this.hideModalInfo();
            scrollToTop();
            this.handleClick(evt)
          }}
          picturesLikesCounts={picturesLikesCounts}
          changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
          showMorePackButton={false}
        />

        <section className="browse__filters">
          <div className="container-custom">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <section className="form">
                  <SearchForm
                    placeholder={this.state.searchPlaceholder}
                    onSubmitCallback={this.onSubmitSearchInputs}
                    inputValue={activeFilter === 'assets' ? this.state.searchForIllustrations  : this.state.searchForPacks}
                    onChangeCallback={activeFilter === 'assets'? this.onChangeSearchForIllustrations : this.onChangeSearchForPacks}
                    autoFocus={true}
                    show={true}
                  />

                  <SearchForm
                    placeholder={this.state.searchPlaceholder}
                    onSubmitCallback={(inputValue) => {
                      this.onSubmitSearchInputs(inputValue);
                      if (this.state.showDetachedSearch) {
                        scrollToTop();
                      }
                    }}
                    inputValue={activeFilter === 'assets' ? this.state.searchForIllustrations  : this.state.searchForPacks}
                    onChangeCallback={activeFilter === 'assets'? this.onChangeSearchForIllustrations : this.onChangeSearchForPacks}
                    autoFocus={false}
                    show={this.state.showDetachedSearch}
                    fixedStyle={true}
                  />

                </section>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <section className="browse__filter-controls">
                  <Tabs
                    array={[`Assets`, `Packs`]}
                    arrayIcons={[<UnionFour/>, <UnionSix/>]}
                    updateData={this.handleClick}
                    current={this.state.activeFilter}
                  />
                  {activeFilter === 'assets' &&
                    <FilterMenu
                      options={filterEmptyCategories(categories)}
                      paramsSelectedId={this.state.illustrationsParams.categoryId}
                      handleMenuOptionClick={value => this.handleFilterClick('categoryId', value)}
                      specialNames={null}
                    />
                  }
                  <div className="browse__filters-container">
                    {activeFilter === 'assets' &&
                      <FilterDropdown
                        types={types}
                        typeId={this.state.illustrationsParams.typeId}
                        isFree={this.state.illustrationsParams.isFree}
                        handleCategoryClick={() => console.log('category click')}
                        handleTypeSwitchClick={value => this.handleFilterClick('typeId', value)}
                        handleFreeSwitchClick={value => this.handleFilterClick('isFree', value)}
                      />
                    }
                    <FilterSelect
                      value={sortBy}
                      onChange={(v) => {
                        this.handleFilterClick('sortBy', v === 'popular' ? 4 : null, true);
                        this.onChangeSortByRating(v);
                      }}
                      options={[
                        {
                          value: 'newest',
                          label: 'Newest',
                        },
                        {
                          value: 'popular',
                          label: 'Popular',
                        },
                      ]}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <section className="browse__catalog position-relative" id="catalog" ref={this.myRef}>
          <div className="container-fluid" >
            <div className="row">
              <div className={`col main-content`}>
                <section className="illustrations-pack">
                  <h2 className="visually-hidden">Illustrations list</h2>
                  {activeFilter === 'assets' &&
                  <>
                    <IllustrationsList
                      onClick={this.getIllustrationInfoWrapper}
                      pictures={pictures}
                      status={isLoading}
                      containerType={"colInf"}
                      customerActivities={this.changeIllustrationActivitiesWrapper}
                      getCustomerActivities={customerActivities}
                      downloadErrorHandler={this.downloadErrorHandlerWrapper}
                      maxElements={MAX_ELEMENTS_VIEW}
                      picturesLikesCounts={picturesLikesCounts}
                      changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
                    />
                    {isLoading === false && pictures.length === 0 &&
                      <EmptyData
                        onClick={this.clearAllFilters}
                      />
                    }
                  </>
                  }
                </section>

                {activeFilter === 'packs' &&
                <>
                  <section className="browse-by-packs">
                    {isLoading === false && packs.length === 0 &&
                    <EmptyData
                      onClick={this.clearAllPackFilters}
                    />
                    }
                    <PacksGrid
                      packs={packs}
                      hasSection={false}
                      hasContainer={false}
                      hasSmartGrid={true}
                      isLoading={isLoading}
                      hasButton={false}
                      quantity={MAX_ELEMENTS_VIEW}
                    />
                  </section>
                </>
                }
                <div className="row">
                  <div className="col mx-auto">

                    {isLoading === true &&  <SkeletonButton/>}

                    {(this.state.activeFilter === 'assets') &&
                    <ViewportBlock
                      onEnterViewport={() => this.handleMoreClick()}
                      data={this.state.pictures}
                      categoryParams={this.state.illustrationsParams}
                    />
                    }

                    {(this.state.activeFilter === 'packs') &&
                    <ViewportBlock
                      text="packs"
                      onEnterViewport={() => this.handleMoreClick()}
                      data={this.state.packs}
                      categoryParams={this.state.packsParams}
                    />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {hasSubscribe !== null && !hasSubscribe &&
          <ReadyTo />
        }

        <ScrollUp
          reveal={this.state.showScrollUp}
          scrollAction={scrollToTopSmooth}
        />

        <div id="stop" style={{border: '0.1px solid transparent'}}>
          {/*<BeforeFooter />*/}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  autoRenewal: state['user'].autoRenewal,
  hasSubscribe: state['user'].hasSubscribe,
  endDate: state['user'].endDate,
  moods: state['browse'].moods,
  types: state['browse'].types,
  categories: state['browse'].categories,
});

const mapDispatchToProps = dispatch => ({
  showModalPlans: () => dispatch({ type: 'modals/modalPlansActive', payload: true }),
});

const BrowseRouter = withRouter(Browse);
export default connect(mapStateToProps, mapDispatchToProps)(BrowseRouter);

