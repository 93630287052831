import axios from "axios";

const initialState = {
    moods: [],
    types : [],
    categories : [],
}

export default function browseReducer(state = initialState, action) {
    switch (action.type) {
        case 'browse/moodsLoad': {

            return {
                ...state,
                moods: action.payload,
            }
        }
        case 'browse/typesLoad': {

            return {
                ...state,
                types: action.payload,
            }
        }
        case 'browse/categoriesLoad': {

            return {
                ...state,
                categories: action.payload,
            }
        }
        default:
            return state
    }
}

// Thunk function
export async function fetchMoodsData(dispatch, _getState) {
     await axios.get(`/api/mood`).then((response) => {
        const {success, result} = response.data;
        const {moodsData} = result;

        if(success) {
            dispatch({ type: 'browse/moodsLoad', payload: moodsData})
        }
    })
      .catch((error) => {
          console.log(error)
      })
}

// Thunk function
export async function fetchTypesData(dispatch, _getState) {
    await axios.get(`/api/type`).then((response) => {
        const {success, result} = response.data;
        const {typesData} = result;

        if(success) {
            dispatch({ type: 'browse/typesLoad', payload: typesData})
        }
    })
      .catch((error) => {
          console.log(error)
      })
}

// Thunk function
export async function fetchCategoriesData(dispatch, _getState) {
    await axios.get(`/api/category`).then((response) => {
        const {success, result} = response.data;
        const {categoriesData} = result;

        if(success) {
            dispatch({ type: 'browse/categoriesLoad', payload: categoriesData})
        }
    })
      .catch((error) => {
          console.log(error)
      })
}

