import React from "react";
import PropTypes from "prop-types";

const Picture = ({
  standart, 
  big, 
  alt, 
  picture_class, 
  img_class
}) => {
  return (
    <picture className={picture_class}>
      <source srcSet={`${standart} 1x, ${big} 2x`}/>
      <img className={img_class} src={standart} alt={alt}/>
    </picture>
  );
}

Picture.propTypes = {
  standart:       PropTypes.string.isRequired,
  big:            PropTypes.string.isRequired,
  alt:            PropTypes.string,
  picture_class:  PropTypes.string,
  img_class:      PropTypes.string,
};

Picture.defaultProps = {
  alt:            'picture',
  picture_class:  '',
  img_class:      '', 
}

export default Picture;
