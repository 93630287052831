import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Footer from './components/Footer';
import Header from './components/Header';
import SubscriptionPlanCard from './components/SubscriptionPlanCard';
import { setPagesHistory } from './lib/domainPagesHistory';

import { connect } from 'react-redux';

//Common js
import { postFormData } from './lib/apiRequests';
import { openNotificationWithIcon } from './lib/notifications';

import './Plans.css';
import Skeleton from '@material-ui/lab/Skeleton';
import {Helmet} from "react-helmet";

import envs from './lib/envs';
import ProductHuntBage from "./components/ProductHuntBadge";
import CompaniesLogos from "./components/CompaniesLogos";
import FaqSection from "./components/FaqSection";

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

const firstImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/01.svg';
const secondImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/02.svg';
const thirdImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/03.svg';
const fourthImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/04.svg';
const fifthImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/05.svg';
const sixthImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/06.svg';
const seventhImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/07.svg';
const eighthImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/08.svg';
const ninthImage = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/plans/09.svg';


const mapDispatchToProps = dispatch => ({
  showModalLogin: () => dispatch({ type: 'modals/modalLoginActive', payload: true }),
  showModalCheckout: () => dispatch({ type: 'modals/modalCheckoutActive', payload: true }),
  orderChoosed: (order) => dispatch({ type: 'modals/orderChoosen', payload: order})
});
const oldPrices = [19, 29, 39];

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      choosed_plans: [
        parseInt(process.env.REACT_APP_PLAN_ID_FIRST) || null,
        parseInt(process.env.REACT_APP_PLAN_ID_SECOND) || null,
        parseInt(process.env.REACT_APP_PLAN_ID_THIRD) || null
      ],
      filtered_plans: [],
      isLoading: false,
      cardButtonsDisabled: false,
      responseData: {
        success: false,
        isLoading: true,
        message: 'Something went wrong. Please try again later'
      }
    }
  }

  componentDidMount() {
    axios
      .get(`/api/subscriptionPlan`)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          this.setState({
            plans: response.data.result.subscriptionPlans,
            isLoading: true,
          });
        }
      }).catch(function (error) {
      console.log(error);
    });

    setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
  }

  goAccount = (id) => {
    let formData = new FormData();

    formData.append('orderPositions[1][id]', id);
    formData.append('orderPositions[1][type]', 'subscription_plan');
    formData.append('orderPositions[1][count]', 1);

    this.setState({
      cardButtonsDisabled: true,
    });

    postFormData('/api/order/create', formData)
      .then(data => {
        if (data.success) {
          this.setState(prevState => ({
            ...prevState,
            responseData: {
              ...prevState.responseData,
              success: data.success,
              isLoading: false,
              order: data.result.order.id
            },
            cardButtonsDisabled: false,
          }));
          this.props.orderChoosed(data.result.order);
          this.props.showModalCheckout();
        } else {
          this.setState(prevState => ({
            ...prevState,
            responseData: {
              ...prevState.responseData,
              success: data.success,
              isLoading: false,
              message: data.message
            },
            cardButtonsDisabled: false,
          }));
          openNotificationWithIcon('error', 'Error', this.state.responseData.message);
        }
      })
      .catch(err => {
        this.setState(prevState => ({
          ...prevState,
          responseData: {
            ...prevState.responseData,
            success: false,
            isLoading: false,
            message: err.message
          },
          cardButtonsDisabled: false,
        }));
        if (err.message === 'Request failed with status code 401') {
          this.props.showModalLogin();
        } else {
          openNotificationWithIcon('error', 'Error', err.message);
        }
      })
  };

  render() {
    const { plans, isLoading, cardButtonsDisabled, choosed_plans } = this.state;
    const { history } = this.props;
    const oldPrices = [19, 29, 39];

    function renderCards() {
      const items = [];
      for (let x = 0; x <= 2; x++) {
        items.push(
          <Skeleton variant="rect" width="100%" key={x} animation="wave">
            <span style={{display: 'block', paddingTop: '84%'}}/>
          </Skeleton>)
      }
      return (<>{items}</>);
    }

    return (
      <div className="plans-page">
        <Header />
        <Helmet>
          <title>Storytale Pricing</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <section className="plans">
          <div className="container-custom">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center">Pricing</h1>
                {/* <div className="black-friday-text">                 
                  <p>-50% for all subscription plans during Black Friday Sale</p>
                  <p>Join Storytale by 1 December</p>
                </div> */}
                <div className="outer-container">
                  <div className="position-relative">
                    <img src={firstImage}   alt="" className="position-absolute element-01"/>
                    <img src={secondImage}  alt="" className="position-absolute element-02"/>
                    <img src={thirdImage}   alt="" className="position-absolute element-03"/>
                    <img src={fourthImage}  alt="" className="position-absolute element-04"/>
                    <img src={fifthImage}   alt="" className="position-absolute element-05"/>
                    <img src={sixthImage}   alt="" className="position-absolute element-06"/>
                    <img src={seventhImage} alt="" className="position-absolute element-07"/>
                    <img src={eighthImage}  alt="" className="position-absolute element-08"/>
                    <img src={ninthImage}   alt="" className="position-absolute element-09"/>
                  </div>
                  <div className="wrapper-container">
                    {isLoading === false && renderCards()}
                      {choosed_plans.map((item, index) => {
                        let btnType = 'outline blue';
                        let cardClass = 'SubscriptionPlanCard_big';
                        btnType = ((index-1)%3 === 0) ? 'secondary' : 'outline blue';
                        cardClass += ((index-1)%3 === 0) ? ' SubscriptionPlanCard_yellow' : ' SubscriptionPlanCard_big';
                        // const oldprice = oldPrices[index];
                        return (
                          plans.filter(plan => plan.id === item).map(plan => {
                            return (
                              <SubscriptionPlanCard
                                key={index}
                                id={plan.id}
                                name={plan.name}
                                price={plan.price}
                                // oldprice={oldprice}
                                duration={plan.durationLabel}
                                durationCount={plan.durationCount}
                                chargePeriodCount={plan.chargePeriodCount}
                                chargePeriodLabel={plan.chargePeriodLabel}
                                downloadsPM={plan.downloadLimit}
                                description={plan.description}
                                cardClass={cardClass}
                                buttonType={btnType}
                                handleClick={() => { this.goAccount(plan.id)}}
                                buttonDisabled={cardButtonsDisabled}
                                simple={true}
                              />
                            )
                          })
                        )
                      })}
                  </div>
                  <div className="text-center info">Renew, switch and cancel plan any time. VAT rate maybe charged, following your country law</div>
                  <div style={{marginTop: "20px"}}>
                    <ProductHuntBage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CompaniesLogos title="Trusted by great companies" />
        <FaqSection
          LeftCollumnTitles={['How can I use Storytale assets?', 'What is a Team plan?']}
          LeftCollumnText={[
            'You can use assets both for personal and commercial projects. Example: client’s work, dribbble shots, personal website, social media account, advertising, physical items, etc.',
            'Team plan is required if you are going to use assets in your team that means 2 or more users.'
          ]}
          RightCollumnTitles={['Can I cancel my subscription?', 'Can I use assets after my subscription expires?']}
          RightCollumnText={[
            'Yes, you can cancel your subscription anytime. You will still have access to assets till your subscription period ends.',
            'Yes, even after your subscription expires, you can use the assets you downloaded during your subscription period.'
          ]}
        />
        <Footer bordered={true}/>
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Plans));
