function createCrisp() {
  window.$crisp=[];
  window.CRISP_WEBSITE_ID=process.env.REACT_APP_CRISP_WEBSITE_ID;
  (function(){
    const d=document;
    const s=d.createElement("script");
    s.src="https://client.crisp.chat/l.js";
    s.async=1;
    s.id="crispScript";
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
}

function showCrisp() {
  const crispClient = document.querySelector(".crisp-client");
  if (crispClient) {
    crispClient.style.display = 'block';
  } else {
    createCrisp();
  }
}

function hideCrisp() {
  const crispClient = document.querySelector(".crisp-client");
  if (crispClient) {
    crispClient.style.display = 'none';
  }
}

export { showCrisp, hideCrisp };
