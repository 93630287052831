import React from "react";
import "./BrowseByCard.css";
import { Link } from "react-router-dom";

function BrowseByCard({classes, link, imageSrc, imageAlt, description}) {


  return (
    <Link className={`browse-by-card ${classes}`} to={link}>
      <img src={imageSrc} alt={imageAlt}/>
      <p className="description">{description}</p>
    </Link>
  );
}

export default BrowseByCard;
