import React from "react";
import { Fragment } from 'react';

import "./AccountDelimiter.scss";

export default function AccountDelimiter(props) {
  return (
    <Fragment>
      <div className="AccountDelimiter">
        <p className="AccountDelimiter__text">{props.text}</p>
      </div>
    </Fragment>
  );
}
