import './AccountForm.scss';

import AccountDelimiter from './AccountDelimiter';
import AccountSignLink from './AccountSignLink';
import SignBlock from './SignBlock';
import ErrorField from './ErrorField';

//Icons
import { UsernameFromIcon, EmailFormIcon, PasswordFormIcon } from './Icons';

import { Form, Input, Button } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Common js
import {checkAuthorization, getActiveSubscriptionInfo} from "../features/user/userSlice";
import {eraseCookie, setCookie} from "../lib/cookies";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function RegisterFrom (props) {
  const [backError, setBackError] = useState({
    message: 'Unknown Error',
    show: false,
  });
  const dispatch = useDispatch();

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('name', values.username);
    formData.append('password', values.password);
    formData.append('email', values.email);

    axios.post(`/api/user/registration`, formData)
      .then(response => {
        if (response.data.success === true) {
          dispatch(checkAuthorization);
          dispatch(getActiveSubscriptionInfo);
          setCookie('dropUser', 'false', 100, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
          eraseCookie('dropUser');
          props.onSubmit();
        } else {
          setBackError({
            message: response.data.message,
            show: true,
          });
        }
      })
      .catch(function (error) {
        setBackError({
          message: error.message,
          show: true,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Form
      {...layout}
      name="registerForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="AccountForm"
    >
      <SignBlock />

      <AccountDelimiter text="or" />

      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input placeholder="Username" prefix={<UsernameFromIcon />} />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input placeholder="Email" prefix={<EmailFormIcon />} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password placeholder="Password" prefix={<PasswordFormIcon />} />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm password" prefix={<PasswordFormIcon />} />
      </Form.Item>

      { backError.show === true &&
        <ErrorField error_message={backError.message} show={backError.show}/>
      }

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>

      <AccountSignLink text="Already have an account?" linktext="Login" linkroute="/login" onClick={props.onClick} />
    </Form>
  );
};
