import React from "react";
import "./PackCard.scss";
import * as PropTypes from 'prop-types';
import {Link} from "react-router-dom";

const IMG_HEIGHTS = [
    {screenMaxWidth: '575px', height: 409},
    {screenMaxWidth: '767px', height: 180},
    {screenMaxWidth: '991px', height: 248},
    {screenMaxWidth: '1269px', height: 338},
    {screenMaxWidth: '1509px', height: 278},
    {screenMaxWidth: '1599px', height: 350},
    {screenMinWidth: '1600px', height: 358}
  ];

function PackCard({packData, onClick = () => {}}) {
  const {
    assets,
    name,
    id,
    cover,
    illustrationCount,
    category='assets',
    shortDescription,
    commercialPrice,
    extendedPrice,
    commercialPriceDiscounted,
  } = packData;

  return(
    <article className="pack-card" onClick={() => onClick(id)}>
      <Link to={`/pack/${id}`} className="pack-card__link">
        <div className="pack-card__tile">
          <div className="pack-card__picture-container">
            {assets &&
              <picture className="pack-card__picture">
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[0]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[0]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[0]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[1]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[1]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[1]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[2]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[2]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[2]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[3]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[3]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[3]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[4]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[4]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[4]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[5]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[5]['height'] * 2} 2x`} media={`(max-width: ${IMG_HEIGHTS[5]['screenMaxWidth']})`} />
                <source srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[6]['height']} 1x, ${assets[0]?.cover}?height=${IMG_HEIGHTS[6]['height'] * 2} 2x`} media={`(min-width: ${IMG_HEIGHTS[6]['screenMinWidth']})`} />
                <img
                  className="pack-card__image"
                  alt={name}
                  src={`${assets[0]?.cover}?height=${IMG_HEIGHTS[6]['height']}`}
                  srcSet={`${assets[0]?.cover}?height=${IMG_HEIGHTS[6]['height'] * 2} 2x`}
                />
              </picture>
            }
          </div>
          <div className="pack-card__tile-shade pack-card__tile-shade_gray" />
        </div>

        <div className="pack-card__assets-container">
          {assets && createAssetsArray(assets).map((asset, index) => {
            return (
              <AssetElement
                key={index}
                asset={asset}
              />
            )
          })}

        </div>
      </Link>

      <section className="pack-card__about">
        <Link to={`/pack/${id}`} className="pack-card__thumbnail-container">
          <img className="pack-card__thumbnail" src={`${cover}?height=112px`} alt={name}/>
        </Link>

        <div className="pack-card__texts-container">
          <Link to={`/pack/${id}`}>
            <h5 className="pack-card__title">{name}</h5>
          </Link>
          <p className="pack-card__text">{shortDescription}</p>
          <header className="pack-card__header">
            <span className="pack-card__header-text">{illustrationCount}&nbsp;{category}</span>
            {priceElement(commercialPrice, extendedPrice, commercialPriceDiscounted)}
          </header>
        </div>
      </section>
    </article>
  );
}

const priceElement = (commercialPrice, extendedPrice, commercialPriceDiscounted) => {
  if (commercialPrice === 0) {
    return (
      <span className="pack-card__price_free">Free</span>
    );
  }
  if (commercialPriceDiscounted) {
    return (
      <>
        <span className="pack-card__price_before-discount">${commercialPrice}</span>
        <span className="pack-card__price_discounted">{extendedPrice && 'From '}${commercialPriceDiscounted}</span>
      </>
    );
  }
  if (commercialPrice) {
    return (
      <span className="pack-card__price_commercial">{extendedPrice && 'From '}${commercialPrice}</span>
    );
  }
  return null;
};

const createAssetsArray = (assets) => {
  const emptyArr = new Array(4);
  emptyArr.fill({});
  const updatedAssets = assets.concat(emptyArr);
  return updatedAssets.slice(1,4);
}

const AssetElement = ({asset}) => {
  return (
    <div className="pack-card__asset">
      <div className="pack-card__asset-container">
        {asset?.cover &&
          <img
            className="pack-card__image"
            src={`${asset?.cover}?height=160px`}
            srcSet={`${asset?.cover}?height=320px 2x`}
          />
        }
      </div>
      <div className="pack-card__tile-shade pack-card__tile-shade_gray" />
    </div>
  )
}

PackCard.propTypes = {
  packData: PropTypes.shape({
      assets: PropTypes.array,
      onClick: PropTypes.func,
      name: PropTypes.string,
      id: PropTypes.number,
      cover: PropTypes.string,
      illustrationCount: PropTypes.number,
      category: PropTypes.string,
      shortDescription: PropTypes.string,
      commercialPrice: PropTypes.number,
      extendedPrice: PropTypes.number,
      commercialPriceDiscounted: PropTypes.number,
  })
};

export default PackCard;
