import React from "react";
import "./IllustrationsList.css"
import Product from "./Product";
import Skeleton from '@material-ui/lab/Skeleton';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function IllustrationsList(props) {
    const {
        pictures,
        onClick,
        customerActivities,
        containerType = "col4",
        downloadErrorHandler,
        getCustomerActivities,
        maxElements = 12,
        picturesLikesCounts,
        changeLikesCountsLocally,
    } = props;

    function renderCards(value) {
        const items = [];

        for (let x = 0; x < value; x++) {
            items.push(
                <Skeleton variant="rect" width="100%" key={x} animation="wave">
                    <span style={{display: 'block', paddingTop: '75%'}}/>
                </Skeleton>)
        }

        return (
            <>
                {items}
            </>
        );
    }

    return (
        <>
            <div className={`illustrations-list illustrations-list-${containerType}`}>
                {props.status ? renderCards(maxElements) :
                    <>
                        {pictures.length > 0
                            ? pictures.map((picture, index) => {
                                return (
                                    <LazyLoadComponent threshold={300} key={index}>
                                        <Product
                                            id={picture.id}
                                            cover={picture.cover}
                                            assetName={picture.name}
                                            packName={picture.pack.name}
                                            isFree={picture.isFree}
                                            likeCount={picturesLikesCounts ? picturesLikesCounts[picture.id] : null}
                                            onClick={onClick}
                                            customerActivities={customerActivities}
                                            containerType={containerType}
                                            downloadErrorHandler={downloadErrorHandler}
                                            getCustomerActivities={getCustomerActivities}
                                            changeLikesCountsLocally={changeLikesCountsLocally}
                                        />
                                    </LazyLoadComponent>
                                );
                            })
                            :
                            null
                        }
                    </>
                }
            </div>
        </>
    );
}

export default IllustrationsList;

