import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import queryString from 'query-string';

import EmptyPageFiller from './components/EmptyPageFiller';
import Header from './components/Header';
import Footer from './components/Footer';

import { postFormData } from './lib/apiRequests';

import './NewsletterUnsubscribe.css';

const StandartNoSubscriptions = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/NoSubscriptions.png";
const BigNoSubscriptions = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/NoSubscriptions@2x.png";

const NewsletterUnsubscribe = (props) => {
  const { search } = useLocation();

  useEffect(() => {
    let formData = new FormData();
    const parsedSearchQuery = queryString.parse(search);

    formData.append('newsletterSubscriptionUuid', parsedSearchQuery.newsletterSubscriptionUuid);

    postFormData('/api/newsletter/unsubscribe', formData)
      .then(data => {
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      })
  });

  return (
    <div className='Unsubscribe'>
      <Header />
      <Helmet>
        <title>Storytale Newsletter Unsubscribe</title>
      </Helmet>
      <EmptyPageFiller
        text='You’ve unsubscribed from Storytale newsletter'
        StandartImage={StandartNoSubscriptions}
        BigImage={BigNoSubscriptions}
        linkText='Go to Storytale'
        linkPath='/'
        buttonType='secondary'
        showIcon={false}
      />
      <Footer bordered={true}/>
    </div>
  );
};

export default NewsletterUnsubscribe;
