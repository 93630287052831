import React from "react";
import "./ButtonIcon.css";


function ButtonIcon(props) {
  const {classes, isLink=false, callback, href=null, icon, content, type="button", isEmulate=false} = props;

  let result;

  if(isLink && isEmulate === false) {
    result = (
      <a href={href} onClick={callback} className={`button-icon ${classes}`}>
          <span>
            {icon}
          </span>
        <span>
          {content}
        </span>
      </a>
    );
  } else if (isLink === false && isEmulate === false) {
    result = (
      <button type={type} onClick={callback} className={`button-icon ${classes}`}>
          <span>
            {icon}
          </span>
        <span>
          {content}
        </span>
      </button>
    );
  } else if (isEmulate) {
    result = (
      <div onClick={callback} className={`button-icon ${classes}`}>
          <span>
            {icon}
          </span>
        <span>
          {content}
        </span>
      </div>
    )
  }

  return (
    result
  );
}

export default ButtonIcon;
