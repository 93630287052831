import axios from "axios";
import addPictureLikeCounts from "./addPictureLikeCounts";

const qs = require('qs');
const getIllustrationInfo = async (props, id, callback, getCustomerActivities) => {

  //clear loading indicators and state

  props.setState(
    prevState => {

      let illustrationsFromKeywordsParams = Object.assign({}, prevState.illustrationsFromKeywordsParams);
      illustrationsFromKeywordsParams.searchText = "";
      illustrationsFromKeywordsParams.page = 1;

      let illustrationsFromPackParams = Object.assign({}, prevState.illustrationsFromPackParams);
      illustrationsFromPackParams.packId = "";
      illustrationsFromPackParams.page = 1;

      return {
        illustrationsFromKeywordsParams,
        illustrationsFromPackParams,
        isLoadingKeywordsIllustrations: true,
        isLoadingPackIllustrations: true,
        isLoadingInfoData: true,
        isLoadingPackInfo: true,
        illustrationsFromKeywords: [],
        illustrationsFromPack : []
      };
    }
  );


  //get more information from current illustration
  const responseIllustration = await axios.get(
    `/api/illustration/${id}`
  );

  const {illustrationData} = await responseIllustration.data.result;
  const {keywords = []} = illustrationData;
  const packId = illustrationData.pack.id;

  try {
    let updArray =  keywords.join(' ').trim();

    if(updArray.length > 0 ) {

      props.setState(prevState => {
        let illustrationsFromKeywordsParams = Object.assign({}, prevState.illustrationsFromKeywordsParams);
        illustrationsFromKeywordsParams.searchText = updArray;
        illustrationsFromKeywordsParams.exclude = {packIds : [packId]};

        return {
          illustrationsFromKeywordsParams,
        };
      }, () => {

        const {illustrationsFromKeywordsParams} = props.state;

        axios
          .get(`/api/illustration`, {
            params: {...illustrationsFromKeywordsParams},
            paramsSerializer: function(params) {
              return qs.stringify(params, {arrayFormat: 'brackets', encode: false})
            },
          })
          .then(response => {
            const {illustrationData} = response.data.result;
            const {success} = response.data;

            try {
              if (success && illustrationData !== null) {
                const updatedPicturesLikesCounts = addPictureLikeCounts(props.state.picturesLikesCounts, illustrationData);
                props.setState(
                  prevState => {
                    return {
                      illustrationsFromKeywords: illustrationData,
                      isLoadingKeywordsIllustrations: false,
                      picturesLikesCounts: updatedPicturesLikesCounts,
                    };
                  }, () => getCustomerActivities(props.state.illustrationsFromKeywords)
                );
              }
            } catch (err) {
              console.log(err);
            }
          }).catch(function (error) {
          console.log(error);
        });
      });
    }
  }  catch (err) {
    console.log(err);
  }

  try {
    if (responseIllustration.data.success === true && (illustrationData !== null)) {
      window.history.pushState('', '',`/illustration/${id}`);

      props.setState(
        prevState => {

          return {
            currentIllustrationInfo: illustrationData,
            showModalInfo: true,
            isLoadingInfoData: false
          };
        }, () => {
            callback()
        }
      );
    }
  } catch (err) {
    console.log(err);
  }

  //current pack information
  try {
    if(packId && packId !== '') {
      const responsePackInfo = await axios.get(
        `/api/pack/${packId}`
      );

      const {packData} = await responsePackInfo.data.result;

      try {
        if (responsePackInfo.data.success === true && (packData !== null)) {
          props.setState(
            prevState => {
              return {
                currentIllustrationPackData: packData,
                isLoadingPackInfo: false,
              };
            },
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  } catch (err) {
    console.log(err);
  }

  //get illustrations from pack
  try {
    if(packId && packId !== '') {
      props.setState(prevState => {
        let illustrationsFromPackParams = Object.assign({}, prevState.illustrationsFromPackParams);
        illustrationsFromPackParams.packId = packId;

        return {
          illustrationsFromPackParams,
        };
      }, () => {

        const {illustrationsFromPackParams} = props.state;

        let search = {
          params: illustrationsFromPackParams
        };

        axios
          .get(`/api/illustration`, search)
          .then(response => {
            const {illustrationData} = response.data.result;
            const {success} = response.data;

            try {
              if (success && illustrationData !== null) {
                const updatedPicturesLikesCounts = addPictureLikeCounts(props.state.picturesLikesCounts, illustrationData);
                props.setState(
                  prevState => {
                    return {
                      illustrationsFromPack: illustrationData,
                      isLoadingPackIllustrations: false,
                      picturesLikesCounts: updatedPicturesLikesCounts,
                    };
                  }, () => getCustomerActivities(props.state.illustrationsFromPack)
                );
              }
            } catch (err) {
              console.log(err);
            }
          }).catch(function (error) {
          console.log(error);
        });
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export default getIllustrationInfo;
