import React from "react";
import Modal from "react-bootstrap/Modal";

import '../common_styles/buttons.scss'
import "./Modal.scss"
import {CloseElement, StorytaleLogoSlim} from "./Icons";
import AccountForm from "./AccountForm";

import {useSelector, useDispatch} from 'react-redux'

const image = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/authLoginTinified.png";

const ModalAuthLogin = () => {
  const dispatch = useDispatch();

  const modalLoginActive = useSelector((state) => state.modals.modalLoginActive);

  const handleClose = () => dispatch({ type: 'modals/modalLoginActive', payload: false });
  const handleCloseCustom  = () => {
    dispatch({ type: 'modals/modalLoginActive', payload: false });
    dispatch({ type: 'modals/modalRegisterActive', payload: true });
  };

  return (
    <Modal
      animation={true}
      show={modalLoginActive}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-auth modal-dialog-centered "
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
        <span className="close-button" onClick={handleClose}>
          <CloseElement/>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="wrapper">
          <div className="left">
            <div className="custom-title">Sign in to continue</div>
            <AccountForm onSubmit={handleClose} onClick={handleCloseCustom}/>
          </div>
          <div className="right">
            <div className="logo">
              <StorytaleLogoSlim/>
            </div>
            <img src={image} srcSet={image} alt="login"/>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};
export default ModalAuthLogin;
