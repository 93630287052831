import Stack from './Stack';
import { setCookie, getCookie } from './cookies';

export function setPagesHistory(origin, location_pathname = '', days = 7, domain) {
  let stack = new Stack(15);
  let pages_from = getCookie('pageFrom') ? getCookie('pageFrom') : '';
  let history_array = pages_from.length ? pages_from.split('>>') : [];
  
  history_array.forEach(url => { stack.push(url) });

  stack.push(origin + location_pathname);

  let stack_array = stack.get();

  setCookie('pageFrom', stack_array.join('>>'), days, domain);
}

export function getHistoryPage(number) {
  let stack = new Stack(15);
  let pages_from = getCookie('pageFrom') ? getCookie('pageFrom') : '';
  let history_array = pages_from.length ? pages_from.split('>>') : [];
  
  history_array.forEach(url => { stack.push(url) });

  return stack.getElement(number);
}
