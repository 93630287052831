import React, { Component }  from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, Checkbox, Switch, Divider } from 'antd';
import { ArrowDownBig } from './Icons';
import './FilterDropdown.scss';

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      typeVectorSwitched: (this.props.typeId === '1'),
      freeOnlySwitched: this.props.isFree,
      checkedCategories: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.typeId !== this.props.typeId) {
      this.setState({
        typeVectorSwitched: (this.props.typeId === '1'),
      })
    }
    if (prevProps.isFree !== this.props.isFree) {
      this.setState({
        freeOnlySwitched: this.props.isFree,
      })
    }
  }

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  handleTypeSwitch = typeVectorSwitched => {
    const { handleTypeSwitchClick, types } = this.props;
    if (typeVectorSwitched) {
      handleTypeSwitchClick(String(types[0]?.id));
    } else {
      handleTypeSwitchClick(null);
    }
  };

  handleFreeOnlySwitch = freeOnlySwitched => {
    const { handleFreeSwitchClick } = this.props;
    if (freeOnlySwitched === true) {
      handleFreeSwitchClick('true');
    } else {
      handleFreeSwitchClick(null);
    }
  };

  render() {
    const { checkboxes, types, isFree, children} = this.props;
    const { typeVectorSwitched, freeOnlySwitched } = this.state;

    const dropdownInner = (
      <div
        className='filter-dropdown__container'
      >
        {children}
        {checkboxes && Array.isArray(checkboxes) && checkboxes.length > 0 &&
          <>
            <Checkbox
              onChange={e => { this.handleCheckAll(e?.target?.checked) }}
              className='filter-dropdown__checkbox'
            >
              All
            </Checkbox>
            <Divider className='filter-dropdown__divider' />
            <Checkbox.Group
              onChange={() => {}}
              className='filter-dropdown__checkbox-group'
            >
              {checkboxes.map((item) =>
                <Checkbox
                  key={uuidv4()}
                  value={item?.id}
                  className='filter-dropdown__checkbox'
                >
                  {item?.name}
                </Checkbox>
              )}
            </Checkbox.Group>
            <Divider className='filter-dropdown__divider' />
          </>
        }

        {types && Array.isArray(types) && types.length === 2 &&
        <div className='filter-dropdown__switch'>
          <label
            className='filter-dropdown__switch-label'
            htmlFor='filter-switch-type'
          >
            {types[0]?.name}
          </label>
          <Switch
            className='filter-dropdown__switch-button'
            onChange={this.handleTypeSwitch}
            id='filter-switch-type'
            checked={typeVectorSwitched}
          />
        </div>
        }

        {isFree !== undefined &&
        <div className='filter-dropdown__switch'>
          <label
            className='filter-dropdown__switch-label'
            htmlFor='filter-switch-freeOnly'
          >
            Free
          </label>
          <Switch
            className='filter-dropdown__switch-button'
            onChange={this.handleFreeOnlySwitch}
            id='filter-switch-freeOnly'
            checked={freeOnlySwitched}
          />
        </div>
        }
      </div>
    );

    return (
      <Dropdown
        overlay={dropdownInner}
        trigger={['click']}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
      >
        <button
          className='filter-dropdown__button'
        >
          Filter
          <ArrowDownBig />
        </button>
      </Dropdown>
    );
  }
}

export default FilterDropdown;
