import React from "react";
import PropTypes from 'prop-types';
import "./AdvantageCard.scss";

function AdvantageCard({ title, description, illustration, descriptionWidth }) {
  return (
    <article className="advantage-card">
      <img
        className="advantage-card__illustration"
        src={illustration}
        alt={title}
      />
      <h3 className="advantage-card__title">
        {title}
      </h3>
      <p className={`advantage-card__description advantage-card__description_${descriptionWidth}`}>
        {description}
      </p>
    </article>
  );
}

AdvantageCard.propTypes = {
  title:                PropTypes.string,
  description:          PropTypes.string,
  illustration:         PropTypes.string.isRequired,
};

AdvantageCard.defaultProps = {
  title:                'Title',
  description:          'Description',
};

export default AdvantageCard;
