import React from "react";

import "./ButtonBase.css";

function ButtonBase(props) {
  const {
    classes,
    isLink = false,
    callback,
    href = null,
    content,
    icon = null,
    icon_position = "right",
    type = "button",
    isEmulate = false,
  } = props;

  let result;

  if (isLink && isEmulate === false) {
    result = (
      <a href={href} onClick={callback} className={`button-base ${classes}`}>
        {icon !== null ? (
          icon_position === "left" ? (
            <>
              <span>{icon}</span>
              <span>{content}</span>
            </>
          ) : (
            <>
              <span>{content}</span>
              <span>{icon}</span>
            </>
          )
        ) : (
          content
        )}
      </a>
    );
  } else if (isLink === false && isEmulate === false) {
    result = (
      <button
        type={type}
        onClick={callback}
        className={`button-base ${classes}`}
      >
        {icon !== null ? (
          icon_position === "left" ? (
            <>
              <span>{icon}</span>
              <span>{content}</span>
            </>
          ) : (
            <>
              <span>{content}</span>
              <span>{icon}</span>
            </>
          )
        ) : (
          content
        )}
      </button>
    );
  } else if (isEmulate) {
    result = (
      <div onClick={callback} className={`button-base ${classes}`}>
        {icon !== null ? (
          icon_position === "left" ? (
            <>
              <span>{icon}</span>
              <span>{content}</span>
            </>
          ) : (
            <>
              <span>{content}</span>
              <span>{icon}</span>
            </>
          )
        ) : (
          content
        )}
      </div>
    );
  }

  return result;
}

export default ButtonBase;
