import React from "react";
import "./CustomIllustrationsCard.css";
import {Link} from "react-router-dom";
import ButtonBase from "./ButtonBase";

function CustomIllustrationsCard(props) {
  return (
    <div className="wrapper_custom_illustrations">
      <div className="first_block">
        <span className="block-title">Didn’t find what you were looking for?</span>
        <Link className="link" to={`/order-custom`}>
          <ButtonBase
            isEmulate={true}
            classes="btn-light-blue btn-custom"
            content="Order custom design"
          />
        </Link>
      </div>


      <img
        className="icon"
        src="/images/custom-illustrations-icon.png"
        alt=""
        width={280}
        height={208}
      />
    </div>
  );
}

export default CustomIllustrationsCard;
