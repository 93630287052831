import React from "react";

import { Switch, Route} from "react-router-dom";
import BrowseRouter from "./Browse";
import SingleIllustration from "./SingleIllustration";
import Pack from "./Pack";
import Main from "./Main";
import Plans from "./Plans";
import NotFound from "./NotFound";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import LicenseAgreement from "./LicenseAgreement";
import TermsOfUse from "./TermsOfUse";
import RefundPolicy from "./RefundPolicy";
import CookiesPolicy from "./CookiesPolicy";
import Help from "./Help";
import OrderCustom from "./OrderCustom";
import AboutUs from "./AboutUs";
import NewsletterUnsubscribe from "./NewsletterUnsubscribe";
import ThankYou from "./ThankYou";

import ScrollToTop from "./ScrollToTop";
import {Helmet} from "react-helmet";
import EuclidCircularARegular from "./fonts/EuclidCircularA-Regular-WebXL.woff2";
import EuclidCircularAMedium from "./fonts/EuclidCircularA-Medium-WebXL.woff2";
import EuclidCircularASemiBold from "./fonts/EuclidCircularA-Semibold-WebXL.woff2";
import DmSansRegular from "./fonts/dmsans-regular.woff2";

import NotificationCookies from "./components/NotificationCookies";
import ModalAuthLogin from "./components/ModalAuthLogin";
import ModalAuthRegister from "./components/ModalAuthRegister";
import ModalPlans from "./components/ModalPlans";
import ModalCheckout from "./components/ModalCheckout";
import ErrorBoundary from "./components/ErrorBoundary";

const  OpenGraph = "https://storytale-public.b-cdn.net/static/assets/storytale-app/img/Open-Graph.png";

function App () {

  return (
    <>
      <Helmet>
        <link rel="preload" href={ EuclidCircularARegular } as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href={ EuclidCircularAMedium } as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href={ EuclidCircularASemiBold} as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href={ DmSansRegular} as="font" type="font/woff2" crossorigin="anonymous" />
        <meta property="og:site_name" content="Storytale" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta data-render-type="csr" property="og:title" content="Storytale — High-class web assets under simple subscription"/>
        <meta data-render-type="csr" property="og:description" content="Huge library of stylish assets that grows every week. You can get them all by yearly subscription and download any assets you want for commecial and personal projects."/>
        <meta data-render-type="csr" property="og:image" content={OpenGraph}/>
        <meta data-render-type="csr" property="og:image:type" content="image/png" />
      </Helmet>

      <NotificationCookies />
      <ModalAuthLogin />
      <ModalAuthRegister />
      <ModalPlans />
      <ModalCheckout />

      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <ErrorBoundary><Main /></ErrorBoundary>
          </Route>
          <Route exact path="/plans">
            <ErrorBoundary><Plans /></ErrorBoundary>
          </Route>
          <Route
            exact
            path="/browse/:search?/"
            render={({ match }) => (
              <ErrorBoundary><BrowseRouter  search={match.params.search}/></ErrorBoundary>
            )}
          />
          <Route
            exact
            path="/illustration/:id?"
            render={({ match }) => (
              <ErrorBoundary><SingleIllustration id={match.params.id}/></ErrorBoundary>
            )}
          />
          <Route
            exact
            path="/pack/:id?"
            render={({ match }) => <ErrorBoundary><Pack id={match.params.id}/></ErrorBoundary>}
          />
          <Route path='/contact-us' component={() =><ErrorBoundary><ContactUs/></ErrorBoundary>} />
          <Route path='/privacy-policy' component={() =><ErrorBoundary><PrivacyPolicy/></ErrorBoundary>} />
          <Route path='/license-agreement' component={() =><ErrorBoundary><LicenseAgreement/></ErrorBoundary>} />
          <Route path='/terms-of-use' component={() =><ErrorBoundary><TermsOfUse/></ErrorBoundary>} />
          <Route path='/refund-policy' component={() =><ErrorBoundary><RefundPolicy/></ErrorBoundary>} />
          <Route path='/cookies-policy' component={() =><ErrorBoundary><CookiesPolicy/></ErrorBoundary>} />
          <Route path='/help' component={() =><ErrorBoundary><Help/></ErrorBoundary>} />
          <Route path='/order-custom' component={() =><ErrorBoundary><OrderCustom/></ErrorBoundary>} />
          <Route path='/about-us' component={() =><ErrorBoundary><AboutUs/></ErrorBoundary>} />
          <Route path='/thankyou' component={ThankYou} />
          <Route exact path='/newsletter/unsubscribe' component={() =><ErrorBoundary><NewsletterUnsubscribe/></ErrorBoundary>} />
          <Route path="/error" component={NotFound}/>
          <Route component={NotFound}/>
        </Switch>
      </ScrollToTop>
    </>
  );
}

export default App;
