const initialState = {
  modalLoginActive: false,
  modalRegisterActive : false,
  modalPlansActive : false,
  modalCheckoutActive: false,
  orderChoosen: {},
}

export default function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case 'modals/modalLoginActive': {
      return {
        ...state,
        modalLoginActive: action.payload,
      }
    }
    case 'modals/modalRegisterActive': {
      return {
        ...state,
        modalRegisterActive: action.payload,
      }
    }
    case 'modals/modalPlansActive': {
      return {
        ...state,
        modalPlansActive: action.payload,
      }
    }
    case 'modals/modalCheckoutActive': {
      return {
        ...state,
        modalCheckoutActive: action.payload,
      }
    }
    case 'modals/orderChoosen': {
      return {
        ...state,
        orderChoosen: action.payload,
      }
    }
    default:
      return state
  }
}
