import React from "react";
import "./EmptyData.css";
import {Kebab} from "./Icons";

const image = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/empty.png"

function EmptyData(props) {
    return (
        <div className="empty-data">
            <img src={image} alt="" />
            <div className="description">Sorry, no products were found</div>
            <button className="button-icon outline black large" onClick={props.onClick} id="clear">
                <span>
                   <Kebab/>
                </span>
                <span>Browse all</span>
            </button>
        </div>
    );
}

export default EmptyData;
