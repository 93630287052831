import React from "react";
import PropTypes from "prop-types";
import "./FaqSection.css"

const FaqSection = ({
  LeftCollumnTitles,
  LeftCollumnText,
  RightCollumnTitles,
  RightCollumnText,
}) => {
  return (
    <section className="faq section">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className="wrapper">
              <div className="left">
                {LeftCollumnTitles.map((element, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="title">{element}</div>
                      <p className="description">{(LeftCollumnText[index] !== undefined) ? LeftCollumnText[index] : element}</p>
                    </div>
                  )
                })}
              </div>
              <div className="right">
                {RightCollumnTitles.map((element, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="title">{element}</div>
                      <p className="description">{(RightCollumnText[index] !== undefined) ? RightCollumnText[index] : element}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FaqSection.propTypes = {
  LeftCollumnTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  LeftCollumnText: PropTypes.arrayOf(PropTypes.string).isRequired,
  RightCollumnTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  RightCollumnText: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FaqSection;
