import { combineReducers } from 'redux'
import userReducer from "./features/user/userSlice";
import browseReducer from "./features/browse/browseSlice";
import plansReducer from "./features/plans/plansSlice";
import modalsReducer from "./features/modals/modalsSlice";

const rootReducer = combineReducers({
    user: userReducer,
    browse: browseReducer,
    plans: plansReducer,
    modals: modalsReducer,
})

export default rootReducer