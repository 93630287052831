import React, { Component } from "react";

//components
import BeforeFooter from "./components/BeforeFooter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ReadyTo from "./components/ReadyTo";
import ProductInfo from "./components/ProductInfo";
import ModalDownloadLimit from "./components/ModalDownloadLimit";
import ModalSubscriptionError from "./components/ModalSubscriptionError";
import ButtonIcon from "./components/ButtonIcon";
import PacksGrid from "./components/PacksGrid";

//styles
import "./SingleIllustration.css"

import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import {Avatar} from 'antd';
import {connect} from "react-redux";
import moment from "moment";
import {Helmet} from "react-helmet";

import envs from './lib/envs';

//lib
import { setPagesHistory } from './lib/domainPagesHistory';
import downloadErrorHandler from "./lib/downloadErrorHandler";
import changeIllustrationActivities from "./lib/changeIllustrationActivities";
import changePicturesLikesCountsLocally from "./lib/changePicturesLikesCountsLocally";
import getCustomerActivities from "./lib/getCustomerActivities";
import metaTagsDelete from "./lib/metaTagsDelete";
import loadMoreIllustrationsFrom from "./lib/loadMoreIllustrationsFrom";
import getIllustrationInfo from "./lib/getIllustrationInfo";
import BrowseByIllustrations from "./components/BrowseByIllustrations";
import axios from "axios";
import scrollToTop from "./lib/scrollToTop";
import {Escape} from "./components/Icons";

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;
const MAX_ELEMENTS = 12;
const MAX_PACKS = 6;

class SingleIllustration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      pictures: [],
      illustrationsFromPack: [],
      illustrationsFromKeywords: [],
      illustrationsFromKeywordsParams: {
        searchText: "",
        count: MAX_ELEMENTS,
        page: 1
      },
      illustrationsFromPackParams: {
        packId: "",
        count: MAX_ELEMENTS,
        sortBy: "3",
        page: 1
      },
      customerActivities: {},
      picturesLikesCounts: {},
      currentIllustrationInfo: null,
      currentIllustrationPackData: null,
      isLoading: true,
      isLoadingInfoData: true,
      isLoadingPackIllustrations: true,
      isLoadingKeywordsIllustrations: true,
      isLoadingPackInfo: true,
      packs: [],
      isPackIllustrationLoading: true,
      hasActiveSubscription : false,
      showModalDownloadLimits: false,
      showModalSubscriptionEnded: false,
      server: "",
    };

    this.handleIlustrationClick = this.handleIlustrationClick.bind(this);
    this.showModalDownloadLimits = this.showModalDownloadLimits.bind(this);
    this.hideModalDownloadLimits = this.hideModalDownloadLimits.bind(this);
    this.showModalSubscriptionEnded = this.showModalSubscriptionEnded.bind(this);
    this.hideModalSubscriptionEnded = this.hideModalSubscriptionEnded.bind(this);
    this.loadMoreIllustrationsFromWrapper = this.loadMoreIllustrationsFromWrapper.bind(this);

    this.getIllustrationInfoWrapper = this.getIllustrationInfoWrapper.bind(this);
    this.getCustomerActivitiesWrapper = this.getCustomerActivitiesWrapper.bind(this);
    this.changeIllustrationActivitiesWrapper = this.changeIllustrationActivitiesWrapper.bind(this);
    this.changePicturesLikesCountsLocallyWrapper = this.changePicturesLikesCountsLocallyWrapper.bind(this);
    this.downloadErrorHandlerWrapper = this.downloadErrorHandlerWrapper.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    scrollToTop();

    this.timerID = setTimeout(() => {
      if (window.__ROUTE_DATA__) {
        this.setState({
          server: window.__ROUTE_DATA__
        });
        delete window.__ROUTE_DATA__;
        clearTimeout(this.timerID);
        metaTagsDelete('meta[data-render-type="csr"]');
      } else {
        this.setState({
          server: null
        });
      }
    }, 0);

    axios
      .get(`/api/pack?&count=${MAX_PACKS}`)
      .then((response) => {
      if (response.data.success) {
        const {packsData} = response.data.result;

        this.setState({
          packs: packsData,
        });
      }

    })
      .catch((error) => {
        console.log(error);
      });

    this.getIllustrationInfoWrapper(this.props.id);
    setPagesHistory(window.location.href, '', 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
  }

  componentDidUpdate(prevProps) {

    if (this.props.id !== prevProps.id) {
      clearTimeout(this.timerID);
      this.setState({
        server: null
      });
    }

    if (this.state.server === null) {
      metaTagsDelete('meta[data-render-type="ssr"]');
    }

    if(prevProps.authorized !== this.props.authorized && this.props.authorized === true) {
      this.getCustomerActivitiesWrapper(this.state.pictures);
    }
  }

  changeIllustrationActivitiesWrapper = (id, type, dataName) => {
    const props = this;
    changeIllustrationActivities(props, id, type, dataName);
  };

  changePicturesLikesCountsLocallyWrapper = (id, actionTaken) => {
    const stateOwnerThis = this;
    changePicturesLikesCountsLocally(stateOwnerThis, id, actionTaken);
  };

  getCustomerActivitiesWrapper = (originalData, requestData) => {
    const props = this;
    getCustomerActivities(props, originalData, requestData);
  };


  downloadErrorHandlerWrapper = (errorCode) => {
    const {history, showModalPlans} = this.props;

    downloadErrorHandler(errorCode, history, this.showModalSubscriptionEnded, this.showModalDownloadLimits, showModalPlans);
  };

  loadMoreIllustrationsFromWrapper = () => {
    const props = this;
    loadMoreIllustrationsFrom(props);
  };


  handleIlustrationClick() {
    this.setState(prevState => {
      return {
        isLoading: true
      }
    }, () => this.callApi())
  }

  hideModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: false,
    });
  }

  showModalDownloadLimits() {
    this.setState({
      showModalDownloadLimits: true,
    });
  }

  hideModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: false,
    });
  }

  showModalSubscriptionEnded() {
    this.setState({
      showModalSubscriptionEnded: true,
    });
  }

  goAccount = () => {
    window.location.href = `/error`;
  };

  handleScroll = () => {
    window.scroll({top:0})
  };

  redirectTo(value) {
    const {history} = this.props;
    history.push(`/browse/?searchText=${value}`)
  }

  getIllustrationInfoWrapper = (id) => {
    const props = this;

    const  callback = () => setPagesHistory(window.location.origin, this.props.history.location.pathname, 30, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
    getIllustrationInfo(props, id, callback, this.getCustomerActivitiesWrapper);
  };

  render() {
    const {
      pictures,
      error,
      packs,
      currentIllustrationIndex,
      server,
      isLoadingPackInfo,
      isLoadingPackIllustrations,
      illustrationsFromKeywordsParams,
      illustrationsFromPackParams,
      currentIllustrationInfo,
      currentIllustrationPackData,
      isLoadingInfoData,
      illustrationsFromPack,
      illustrationsFromKeywords,
      customerActivities,
      isLoadingKeywordsIllustrations,
      picturesLikesCounts,
    } = this.state;

    const {autoRenewal, endDate, hasSubscribe} = this.props;
    const {history} = this.props;

    return (
      <div className="single-illustration-page">
        {error === true && this.goAccount()}

        <Header/>

        {server === null &&
          <Helmet>
            <title>{currentIllustrationInfo?.name || 'Illustration'}</title>
            { pictures[currentIllustrationIndex]
              && pictures[currentIllustrationIndex].hasOwnProperty('keywords')
                && pictures[currentIllustrationIndex].keywords.length > 0
                && pictures[currentIllustrationIndex].keywords[0] !== ""
                && <meta length={pictures[currentIllustrationIndex].keywords.length} data-render-type="csr" name="keywords" content={pictures[currentIllustrationIndex].keywords.join()} data-react-helmet="true"/>}
            <meta data-render-type="csr" property="og:title" content={pictures[currentIllustrationIndex] && pictures[currentIllustrationIndex].hasOwnProperty('name') ? pictures[currentIllustrationIndex].name : 'Illustration'} />
            <meta data-render-type="csr" property="og:url" content={pictures[currentIllustrationIndex] && pictures[currentIllustrationIndex].hasOwnProperty('cover') && (storytaleUrl + history.location.pathname)} />
            <meta data-render-type="csr" property="og:image" content={pictures[currentIllustrationIndex] && pictures[currentIllustrationIndex].hasOwnProperty('cover') && pictures[currentIllustrationIndex].cover} />
          </Helmet>
        }

        <ModalDownloadLimit
          show={this.state.showModalDownloadLimits}
          handleClose={this.hideModalDownloadLimits}
          endDate={moment(endDate).format("MMM Do")}
          autoRenewal={autoRenewal}
        />

        <ModalSubscriptionError
          show={this.state.showModalSubscriptionEnded}
          handleClose={this.hideModalSubscriptionEnded}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col button-col">
              {isLoadingPackInfo ?
                <Skeleton variant="rect" animation="wave" height={50} width={170} style={{borderRadius: '10px'}}/>
                :
                  <Link
                    to={`/pack/${currentIllustrationPackData && currentIllustrationPackData.id}`}
                  >
                    <ButtonIcon
                      isEmulate={true}
                      classes="large neutral outline escape"
                      icon={
                        <Escape/>
                      }
                      content={
                        <>
                          <Avatar size={32} shape="circle" src={currentIllustrationPackData && currentIllustrationPackData.cover}/>
                          <p className="text-content">{currentIllustrationPackData && currentIllustrationPackData.name}</p>
                        </>
                      }
                    />
                  </Link>
              }
            </div>
          </div>
        </div>

        <div className="container-custom">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <ProductInfo
                data={currentIllustrationInfo}
                isLoading={isLoadingInfoData}
                inModal={false}
                callbackTagClick={this.redirectTo}
                showModalLogin={this.showModal}
                customerActivities={this.changeIllustrationActivitiesWrapper}
                downloadErrorHandler={this.downloadErrorHandlerWrapper}
                handleClose={() => {
                  this.hideModalInfo();
                }}
                getCustomerActivities={customerActivities}
                picturesLikesCounts={picturesLikesCounts}
                changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
              />
            </div>
          </div>
        </div>

        <section className="illustrations-pack">
          <div className="container-custom">
            <div className="row">
              <div className="col">
                {illustrationsFromKeywords.length > 0 &&
                  <section className="section">
                    <BrowseByIllustrations
                      titleText={`You might also like`}
                      buttonText={`Load more assets`}
                      pictures={illustrationsFromKeywords}
                      requestParams={illustrationsFromKeywordsParams}
                      onClick={(id) => {
                        scrollToTop();
                        this.getIllustrationInfoWrapper(id);
                      }}
                      callback={this.loadMoreIllustrationsFromWrapper}
                      status={isLoadingKeywordsIllustrations}
                      containerType="col4"
                      customerActivities={this.changeIllustrationActivitiesWrapper}
                      downloadErrorHandler={this.downloadErrorHandlerWrapper}
                      getCustomerActivities={customerActivities}
                      picturesLikesCounts={picturesLikesCounts}
                      changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
                    />
                  </section>
                }
              </div>
            </div>
          </div>

          <div className="container-custom">
            <div className="row">
              <div className="col">
                  <section className="section">
                    <BrowseByIllustrations
                      titleText={`More from ${currentIllustrationPackData && currentIllustrationPackData.name}`}
                      href={currentIllustrationPackData && currentIllustrationPackData.id}
                      pictures={illustrationsFromPack}
                      requestParams={illustrationsFromPackParams}
                      onClick={(id) => {
                        scrollToTop();
                        this.getIllustrationInfoWrapper(id);
                      }}
                      status={isLoadingPackIllustrations}
                      containerType="col4"
                      customerActivities={this.changeIllustrationActivitiesWrapper}
                      downloadErrorHandler={this.downloadErrorHandlerWrapper}
                      getCustomerActivities={customerActivities}
                      redirect={true}
                      picturesLikesCounts={picturesLikesCounts}
                      changeLikesCountsLocally={this.changePicturesLikesCountsLocallyWrapper}
                    />
                  </section>
              </div>
            </div>
          </div>

          <PacksGrid
            title={"Similar packs"}
            packs={packs}
            isLoading={isLoadingInfoData}
            quantity={MAX_PACKS}
          />
        </section>

        {!hasSubscribe &&  <ReadyTo text="Like this pack?" />}

        {/*<BeforeFooter />*/}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  autoRenewal: state['user'].autoRenewal,
  endDate: state['user'].endDate,
  hasSubscribe: state['user'].hasSubscribe,
});

const mapDispatchToProps = dispatch => ({
  showModalPlans: () => dispatch({ type: 'modals/modalPlansActive', payload: true }),
});

const SingleIllustrationRouter = withRouter(SingleIllustration);
export default connect(mapStateToProps, mapDispatchToProps )(SingleIllustrationRouter);

