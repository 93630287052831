import React, { Component } from 'react';

import Header from './components/Header';
import Picture from './components/Picture';
import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import AboutUsProsCard from './components/AboutUsProsCard';
import ResourceCard from './components/ResourceCard';
import { withRouter } from 'react-router-dom';

import './AboutUs.css';
import { Helmet } from "react-helmet";

import envs from './lib/envs';

const AboutUsMainImageNormal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/AboutUsMainImage.png';
const AboutUsMainImageBig = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/AboutUsMainImage@2x.png';

const AboutUsProsImage01Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image1.png';
const AboutUsProsImage01Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image1@2x.png';
const AboutUsProsImage02Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image2.png';
const AboutUsProsImage02Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image2@2x.png';
const AboutUsProsImage03Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image3.png';
const AboutUsProsImage03Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image3@2x.png';
const AboutUsProsImage04Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image4.png';
const AboutUsProsImage04Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image4@2x.png';
const AboutUsProsImage05Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image5.png';
const AboutUsProsImage05Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image5@2x.png';
const AboutUsProsImage06Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image6.png';
const AboutUsProsImage06Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/aboutuspros/Image6@2x.png';

const AboutUsResourcesImage01Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image1.png';
const AboutUsResourcesImage01Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image1@2x.png';
const AboutUsResourcesImage02Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image2.png';
const AboutUsResourcesImage02Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image2@2x.png';
const AboutUsResourcesImage03Normal = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image3.png';
const AboutUsResourcesImage03Big = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/aboutus/resources/Image3@2x.png';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class AboutUs extends Component {
  render() {

    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale About Us</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='AboutUs'>
          <div className='container-fluid'>
            <div className='row AboutUs__heading'>
              <div className='col-12 col-lg-5 col-xl-5 offset-xl-1 AboutUs__heading-text-column'>
                <h1 className='title_medium'>About</h1>
                <p>Storytale is a service that provides premium quality illustrations of a constantly increasing collection. It helps designers and project owners, who are looking for vector and 3D graphics.</p>
                <p>Storytale was created by Craftwork team in order to organize a unique marketplace of high-quality illustrations. In the nearest future Storytale will be available as a platform for selling works from the best illustrators and graphic creators all over the world.</p>
              </div>
              <div className='col-12 col-lg-7 col-xl-5'>
              <Picture
                  standart={AboutUsMainImageNormal}
                  big={AboutUsMainImageBig}
                  alt='Main image'
                />
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row AboutUs__pros'>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='3400+ illustrations'
                  text='Growing library of high-quality illustrations will satisfy your needs and will save your time of creating designs.'
                  alt='picture 1'
                  img_normal={AboutUsProsImage01Normal}
                  img_big={AboutUsProsImage01Big}
                />
              </div>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='Convenient search by tags'
                  text='Use searching field to browse illustrations and find what you need with one word.'
                  alt='picture 2'
                  img_normal={AboutUsProsImage02Normal}
                  img_big={AboutUsProsImage02Big}
                />
              </div>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='Regular updates and releases'
                  text='New illustrations appear twice a month. Recent illustrations are always at the top of the browsing catalog.'
                  alt='picture 3'
                  img_normal={AboutUsProsImage03Normal}
                  img_big={AboutUsProsImage03Big}
                />
              </div>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='Vector, 3D, and raster'
                  text="We collected illustrations in variety of styles and moods. Let’s choose something you'll delighted with."
                  alt='picture 4'
                  img_normal={AboutUsProsImage04Normal}
                  img_big={AboutUsProsImage04Big}
                />
              </div>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='40+ categories of use'
                  text='illustrations in similar topics are gathered in categories. You can find a suitable scene for your purpose simply.'
                  alt='picture 5'
                  img_normal={AboutUsProsImage05Normal}
                  img_big={AboutUsProsImage05Big}
                />
              </div>
              <div className='col-12 col-md-6'>
                <AboutUsProsCard
                  title='Personal and Commercial use'
                  text='Feel free to use illustrations in your projects and include in design work for your client. View details of use in <a href="/license-agreement">license terms</a>.'
                  alt='picture 6'
                  img_normal={AboutUsProsImage06Normal}
                  img_big={AboutUsProsImage06Big}
                />
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className='row AboutUs-resources'>
              <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                <h2 className='title_medium text-center AboutUs-resources__title'>Resources</h2>
                <p className='text-center AboutUs-resources__subtitle'>If you want to mention Storytale in your project, we’re happy to provide you high-res resources to let you do it in a better way. Oh, by the way, thank you!</p>
              </div>
            </div>
            <div className='row AboutUs-resources-list'>
              <ResourceCard
                title='Covers'
                text='Use for articles and blog posts'
                img_normal={AboutUsResourcesImage01Normal}
                img_big={AboutUsResourcesImage01Big}
                alt='Covers'
                button_text='Download covers'
                button_link='http://www.mediafire.com/file/nb58cmuedh02xsm/Covers.zip/file'
              />
              <ResourceCard
                title='Logotype'
                text='Logotype in PNG and SVG formats'
                img_normal={AboutUsResourcesImage02Normal}
                img_big={AboutUsResourcesImage02Big}
                alt='Logos'
                button_text='Download logotypes'
                button_link='https://storytale-public2.b-cdn.net/static/Storytale%20Logos.zip'
              />
              <ResourceCard
                title='Demo illustrations'
                text='Free pack of 10 illustrations'
                img_normal={AboutUsResourcesImage03Normal}
                img_big={AboutUsResourcesImage03Big}
                alt='Illustrations'
                button_text='Download illustrations'
                button_link='http://www.mediafire.com/file/ly3haa8pyfgjoe8/press-kit-10-illustrations.zip/file'
              />
            </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer />
      </>
    );
  }
}

export default withRouter(AboutUs);

