
const downloadErrorHandler = (errorCode, history, showModalSubscriptionEnded, showModalDownloadLimits, showModalPlans) => {

  switch (errorCode) {
    case 109001002:
      showModalSubscriptionEnded();
      break;
    case 109001001:
      showModalPlans();
      break;
    case 109001003:
      showModalDownloadLimits();
      break;
    default:
      return ;
  }
}

export default downloadErrorHandler;
