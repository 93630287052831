export default class Stack {
  constructor(maxSize) {
    if (isNaN(maxSize)) { maxSize = 5; }
    this.maxSize = maxSize;
    this.container = [];
  }

  get() { return this.container }

  isEmpty() { return this.container.length === 0 }

  isFull() { return this.container.length >= this.maxSize;}

  getElement(number) { 
    if (this.isEmpty()) { return }
    if (isNaN(number)) { number = this.container.length - 1 }
    else { number = this.container.length - number%this.container.length - 1;}
    return this.container[number];
  }

  push(element) {
    if (this.container[this.container.length - 1] === element) return;

    if (this.isFull()) {
      this.container.shift();
    }
    this.container.push(element);
  }
}
