import React from 'react';
import PropTypes from 'prop-types';

import Picture from './Picture';
import { DownloadSecondary } from './Icons';

import './ResourceCard.css';

const ResourceCard = ({
  title,
  text,
  img_normal,
  img_big,
  alt,
  button_text,
  button_link
}) => {
  return (
    <div className='ResourceCard'>
      <p className='ResourceCard__title'>{title}</p>
      <div className='ResourceCard__img'>
        <Picture 
          standart={img_normal} 
          big={img_big} 
          alt={alt}/>
      </div>
      <p className='ResourceCard__text'>{text}</p>
      <a href={button_link} className='ResourceCard__button'><DownloadSecondary fill='#000' />{button_text}</a>
    </div>
  );
};

ResourceCard.propTypes = {
  title:        PropTypes.string,
  text:         PropTypes.string,
  alt:          PropTypes.string,
  img_big:      PropTypes.string.isRequired,
  img_normal:   PropTypes.string.isRequired,
  button_text:  PropTypes.string,
  button_link:  PropTypes.string
};

ResourceCard.defaultProps = {
  title:        'Title',
  text:         'Some text',
  alt:          'picture',
  button_text:  'Download',
  button_link:  '/'
}

export default ResourceCard;
