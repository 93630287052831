import React from 'react';
import moment from "moment";
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Collapse} from 'antd';
import {useSelector, useDispatch} from 'react-redux'
import {userLogout} from "../features/user/userSlice";
import {StorytaleLogo} from "./Icons";
import TextAvatar from "./TextAvatar";
import './Header.css';

const {Panel} = Collapse;

const Header = (props) => {
  const authorizationStatus = useSelector((state) => state.user.authorized);
  const subscriptionStatus = useSelector((state) => state.user.hasSubscribe);
  const downloadRemaining = useSelector((state) => state.user.downloadRemaining);
  const membershipStatus = useSelector((state) => state.user.membershipStatus);
  const userEmail = useSelector((state) => state.user.userEmail);
  const endDate = useSelector((state) => state.user.endDate);
  const formattedDate = moment(endDate).isValid() ? moment(endDate).format("MMMM D") : '';
  const dispatch = useDispatch();

  const FindSelector = (value) => {
    const todo = useSelector((state) =>
      state['browse'].categories.filter(category => category.name.toLowerCase() === value.toLowerCase()).map(item => item.id));
    return todo[0];
  };

  const logoutClick = () => dispatch(userLogout);
  const getFullAccessClick = () => dispatch({type: 'modals/modalPlansActive', payload: true});
  const signUpClick = () => dispatch({type: 'modals/modalRegisterActive', payload: true});
  const signInClick = () => dispatch({type: 'modals/modalLoginActive', payload: true});

  const goAccount = (url) => {
    window.location.href = `${process.env.REACT_APP_ACCOUNT_APP}/${url}`;
  };

  const categoryIllustration = FindSelector('illustrations');
  const category3d = FindSelector('3d');
  const categoryElements = FindSelector('elements');
  const categoryWebDesign = FindSelector('Web design');
  const categoryApplication = FindSelector('application');
  const categoryMockup = FindSelector('mockups');
  const categoryEmail = FindSelector('email');
  const baseUrl = '/browse/?categoryId=';

  const {handleFilterClick} = props;

  const list = (handleFilterClick = () => {
  }) => (
    <ul>
      <li>
        <Link
          className="nav-link"
          to={`${baseUrl}1`}
          onClick={() => handleFilterClick(categoryIllustration)}
        >
          Illustrations
        </Link>
      </li>
      <li>
        <Link
          className="nav-link"
          to={`${baseUrl}2`}
          onClick={() => handleFilterClick(category3d)}
        >
          3D
        </Link>
      </li>
      <li>
        <Link className="nav-link" to={`${baseUrl}3`}
              onClick={() => handleFilterClick(categoryElements)}
        >Icons</Link>
      </li>
      <li>
        <Link className="nav-link" to={`${baseUrl}4`}
              onClick={() => handleFilterClick(categoryWebDesign)}
        >Backgrounds</Link>
      </li>
      <li>
        <Link className="nav-link" to={`${baseUrl}6`}
              onClick={() => handleFilterClick(categoryMockup)}
        >Mockups</Link>
      </li>
      <li>
        <Link className="nav-link bordered" to={`/order-custom`}>
          Custom work
        </Link>
      </li>
    </ul>
  );

  return (
    <header className={`page-header ${authorizationStatus === true ? `authorized` : ''} `}>
      <Navbar expand="lg">
        <Navbar.Brand className="navbar-brand-mr">
          <Link to={`/`}>
            <StorytaleLogo/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>

        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="navbar-nav header-tools">
            <li className="nav-item nav-item-hidden">
              <Collapse ghost>
                <Panel header="Illustrations" key="1" showArrow={false}>
                  {list(handleFilterClick)}
                </Panel>
              </Collapse>
            </li>

            {authorizationStatus !== null && subscriptionStatus !== null &&
              <>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="/browse">
                    Explore
                  </a>
                  <div className="dropdown-menu browse-content animate slideIn">
                    <div className="browse-content-inner">
                      {list(handleFilterClick)}
                    </div>
                  </div>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="/browse/?isFree=true">
                    Freebies
                  </a>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to={`/plans`}>
                    Pricing
                  </Link>
                </li>
              </>
            }
          </ul>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className='navbar-right-block'>
          <ul className="navbar-nav header-tools">

            <li className="nav-item nav-item-auth">
              <button className="nav-link" onClick={() => signInClick()}>Login</button>
            </li>

              <li className="nav-item nav-item-auth">
                <button className="cfw-button-ghost" onClick={() => signUpClick()}>Sign Up</button>
              </li>



            {subscriptionStatus === false &&
              <li className="nav-item nav-item nav-item-authorized">
                <button className="nav-link outline blue" onClick={() => getFullAccessClick()}>
                  Get full access
                </button>
              </li>
            }
            <li className="nav-item user-profile dropdown dropdown-user nav-item nav-item-authorized">
              <div className="nav-link" onClick={() => goAccount('settings')}>
                <TextAvatar
                  userNameFirstLetter={userEmail && (typeof userEmail === 'string' || userEmail instanceof String) ? userEmail.charAt(0) : ''}/>
              </div>

              <div
                className={`dropdown-menu animate slideIn ${authorizationStatus === true ? `logged-author-content ` : ''} p-0`}>

                <div className="browse-content-inner">
                  {subscriptionStatus && (membershipStatus === 3 || membershipStatus === 4) && downloadRemaining !== null &&
                    <div className="downloads-count">
                      <div className="total">{downloadRemaining}</div>
                      <div className="description">downloads <br className={'d-none d-md-block'}/> remaining
                        until <br/> {formattedDate}</div>
                    </div>
                  }
                  <ul className="bordered-list">
                    <li>
                      <button className="nav-link" onClick={() => goAccount('likes')}>Likes</button>
                    </li>
                    <li>
                      <button className="nav-link" onClick={() => goAccount('downloads')}>Downloads</button>
                    </li>
                  </ul>
                  <ul className="bordered-list">
                    <li>
                      <button className="nav-link" onClick={() => goAccount('settings')}>Settings</button>
                    </li>
                  </ul>
                  <ul className="bordered-list">
                    <li>
                      <button className="nav-link red" onClick={() => logoutClick()}>Logout</button>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
