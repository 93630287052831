import axios from "axios";

function getCustomerActivities (props, originalData) {

  let findId = Array.from(originalData.map(function(element) {
    return element.id;
  }));

  axios.get(
    `/api/userInfo/getActivityWithIllustrations`, {
      params: {
        illustrationIds: findId
      }}
  ).then((response) => {
    if (response.data.success) {

      const activities =  response.data.result.activitys;

      let customerActivities = props.state.customerActivities;
      activities.forEach((el)=> customerActivities[el.illustrationId] = { isLiked: el.isLiked, isDownloaded: el.isDownloaded } );

      props.setState({
        customerActivities: customerActivities,
      });
    }

  })
    .catch((error) => {
      console.log(error);
    });
}

export default getCustomerActivities;
