import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux'
import { CloseElement, StorytaleLogoSlim } from "./Icons";
import { setCookie, getCookie } from '../lib/cookies';
import './BlackFridayPopup.scss';
import './Modal.scss';

const image_src = 'https://storytale-public.b-cdn.net/static/assets/storytale-app/img/blackFr.png';

function BlackFridayPopup() {
  const [isToBeRendered, setIsToBeRendered] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();
  const image = useRef();

  useEffect(() => {
    setIsToBeRendered(getCookie('BlackFridayPopupVisibleNew') !== null ? getCookie('BlackFridayPopupVisibleNew') : 'show');
  }, [isToBeRendered]);

  const setHideCookie = () => {
    setCookie('BlackFridayPopupVisibleNew', 'hide', 1, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
    setIsToBeRendered(getCookie('BlackFridayPopupVisibleNew'));
  };

  const handleButtonClick = () => {
    setHideCookie();
    dispatch({ type: 'modals/modalPlansActive', payload: true });
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (image.current && image.current.complete) {
      setImageLoaded(true);
    }
  }, []);

  return (
    <>
      {(isToBeRendered !== null && isToBeRendered !== 'hide') &&
      <div className={`${imageLoaded? 'black-friday-popup__all_opaque' : 'black-friday-popup__all_transparent'} black-friday-popup__all`}>
        <div className='black-friday-popup__shade fade modal-backdrop custom-backdrop' />
        <div className='black-friday-popup'>
          <div
            className='black-friday-popup__close'
            onClick={() => setHideCookie()}>
            <CloseElement
              fill={'#ffffff'}
              circleFill={'#ffffff'}
              circleOpacity={'0.2'}
            />
          </div>

          <div className='black-friday-popup__image-wrapper'>
            <img
              ref={image}
              className='black-friday-popup__image'
              onLoad={handleImageLoad}
              src={image_src}
              alt={'Black Friday'}
            />
          </div>

          <p className='black-friday-popup__text'>
            Save 50% for all subscption plans on Black Friday Sale. Use the best chance to have a great deal until December, 1!
          </p>

          <button
            className='black-friday-popup__button btn'
            onClick={handleButtonClick}
          >
            Claim the deal
          </button>
        </div>
      </div>}
    </>
  );
}

export default BlackFridayPopup;
