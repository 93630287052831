import React from "react";
import "./HowItWorks.css";

function HowItWorks() {
  return (
    <section className="container-custom how-it-works">
      <h2 className="title">Complete your design project in 15 minutes</h2>
      <div className="cu-cards-container">
        <div className="abstract-card">
          <div className="field">abstract</div>
          <div className="search">Search
            <img
              className="pointer"
              alt="pointer"
              src="/images/hand_pointing.png"
            />
          </div>
          <div className="abstract-card-text-container">
            <span className="title">Find your style</span>
            <p className="text">Browse assets and find ones that fit your project perfectly. Search by project, category
              or keyword.</p>
          </div>
        </div>
        <div className="graphics-card">
          <img className="abstract-bg" src="/images/abstract.png" alt=""/>
          <div className="graphics-card-text-container">
            <span className="title">Download graphics</span>
            <p className="text">Grab editable vectors, 3d ready-to-use objects or vector shapes in a single click. It's
              yours now</p>
          </div>
        </div>
      </div>
      <div className="magic-card">
        <div className="text-container">
          <div className="first-text">
            <span>Do a magic</span>
            <p>Apply graphics to your website, presentation, app, or use in design work for your client. Bring it to
              life!</p>
          </div>

          <div className="second-text">
            <div className="title-wrapper">
              <span className="xtitle">Fun fact!
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15"
                     viewBox="0 0 29 15" fill="none">
                <path
                  d="M12 8.10344C12.4691 6.69605 12.6476 5.27386 13.0492 3.86833C13.1264 3.59802 13.386 3.28953 13.386 2.99243C13.386 2.38585 13.9058 1.63633 13.9058 1M16 13.3644C18.2528 13.3644 20.4979 13.114 22.7473 13.0275C24.1932 12.9719 28.5353 13.0179 27.0883 13.0179M6.02438 9.7174C4.81857 8.44066 3.79933 6.97259 2.55929 5.73255C2.05159 5.22485 1.62054 4.31027 1 4"
                  stroke="#2F7657" strokeWidth="2" strokeLinecap="round"/>
              </svg>
              </span>

            </div>
            <p>Check out our <a href="https://dribbble.com/storytale" target="_blank" className="dribble-link">Dribbble</a> page to see how we expertly integrate our illustrations into interface design.</p>
          </div>
        </div>
        <div className="image-block">
          <div className="main-image">
            <div className="main-image-2">
              <svg width="396" height="20" viewBox="0 0 396 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="396" height="20" fill="black"/>
                <rect x="8" y="7" width="6" height="6" rx="3" fill="white"/>
                <rect x="20" y="7" width="6" height="6" rx="3" fill="white"/>
                <rect x="32" y="7" width="6" height="6" rx="3" fill="white"/>
              </svg>
              <img className="blank_preview" src="/images/blank_preview.png" alt="blank ui kit"/>
            </div>

            <img src="/images/drake.png" className="drake-meme" alt="drake meme"/>
            <img src="/images/nice_man.png" className="nice-man" alt="nice man"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;

// <h2 className="title">Complete your design project in 15 minutes</h2>
// <div className="container">
// <div className="div">
// <div className="card">
// <div className="field">
// <div className="text-wrapper-how-it-works">Abstract</div>
// </div>
// <div className="text-wrapper-2-how-it-works">Find your style</div>
// <p className="p">
// Browse assets and find ones that fit your project perfectly.
// Search by project, category or keyword.
// </p>
// <div className="overlap-group">
// <button className="button">
// <div className="div-2">
// <div className="text-wrapper-3-how-it-works">Search</div>
// </div>
// </button>
// <img

//                 className="hand-pointing"
//                 alt="hand-pointing"
//                 src="./images/hand_pointing.png"
//               />
//             </div>
//           </div>
//           <div className="overlap-wrapper">
//             <div className="overlap">
//               <div className="overlap-2">
//                 <div className="text-wrapper-4-how-it-works">
//                   Download graphics
//                 </div>
//                 <p className="grab-editable">
//                   Grab editable vectors, 3d ready-to-use objects or vector
//                   shapes in a single click. It&#39;s yours now
//                 </p>
//                 <div className="overlap-3">
//                   <img
//                     className="element"
//                     alt="Element"
//                     src="/images/150.png"
//                   />
//                   <img className="img" alt="Element" src="/images/141.png" />
//                   <img
//                     className="element-2"
//                     alt="Element"
//                     src="/images/100.png"
//                   />
//                   <img
//                     className="element-3"
//                     alt="Element"
//                     src="/images/154.png"
//                   />
//                 </div>
//               </div>
//               <img className="element-4" alt="Element" src="/images/111.png" />
//             </div>
//           </div>
//         </div>
//         <div className="div-3">
//           <div className="block_three__content">
//             <div className="div-3__fist_block_section">
//               <div className="div-3__first_section_text">
//                 <div className="text-wrapper-8-how-it-works">Do a magic</div>
//                 <p className="text-wrapper-7-how-it-works">
//                   Apply graphics to your website, presentation, app, or use in
//                   design work for your client. Bring it to life!
//                 </p>
//               </div>
//
//               <div className="div-3__second_section_image">
//                 <div className="overlap-5">
//                   <div className="text-wrapper-6-how-it-works">Fun fact!</div>
//                   <img
//                     className="vector"
//                     alt="Vector"
//                     src="./images/three-sticks.svg"
//                   />
//                 </div>
//                 <p className="check-out-our">
//                   <span className="span">Check out our </span>
//                   <a href="https://dribbble.com/storytale" className="text-wrapper-5-how-it-works">Dribbble</a>
//                   <span className="span">
//                     {" "}
//                     page to see how we expertly integrate our illustrations into
//                     interface design.
//                   </span>
//                 </p>
//               </div>
//             </div>
//
//             <div className="overlap-4">
//               <div className="frame">
//                 <div className="toolbar">
//                   <div className="controls">
//                     <div className="dot" />
//                     <div className="dot" />
//                     <div className="dot" />
//                   </div>
//                 </div>
//                 <img
//                   className="image"
//                   alt="site_screen-for-design_how_it_works"
//                   src="/images/site_screen-for-design_how_it_works.png"
//                 />
//               </div>
//               <img
//                 className="image-2"
//                 alt="man_in_orange_jacket"
//                 src="/images/man_in_orange_jacket.png"
//               />
//               <img
//                 className="image-3"
//                 alt="magic_man"
//                 src="/images/magic_man.png"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>