import { notification } from 'antd';
import { CloseElement } from "../components/Icons";

export const openNotificationWithIcon = (type, header, description) => {
  notification[type]({
    message: header,
    description: description,
    closeIcon: (<span><CloseElement/></span>),
  })
}
