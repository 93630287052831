import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import '../common_styles/buttons.scss'
import "./Modal.scss"
import {CloseElement} from "./Icons";
import ButtonBase from "./ButtonBase";

const description = "An error has occurred. Please contact us";
const shortDescription = "Subscription renewal error";
const DownloadError = "https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/download-error.png";

const ModalSubscriptionError= ({
      show,
      handleClose,
    }) => {
  return (
    <Modal
      animation={true}
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-subscription-error modal-dialog-centered "
      backdropClassName="custom-backdrop"
    >
      <Modal.Header>
        <span className="close-button" onClick={handleClose}>
          <CloseElement/>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="image-container">
          <img src={DownloadError} width={70} height={70} alt=""/>
        </div>
        <p className="short-description text-center">{shortDescription}</p>
        <p>{description}</p>

      </Modal.Body>
      <Modal.Footer>
        <Link to='/contact-us'>
        <ButtonBase
          content="Contact us"
          classes="big purple w-100"
        /></Link>
      </Modal.Footer>
    </Modal>
  )
};
export default ModalSubscriptionError;
