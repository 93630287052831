import React from 'react';

import './ProductHuntBadge.css';
import {ArrowRightPH} from "./Icons";

export default function ProductHuntBadge() {
  return (
    <a className='ProductHuntBadge' target="_blank" href="https://www.producthunt.com/products/storytale">
      <span className="top-badge">Top #1</span>
      <span>Product of the week on Product Hunt</span>
      <ArrowRightPH/>
    </a>
  );
}