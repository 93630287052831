import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, withRouter } from 'react-router-dom';
import BeforeFooter from './components/BeforeFooter';
import Footer from './components/Footer';
import Header from './components/Header';
import envs from './lib/envs';
import { hideCrisp, showCrisp } from "./lib/crisp";
import './textpage.css';

const storytaleUrl = process.env.REACT_APP_STORYTALE_APP || envs.storytaleUrl;

class LicenseAgreement extends Component {
  componentDidMount() {
    showCrisp();
  }

  componentWillUnmount() {
    hideCrisp();
  }

  render() {
    const {history} = this.props;

    return (
      <>
        <Header />
        <Helmet>
          <title>Storytale License Agreement</title>
          <meta property="og:url" content={storytaleUrl + history.location.pathname} />
        </Helmet>
        <div className='container textpage'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='textpage__title'>License Agreement</h1>
                <p>Storytale license allows you to use downloaded illustrations for your personal or commercial projects. Within your subscription, you can download up to 60 illustrations per month from the entire library. We allow the additional download if have strong reasons to receive more illustrations.</p>
                <p>Downloading and using our illustrations anywhere obliges you to comply with the license terms described in this agreement.</p>
                <h2 className='textpage__title_secondary'>Type of Licenses</h2>
                <h3 className='textpage__text_lead'>Personal</h3>
                <p>Personal License allows you to use downloaded illustrations for non-commercial, personal projects, personal social media accounts, and personal physical items not for sale. Also, you can use illustrations with a personal license to any project that doesn’t bring you a profit.</p>
                <h3 className='textpage__text_lead'>Commercial</h3>
                <p>Commercial License allows you to use downloaded illustrations for commercial projects which involve customer orders, revenue-generating projects, end-products for sale, physical or digital advertising, physical items not for sale.</p>
                <h2 className='textpage__title_secondary'>General terms of License</h2>
                <p>You may modify the resources according to your requirements and use them royalty free in any or all of your personal and commercial projects.</p>
                <p>Storytale will not be responsible for any outcome that may occur during the course of usage of our resources.</p>
                <p>You can use free illustrations for both personal and commercial projects.</p>
                <p>You are not required to attribute or link to Storytale in any of the projects.</p>
                <h2 className='textpage__title_secondary'>You are not allowed to</h2>
                <p>Redistribute, resell, lease, license, sub-license or offer the file downloaded to any third party. If you are creating end-products for sale or for free distributing, they shouldn’t include editable source files of Storytale illustrations.</p>
                <p>Permit an end-user of the End Product to extract the Item and use it separately from the End Product.</p>
                <p>Assign the authorship as your own. You only get the license that allows you to use the illustration only in specified in this license agreement terms.</p>
                <p>Everything else, except as permitted in this agreement, is prohibited.</p>
                <p>If we suspect you do not comply with this agreement and the terms of using our service, your account will be terminated without warning and clarification of the circumstances.</p>
                <h2 className='textpage__title_secondary'>Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these License Terms at any time. The changes to the License will affect since your next subscription period.</p>
                <p>For any questions regarding the License, feel free to <Link to='/contact-us'>contact us</Link></p>
              </div>
          </div>
        </div>
        {/*<BeforeFooter />*/}
        <Footer />
      </>
    )
    }
}

export default withRouter(LicenseAgreement);
