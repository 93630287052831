import React, {PureComponent} from 'react';
import {v4 as uuidv4} from 'uuid';
import './FilterMenu.scss';

class FilterMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.specialNames = props.specialNames;
  }

  render() {
    const {options, handleMenuOptionClick, paramsSelectedId} = this.props;

    return (<>
      {options && Array.isArray(options) && options.length > 0 && <ul className='filter-menu'>
        <li
          className={`filter-menu__option ${paramsSelectedId === null ? 'filter-menu__option_active' : ''}`}
          onClick={() => handleMenuOptionClick()}
          key={uuidv4()}
        >
          All
        </li>
        {options.map((option) => <li
          className={`filter-menu__option ${option.hasOwnProperty('id') && +paramsSelectedId === option.id ? 'filter-menu__option_active' : ''}`}
          onClick={() => handleMenuOptionClick(option?.id)}
          key={uuidv4()}
        >
          {option.hasOwnProperty('name') && (this.specialNames && this.specialNames.hasOwnProperty(option.name) ? this.specialNames[option.name] : (option.name === 'Web Design' ? 'Patterns' : (option.name === 'Elements' ? 'Icons' : option.name)))}
        </li>)}
      </ul>}
    </>)
  }
}

export default FilterMenu;
