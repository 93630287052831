import React from 'react';
import { Link }  from 'react-router-dom';

import Picture from './Picture';

import PropTypes from 'prop-types';
import './EmptyPageFiller.scss';

const EmptyPageFiller= ({ text, StandartImage, BigImage, linkText, linkPath, buttonType ,showIcon, showButton}) => (
  <div className='EmptyPageFiller'>
    <Picture standart={StandartImage} big={BigImage} />
    { text ? <p className='EmptyPageFiller__text'>{text}</p> : null}
    { showButton &&
      <Link to={linkPath} class="link" ><div class="button-base big black">{linkText}</div></Link> 
    }
  </div>
);

EmptyPageFiller.propTypes = {
  text:          PropTypes.string,
  StandartImage: PropTypes.string,
  BigImage:      PropTypes.string,
  linkText:      PropTypes.string,
  linkPath:      PropTypes.string,
  buttonType:    PropTypes.string,
  showIcon:      PropTypes.bool,
  showButton:    PropTypes.bool
};
  
EmptyPageFiller.defaultProps = {
  linkText:     'Explore catalog',
  linkPath:     'browse',
  buttonType:   'black',
  showIcon:     true,
  showButton:   true
};  

export default EmptyPageFiller;
