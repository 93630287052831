import React, {useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Loupe, Cross} from './Icons';
import './SearchForm.scss';

function SearchFormToWrap(props) {
  const {
    inputValue,
    onSubmitCallback,
    onChangeCallback,
    placeholder,
    show,
    autoFocus = false,
    fixedStyle = false,
    examples = [],
    withTextSubmitButton = false,
    newMainPage = false,
  } = props;
  const searchInput = useRef(null);
  const inputNotEmpty = inputValue && (typeof inputValue === 'string' || inputValue instanceof String) && inputValue.length > 0;
  const formId = 'search-form-id-' + uuidv4();

  const onExampleClick = (value) => {
    onChangeCallback(value);
    onSubmitCallback(value);
  };

  return (
    <>
      <form
        id={formId}
        className={`${newMainPage ? `new-search-form ${show ? 'new-search-form_visible' : 'new-search-form_transparent'} ${fixedStyle ? 'new-search-form_fixed' : ''} ${withTextSubmitButton ? 'new-search-form_with-text-button' : ''}` : `search-form ${show ? 'search-form_visible' : 'search-form_transparent'} ${fixedStyle ? 'search-form_fixed' : ''} ${withTextSubmitButton ? 'search-form_with-text-button' : ''}`}`}
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmitCallback(inputValue);
        }}
      >
        <div className={newMainPage ? 'new-search-form__container' : 'search-form__container'}>
          <input
            className={newMainPage ? 'new-search-form__input' : 'search-form__input'}
            type='text'
            value={inputValue || ''}
            onChange={(evt) => onChangeCallback(evt.target.value)}
            autoFocus={autoFocus}
            placeholder={placeholder}
            ref={searchInput}
          />

          <button
            type='submit'
            className={newMainPage ? 'new-search-form__submit-button-loupe' : 'search-form__submit-button-loupe'}
            name='search'
          >
            <Loupe/>
          </button>

          <button
            type='reset'
            className={newMainPage ? `new-search-form__reset-button ${inputNotEmpty ? 'new-search-form__reset-button_shown' : 'new-search-form__reset-button_hidden'}` : `search-form__reset-button ${inputNotEmpty ? 'search-form__reset-button_shown' : 'search-form__reset-button_hidden'}`}
            onClick={() => {
              onChangeCallback('');
              searchInput?.current?.focus();
            }}
          >
            <Cross/>
          </button>

        </div>


        {withTextSubmitButton &&
          <button
            form={formId}
            type='submit'
            className={newMainPage ? 'new-search-form__submit-button-text' : 'search-form__submit-button-text btn-light-blue_big'}
          >
            Search
          </button>
        }
      </form>

      {!fixedStyle && examples.length > 0 &&
        <p className={newMainPage ? 'new-search-examples' : 'search-examples'}>
          eg.
          {examples.map((example) => {
            return (
              <span
                className={newMainPage ? 'new-search-examples__text' : 'search-examples__text'}
                onClick={() => {
                  onExampleClick(example)
                }}
                key={uuidv4()}
              >
                {example}
              </span>
            );
          })}
        </p>
      }
    </>
  );
}

const SearchForm = React.memo(SearchFormToWrap);
export default SearchForm;
