
import "./AccountSignLink.scss";

export default function AccountSignLink(props) {
  return (
    <div className="AccountSignLink">
      <p>{props.text}</p>
      <p style={{color: 'black', marginLeft: '5px', cursor: 'pointer'}} onClick={()=> props.onClick()}>{props.linktext}</p>
    </div>
  );
}
