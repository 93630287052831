import axios from "axios";

export const postFormData = (url ,formData, callback) => {
    const promise = axios.post(url, formData)
    const dataPromise = promise.then((response) => response.data)

    return dataPromise;
}

export const getRequest = (url, getParams) => {
  let promise;
  let dataPromise;

  if (getParams === undefined || getParams === null) {
    promise = axios.get(url);
    dataPromise = promise.then((response) => response.data);

    return dataPromise;
  } 

  promise = axios.get(url, getParams);
  dataPromise = promise.then((response) => response.data);

  return dataPromise;
}
