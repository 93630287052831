import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Checkout from './Checkout';

import '../common_styles/buttons.scss'
import './Modal.scss'
import { CloseElement, StorytaleLogoSlim } from './Icons';

import {useSelector, useDispatch} from 'react-redux'

const image = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ModalCheckout.png';
const imageBig = 'https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/ModalCheckout2x.png';

const ModalCheckout = () => {
  const dispatch = useDispatch();

  const modalCheckoutActive = useSelector((state) => state.modals.modalCheckoutActive);

  const handleClose = () => {
    dispatch({ type: 'modals/modalCheckoutActive', payload: false });
    dispatch({ type: 'modals/orderChoosen', payload: {} });
  };

  return (
    <Modal
      animation={true}
      show={modalCheckoutActive}
      onHide={handleClose}
      dialogClassName='modal-dialog modal-dialog-auth modal-dialog-checkout modal-dialog-centered '
      backdropClassName='custom-backdrop'
    >
      <Modal.Header>
        <span className='close-button' onClick={handleClose}>
          <CloseElement/>
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className='wrapper'>
          <div className='left'>
            <div className='custom-title'>Checkout</div>
            <Checkout />
          </div>
          <div className='right'>
            <div className='logo'>
              <StorytaleLogoSlim fill="#0500E0" />
            </div>
            <img
              src={image}
              srcSet={`${image} 1x, ${imageBig} 2x`}
              alt='checkout'
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};
export default ModalCheckout;
