export const selectElement = (array, find = true) => {
  let indices = [];
  let idx = array.indexOf(find);
  while (idx !== -1) {
    indices.push(idx + '');
    idx = array.indexOf(find, idx + 1);
  }
  return indices;
};

export const filterArray = (array, number = 999) => {
  return array.filter((item, index) => index < number);
};

export const filterEmptyCategories = (allCategories) => {
  const filteredCategories = allCategories.filter((categoryObj) => {
    return categoryObj.name === "3D" || categoryObj.name === "Illustrations" || categoryObj.name === "Mockups" || categoryObj.name === 'Elements' || categoryObj.name === 'Web design' || categoryObj.name === 'Icons' || categoryObj.name === 'Backgrounds'
  });
  return filteredCategories;
};





